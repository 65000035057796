import { useStoreState } from "easy-peasy";
import React, { useState, useEffect } from "react";
import { withTranslation, useTranslation } from "react-i18next";
import styled from "styled-components";
import { Button, Swiper, Text, Title } from "../components";
import { AuthTemplate } from "../components/organisms/auth";
import { storage } from "../services";
import { theme } from "../styles";
import { useNavigate } from "react-router-dom";

const steps = {
  normal: [
    {
      image: require("../assets/pictures/onboarding_1.png"),
      title: "onboarding_title_1",
      description: "onboarding_description_1",
    },
    {
      image: require("../assets/pictures/onboarding_2.png"),
      title: "onboarding_title_2",
      description: "onboarding_description_2",
    },
    {
      image: require("../assets/pictures/onboarding_3.png"),
      title: "onboarding_title_3",
      description: "onboarding_description_3",
    },
    {
      image: require("../assets/pictures/onboarding_4.png"),
      title: "onboarding_title_4",
      description: "onboarding_description_4",
    },
  ],
  dany: [
    {
      image: require("../assets/pictures/onboarding_1_dany.png"),
      title: "onboarding_title_dany_1",
      description: "onboarding_description_dany_1",
    },
    {
      image: require("../assets/pictures/onboarding_2.png"),
      title: "onboarding_title_2",
      description: "onboarding_description_2",
    },
    {
      image: require("../assets/pictures/onboarding_3_dany.png"),
      title: "onboarding_title_dany_3",
      description: "onboarding_description_dany_3",
    },
  ],
};

const Onboarding = ({}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [start, setStart] = useState(false);
  const [finish, setFinish] = useState(false);

  const isDany = useStoreState((state) => state.isDany);

  function onNextStep() {
    if (!start) {
      setStart(true);
    }
    if (start && finish) {
      navigate("/home");
      storage.set({ key: "@onboarding", value: "true" });
    }
  }

  const [isSmallDevice, setIsSmallDevice] = useState(window.innerHeight <= 680);
  useEffect(() => {
    const handleResize = () => setIsSmallDevice(window.innerHeight <= 680);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <AuthTemplate withLight={!start}>
      {start ? (
        <Swiper
          pages={isDany ? steps.dany : steps.normal}
          onFinish={() => setFinish(true)}
        />
      ) : (
        <Styled.Content>
          <Title fontSize={33} textAlign="center" style={{ marginBottom: 15 }}>
            {t("onboarding_title_0")}
          </Title>
          <Text
            fontSize={isSmallDevice ? 18 : 20}
            textAlign="center"
            color={theme.colors.white}
            fontFamily={theme.fonts.medium}
            fontWeight={500}
          >
            {t("onboarding_description_0")}
          </Text>
        </Styled.Content>
      )}

      <Styled.AnimatedButton
        className={
          finish ? "fadeInUpBig" : start ? "fadeOutDownBig" : "fadeInUpBig"
        }
      >
        {!start && isDany && (
          <Styled.Dany
            isSmallDevice={isSmallDevice}
            src={require("../assets/images/dany.png")}
            alt="Dany"
          />
        )}
        {!start ? (
          <Button
            onClick={onNextStep}
            style={{ marginTop: start ? 37 : 0, zIndex: 1 }}
          >
            {t("onboarding_button_start")}
          </Button>
        ) : (
          ""
        )}
        {start && finish ? (
          <Button
            onClick={onNextStep}
            style={{
              marginTop: start ? 37 : 0,
              zIndex: 1,
              width: window.innerWidth - 40 + "px",
            }}
            textAlignCenter={true}
          >
            {t("onboarding_button_confirm_dany")}
          </Button>
        ) : (
          ""
        )}
      </Styled.AnimatedButton>
    </AuthTemplate>
  );
};

const Styled = {
  Content: styled.div`
    flex: 1;
    align-items: center;
    justify-content: center;
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    text-align: center;
  `,
  Dany: styled.img`
    bottom: 20px;
    width: 100%;
    position: absolute;
    align-self: center;
    object-fit: contain;
    height: 200px;
  `,
  AnimatedButton: styled.div`
    &.fadeInUpBig {
      animation: fadeInUpBig 1s;
    }
    &.fadeOutDownBig {
      animation: fadeOutDownBig 1s;
    }
    display: flex;
    justify-content: center;
  `,
};

const globalStyles = `
  @keyframes fadeInUpBig {
    from {
      opacity: 0;
      transform: translate3d(0, 100%, 0);
    }
    to {
      opacity: 1;
      transform: none;
    }
  }
  @keyframes fadeOutDownBig {
    from {
      opacity: 1;
      transform: none;
    }
    to {
      opacity: 0;
      transform: translate3d(0, 100%, 0);
    }
  }
`;

document.head.insertAdjacentHTML("beforeend", `<style>${globalStyles}</style>`);

export default withTranslation()(Onboarding);
