import React from "react";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import { Text } from "../atoms";
import { theme } from "../../styles";

const defaultStyle = {
  paddingHorizontal: 15,
  borderTopLeftRadius: 15,
  borderTopRightRadius: 15,
  borderBottomLeftRadius: 15,
  borderBottomRightRadius: 15,
  borderColor: "transparent",
  backgroundColor: theme.colors.lightgrey,
};

const labelStyle = {
  fontSize: 14,
  color: theme.colors.black,
  fontFamily: theme.fonts.medium,
};

const itemStyle = {
  justifyContent: "flex-start",
  padding: 20,
};

const Select = ({ options, value, onChange, style, placeholder, label }) => (
  <>
    {label && (
      <Text
        style={{
          marginBottom: 10,
          marginRight: "auto",
          fontFamily: theme.fonts.semiBold,
          fontWeight: 600,
        }}
      >
        {label}
      </Text>
    )}
    <Dropdown
      options={options}
      value={value}
      onChange={onChange}
      style={{ ...defaultStyle, ...style, height: 45 }}
      placeholder={placeholder}
      controlClassName="dropdown-control"
      menuClassName="dropdown-menu"
      arrowClassName="dropdown-arrow"
      className="dropdown"
    />
  </>
);

export default Select;
