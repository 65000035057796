import { gql, useQuery } from "@apollo/client";
import _ from "lodash";
import React, { useState, useEffect } from "react";
import { withTranslation } from "react-i18next";
import styled from "styled-components";
import {
  CategoryCard,
  Layout,
  StatisticsCard,
  Text,
  Title,
} from "../components"; // Adjust import paths as necessary
import { theme } from "../styles";
import { useNavigate, useLocation } from "react-router-dom";

/*
|--------------------------------------------------------------------------
| VARIABLES
|--------------------------------------------------------------------------
*/
const date = new Date().toISOString().split("T")[0];
const menu = ["statistics", "theme_treated"];

/*
|--------------------------------------------------------------------------
| QUERIES
|--------------------------------------------------------------------------
*/
const GET_STATS = gql`
  query getDaySummary($date: String!) {
    getDaySummary(date: $date) {
      totalPoints
      todayPoints
      monthRank
      themesCount
      questionsCount
      goodAnswersCount
      wrongAnswersCount
      goodAnswersPercentage
      wrongAnswersPercentage
    }
  }
`;

const GET_THEMES = gql`
  query getDayThemeLevels($date: String!) {
    getDayThemeLevels(date: $date) {
      answeredQuestionsCount
      theme {
        id
        name
        color
        backgroundColor
        cover {
          file
        }
        progression {
          questionsCount
          currentQuestion
          percentage
          level
          themeLevelId
        }
        levels {
          id
          level
        }
      }
      themeLevel {
        id
        level
      }
    }
  }
`;

const Recap = ({ t }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { menuParams } = location.state || {};

  /*
  |--------------------------------------------------------------------------
  | STATES
  |--------------------------------------------------------------------------
  */
  const [active, setActive] = useState(menuParams ? menu[1] : menu[0]);
  const [isStatistics, setIsStatistics] = useState(active === menu[0]);
  /*
  |--------------------------------------------------------------------------
  | FETCHING
  |--------------------------------------------------------------------------
  */
  const { data: stats, loading: statsLoading } = useQuery(GET_STATS, {
    variables: { date },
    fetchPolicy: "cache-first",
  });

  const { data: themes, loading: themesLoading } = useQuery(GET_THEMES, {
    variables: { date },
    fetchPolicy: "cache-first",
  });

  /*
  |--------------------------------------------------------------------------
  | FUNCTIONS
  |--------------------------------------------------------------------------
  */
  const goBack = () => navigate("/home", { refresh: true });

  const onRecap = (currentTheme) => {
    navigate("/quiz_day_recap", {
      state: {
        currentTheme,
      },
    });
  };

  useEffect(() => {
    setIsStatistics(active === menu[0]);
  }, [active]);

  /*
  |--------------------------------------------------------------------------
  | VARIABLES
  |--------------------------------------------------------------------------
  */
  const loading = statsLoading || themesLoading;
  const data = stats?.getDaySummary;

  const statistics = [
    {
      isDark: true,
      color: "yellow",
      label: "recap_win_points",
      value: data?.todayPoints || 0,
    },
    {
      color: "red",
      isRanking: true,
      label: "recap_month_ranking",
      value: data?.monthRank || 0,
    },
    {
      isDark: true,
      color: "lightblue",
      label: "recap_theme_treated",
      value: data?.themesCount || 0,
    },
    {
      color: "violet",
      label: "recap_question_treated",
      value: data?.questionsCount || 0,
    },
    {
      isDark: true,
      color: "green",
      label: "recap_right_answers",
      value: data?.goodAnswersCount || 0,
    },
    {
      isDark: false,
      color: "orange",
      isPercentage: true,
      label: "recap_right_answers_percent",
      value: data?.goodAnswersPercentage || 0,
    },
  ];

  const progression = ({ item }) => {
    const items = themes?.getDayThemeLevels;
    const currentLevel = item?.themeLevel?.level;

    const levels = items
      .filter((i) => i?.theme?.id === item?.theme?.id)
      .map((i) => i?.themeLevel?.level);

    const max = Math.max(...levels);

    return levels && currentLevel < max ? 100 : null;
  };

  /*
  |--------------------------------------------------------------------------
  | MAIN RENDER
  |--------------------------------------------------------------------------
  */
  return (
    <Layout
      noSafe={true}
      noHeight={isStatistics ? true : false}
      noScroll
      headerConfig={{
        position: "relative",
        title: t("recap_title"),
        style: { paddingBottom: 0 },
        filters: { active, setActive, menu },
        rightIconConfig: { name: "x", action: goBack },
        wrapperStyle: { borderBottomLeftRadius: 0, borderBottomRightRadius: 0 },
        children: isStatistics && (
          <>
            <Text color={theme.colors.white} style={{ marginBottom: 5 }}>
              {t("recap_score")}
            </Text>
            {loading ? (
              <ActivityIndicator size="large" color={theme.colors.white} />
            ) : (
              <Title color={theme.colors.white} fontSize={28}>
                {t("points", { count: data?.totalPoints || 0 })}
              </Title>
            )}
          </>
        ),
      }}
    >
      <Wrapper isStatistics={isStatistics}>
        <Blue isStatistics={isStatistics} />
        {!loading && (
          <GridContainer isStatistics={isStatistics}>
            {(isStatistics ? statistics : themes?.getDayThemeLevels).map(
              (item, index) =>
                isStatistics ? (
                  <StatisticsCard
                    loading={loading}
                    statistic={item}
                    index={index}
                    key={index}
                  />
                ) : (
                  <CategoryCard
                    key={index}
                    index={index}
                    category={item.theme}
                    level={item?.themeLevel?.level}
                    progression={progression({ item })}
                    onClick={() => onRecap(item)}
                    answeredQuestionsCount={item.answeredQuestionsCount}
                    route={"recap"}
                  />
                )
            )}
          </GridContainer>
        )}

        {(!isStatistics && themes?.getDayThemeLevels.length === 0) ||
        (isStatistics && statistics.length === 0) ? (
          loading ? (
            <ActivityIndicator size="large" color={theme.colors.blue} />
          ) : (
            <Text
              textAlign="center"
              style={{
                zIndex: "1",
                position: "relative",
              }}
            >
              {t("empty_styles_day")}
            </Text>
          )
        ) : null}
      </Wrapper>
    </Layout>
  );
};

const Wrapper = styled.div`
  width: 100%;
  position: relative;
  height: 100%;
`;

const Blue = styled.div`
  left: 0;
  width: 100%;
  height: 100px;
  position: absolute;
  border-bottom-left-radius: 50px;
  border-bottom-right-radius: 50px;
  background-color: ${(props) => props.theme.colors.blue};
`;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: ${({ isStatistics }) =>
    isStatistics ? "repeat(2, 1fr)" : "1fr"};
  grid-gap: 20px;
  z-index: 1;
  position: relative;
  margin-top: 20px;
  justify-items: center;
  overflow: auto;
  padding-top: ${({ isStatistics }) => (isStatistics ? "0" : "30px")};
`;

export default withTranslation()(Recap);

const ActivityIndicator = styled.div`
  width: 30px;
  height: 30px;
  border: 4px solid ${theme.colors.white};
  border-top: 4px solid transparent;
  border-radius: 50%;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
