import React from "react";
import { theme } from "../../styles";

const Text = ({
  color,
  style,
  onClick,
  fontSize,
  children,
  textAlign,
  fontFamily,
  fontStyle,
  fontWeight,
  textTransform,
  ...props
}) => (
  <div
    {...props}
    style={{
      ...style,
      color: color || theme.colors.black,
      textAlign: textAlign || "left",
      fontFamily: fontFamily || theme.fonts.regular,
      fontSize: `${fontSize || 14}px`,
      fontStyle: fontStyle || "none",
      fontWeight: fontWeight || 400,
      textTransform: textTransform || "uppercase",
    }}
  >
    {children}
  </div>
);

export default Text;
