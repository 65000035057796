import React from "react";
import styled from "styled-components";

const KeyboardWrapper = ({ children, style }) => (
  <S.Wrapper style={style}>{children}</S.Wrapper>
);

const S = {
  Wrapper: styled.div`
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow-y: auto;
  `,
};

export default KeyboardWrapper;
