import React from "react";
import styled from "styled-components";
import { Text } from "../atoms";
import { theme } from "../../styles";

const Tags = ({ value, isActive, onClick, colorConfig, style, isLast }) => (
  <Styled.Item
    style={style}
    onClick={onClick}
    isActive={isActive}
    colorConfig={colorConfig}
    isLast={isLast}
  >
    <Text
      fontSize={12}
      fontFamily={isActive ? theme.fonts.semiBold : theme.fonts.medium}
      fontWeight={isActive ? 600 : 500}
      color={
        isActive
          ? colorConfig?.active?.color || theme.colors.black
          : colorConfig?.inactive?.color || theme.colors.white
      }
    >
      {value}
    </Text>
  </Styled.Item>
);

const Styled = {
  Item: styled.button`
    justify-content: center;
    border-radius: 15px;
    align-items: center;
    padding: 10px 15px;
    margin-right: ${({ isLast }) => (isLast ? "0px" : "10px")};
    background-color: ${({ isActive, colorConfig }) =>
      isActive
        ? colorConfig?.active?.backgroundColor || theme.colors.white
        : colorConfig?.inactive?.backgroundColor || "rgba(255, 255, 255, 0.2)"};
    border: none;
    cursor: pointer;
    text-decoration: none;
    color: inherit;
    font: inherit;
  `,
};

export default Tags;
