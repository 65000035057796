import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Progress } from "../../molecules";
import { theme as styles } from "../../../styles";
import styled from "styled-components";
import { withTranslation } from "react-i18next";

const badges = {
  0: styles.icons.medals.bronze,
  1: styles.icons.medals.bronze,
  2: styles.icons.medals.silver,
  3: styles.icons.medals.gold,
  4: styles.icons.medals.diamond,
};

const secToMin = (value) => Math.floor(value / 60);

const MissionCard = ({
  t,
  type,
  theme,
  index,
  onClick,
  badge,
  count,
  stepsArray,
}) => {
  const width = window.innerWidth;

  const isPlayingTime = type === "playingTime";
  const isLast = badge === 4;

  const [progress, setProgress] = useState(0);
  const [label, setLabel] = useState(null);
  const [userCount, setUserCount] = useState(0);

  useEffect(() => {
    if (
      badge !== null &&
      count !== null &&
      stepsArray &&
      !_.isEmpty(stepsArray)
    ) {
      const newCount =
        count === 0
          ? 0
          : isPlayingTime
          ? count < 60
            ? 0
            : secToMin(count)
          : count;

      const target = isLast ? _.last(stepsArray) : stepsArray[badge];
      const newTarget = isPlayingTime ? secToMin(target) : target;

      const newLabel = isLast
        ? `${newTarget}/${newTarget}`
        : `${newCount}/${newTarget}`;

      const newProgress = isLast
        ? 100
        : Math.round((newCount / newTarget) * 100);

      setUserCount(newCount);
      setLabel(newLabel);
      setProgress(newProgress);
    }
  }, [type, badge, count, stepsArray, isPlayingTime, isLast]);

  function getDescription() {
    const isRank = type === "monthRank" || type === "yearRank";
    const r = isRank ? (userCount === 1 ? "_1_first" : "_1") : "";
    const th = theme?.name
      ? isRank && userCount === 1
        ? "_theme_first"
        : "_theme"
      : "";
    const description = `${type}_description${r}${th}`;

    return t(description, {
      count: isPlayingTime
        ? secToMin(isLast ? _.last(stepsArray) : stepsArray[badge])
        : isLast
        ? _.last(stepsArray)
        : stepsArray[badge],
      theme: theme?.name,
    });
  }

  return (
    <Wrapper
      style={{
        position: "relative",
        width: width - 40,
      }}
    >
      <Card
        onClick={onClick}
        style={{ cursor: onClick ? "pointer" : "default" }}
      >
        <Content>
          <div style={{ width: 60, height: 80, marginRight: 10 }}>
            <Medals
              style={{ opacity: badge === 0 ? 0.1 : 1 }}
              src={badges[badge]}
            />
          </div>
          <div style={{ flex: 1 }}>
            {type && (
              <Text
                fontSize={17}
                style={{
                  marginBottom: 5,
                  fontWeight: 700,
                }}
              >
                {t(type)}
              </Text>
            )}
            {getDescription() && <Text>{getDescription()}</Text>}
          </div>
        </Content>

        <Progress
          index={index}
          backgroundColor="#CCDEFF"
          color={styles.colors.blue}
          progression={progress || 0}
        />
      </Card>
      {label && (
        <Tooltip>
          <Text
            fontSize={14}
            style={{
              textTransform: "uppercase",
              fontWeight: 900,
              fontStyle: "italic",
            }}
          >
            {label}
          </Text>
        </Tooltip>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
  margin-bottom: 20px;
  border-radius: 20px;
`;

const Card = styled.div`
  width: 100%;
  position: relative;
  overflow: hidden;
  border-radius: 20px;
  background-color: ${styles.colors.white};
`;

const Medals = styled.img`
  height: 100%;
  width: 100%;
  object-fit: contain;
`;

const Content = styled.div`
  padding: 20px;
  display: flex;
  align-items: center;
`;

const Tooltip = styled.div`
  background-color: white;
  position: absolute;
  padding: 5px 10px;
  z-index: 100;
  right: -10px;
  top: -5px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.16);
  border-radius: 10px 10px 10px 0;
`;

const Text = styled.div`
  font-size: ${(props) => props.fontSize || 14}px;
  font-family: ${(props) => props.fontFamily || "Arial"};
  margin-bottom: ${(props) =>
    props.marginBottom ? `${props.marginBottom}px` : "0"};
`;

export default withTranslation()(MissionCard);
