import React from "react";
import styled, { keyframes } from "styled-components";
import icon from "../../../assets/icon.png";

const bounce = keyframes`
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
`;

const LoadingScreen = () => (
  <S.Wrapper>
    <S.AnimatedItem>
      <S.Image src={icon} alt="Loading Icon" />
    </S.AnimatedItem>
  </S.Wrapper>
);

const S = {
  Wrapper: styled.div`
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    background-color: #0068ff;
    height: 100vh; /* Ensure it takes full screen height */
  `,
  Image: styled.img`
    height: 150px;
    width: 150px;
    object-fit: contain;
  `,
  AnimatedItem: styled.div`
    animation: ${bounce} 2s infinite;
  `,
};

export default LoadingScreen;
