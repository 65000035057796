import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import styled from "styled-components";
import FeatherIcon from "feather-icons-react";
import { Text } from "../../atoms"; // Ensure to update these imports accordingly
import { Progress } from "../../molecules";
import { theme } from "../../../styles";
import { useSpring, animated } from "react-spring";

const CategoryCard = ({
  t,
  level,
  index,
  isLast,
  onClick,
  category,
  isDisabled,
  progression,
  answeredQuestionsCount,
  route,
}) => {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const color = category?.color || theme.colors.white;

  const animationProps = useSpring({
    to: { opacity: 1, transform: "translateX(0)" },
    from: { opacity: 0, transform: "translateX(100px)" },
    delay: 1000 + index * 200,
  });

  return (
    <AnimatedCard style={animationProps}>
      <S.Card
        route={route}
        islast={isLast ? true : false}
        width={width - 40}
        // isdisabled={isDisabled ? true : false}
        onClick={isDisabled ? null : onClick}
        style={{
          pointerEvents: isDisabled ? "none" : "auto",
          opacity: isDisabled ? 0.4 : 1,
        }}
        answeredquestionscount={answeredQuestionsCount}
        backgroundcolor={category?.backgroundColor || theme.colors.violet}
      >
        {(category?.coverFile || category?.cover?.file) && (
          <S.Illustration
            src={
              category?.cover?.file ? category.cover.file : category.coverFile
            }
            alt="category cover"
          />
        )}

        <S.Content>
          <div style={{ flexGrow: 1 }}>
            {category?.name && (
              <Text
                fontSize={20}
                color={color}
                style={{ marginBottom: 5 }}
                fontFamily={theme.fonts.bold}
                fontWeight={700}
              >
                {category.name}
              </Text>
            )}

            {category?.progression?.level && (
              <Text
                color={color}
                style={{ opacity: 0.8 }}
                fontFamily={theme.fonts.semiBold}
                fontWeight={600}
              >
                {t("level", { level: level || category.progression.level })}
              </Text>
            )}

            {answeredQuestionsCount && (
              <Text style={{ marginTop: 10 }} color={color}>
                {t("treated_questions_counter", {
                  count: answeredQuestionsCount || 0,
                })}
              </Text>
            )}
          </div>

          {answeredQuestionsCount && (
            <S.Button>
              <FeatherIcon color={color} size={20} icon="chevron-right" />
            </S.Button>
          )}
        </S.Content>

        {!answeredQuestionsCount && (
          <Progress
            progression={category?.progression?.percentage || 0}
            index={index}
          />
        )}

        <S.Tooltip>
          <Text
            fontSize={14}
            textTransform="uppercase"
            fontFamily={theme.fonts.blackItalic}
            fontWeight={900}
            fontStyle={"italic"}
          >
            {`${progression || category?.progression?.percentage || 0}%`}
          </Text>
        </S.Tooltip>
      </S.Card>
    </AnimatedCard>
  );
};

const S = {
  Card: styled.div`
    background-color: ${({ backgroundcolor }) => backgroundcolor};
    margin-bottom: ${({ islast }) => (islast ? "0px" : "20px")};
    min-height: ${({ answeredquestionscount }) =>
      answeredquestionscount ? "150px" : "130px"};
    max-height: ${({ answeredquestionscount }) =>
      answeredquestionscount ? "150px" : "130px"};
    width: ${({ width }) => (width ? `${width}px` : "unset")};
    border-radius: ${({ route }) =>
      route == "recap" ? "25px" : "25px 25px 0px 0px"};
    position: relative;
    overflow: visible;
    display: flex;
    justify-content: center;
    flex-direction: column;
  `,
  Content: styled.div`
    display: flex;
    align-items: center;
    padding: 30px;
    flex-grow: 1;
  `,
  Illustration: styled.img`
    object-fit: cover;
    position: absolute;
    opacity: 0.7;
    height: 100%;
    width: 100%;
    left: 0px;
    top: 0px;
  `,
  Button: styled.div`
    background-color: rgba(255, 255, 255, 0.6);
    display: flex;
    justify-content: center;
    border-radius: 100px;
    align-items: center;
    padding: 5px;
  `,
  Tooltip: styled.div`
    background-color: white;
    position: absolute;
    padding: 5px 10px;
    box-shadow: 0 5px 6.68px rgba(0, 0, 0, 0.16);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    right: -10px;
    top: -5px;
  `,
};

const AnimatedCard = animated(S.Card);

export default withTranslation()(CategoryCard);
