import { gql, useQuery } from "@apollo/client";
import { useStoreState } from "easy-peasy";
import _ from "lodash";
import React from "react";
import { useTranslation } from "react-i18next";
import styled, { withTheme } from "styled-components";
import { Layout, Text, Title } from "../components";
import { common } from "../utils";
import { useLocation, useNavigate } from "react-router-dom";

/*
|--------------------------------------------------------------------------
| QUERIES
|--------------------------------------------------------------------------
*/
const GET_RANK = gql`
  query getRanks(
    $themeId: ID
    $date: String!
    $type: GetRankTypeEnum!
    $scope: GetRankScopeEnum
    $length: GetRankLengthEnum!
    $includeProgression: Boolean
  ) {
    getRanks(
      type: $type
      date: $date
      scope: $scope
      length: $length
      themeId: $themeId
      includeProgression: $includeProgression
    ) {
      rank {
        id
        rank
      }
    }
  }
`;

/*
|--------------------------------------------------------------------------
| VARIABLES
|--------------------------------------------------------------------------
*/
const date = new Date();
const currentMonth = date.getMonth();
const currentYear = date.getFullYear();

const ResultRanking = ({ theme }) => {
  /*
  |--------------------------------------------------------------------------
  | STORE
  |--------------------------------------------------------------------------
  */
  const isDany = useStoreState((state) => state.isDany);

  /*
  |--------------------------------------------------------------------------
  | HOOKS
  |--------------------------------------------------------------------------
  */
  const { t } = useTranslation();
  const width = window.innerWidth;
  const height = window.innerHeight;
  const navigate = useNavigate();
  const location = useLocation();

  /*
  |--------------------------------------------------------------------------
  | VARIABLES
  |--------------------------------------------------------------------------
  */
  const { target } = location.state || {};
  const isYear = target === "year";

  const year = currentMonth === 0 || isYear ? currentYear - 1 : currentYear;

  const month = isYear
    ? "01"
    : currentMonth === 0
    ? "11"
    : _.size(_.toString(currentMonth)) > 1
    ? currentMonth
    : `0${currentMonth}`;

  const dateFormatted = `${year}/${isYear ? "01" : month}/01`;

  /*
  |--------------------------------------------------------------------------
  | FETCHING
  |--------------------------------------------------------------------------
  */
  const { data: memberData, loading: memberLoading } = useQuery(GET_RANK, {
    variables: {
      type: "user",
      date: dateFormatted,
      includeProgression: false,
      length: target,
    },
    fetchPolicy: "cache-and-network",
  });

  const { data: teamData, loading: teamLoading } = useQuery(GET_RANK, {
    variables: {
      type: "team",
      date: dateFormatted,
      includeProgression: false,
      length: target,
    },
    fetchPolicy: "cache-and-network",
  });

  /*
  |--------------------------------------------------------------------------
  | CONFIG
  |--------------------------------------------------------------------------
  */
  const loading = memberLoading || teamLoading;

  const ranks = {
    member: memberData?.getRanks?.rank?.rank || "0",
    team: teamData?.getRanks?.rank?.rank || "0",
  };

  const isFirst = ({ key }) => {
    return [1, 2, 3, 4, 5].includes(ranks[key]);
  };

  /*
  |--------------------------------------------------------------------------
  | STATES
  |--------------------------------------------------------------------------
  */
  return (
    <Layout
      backgroundColor={{ main: theme.colors.blue }}
      statusBarConfig={{ barStyle: "light-content" }}
      headerConfig={{
        rightIconConfig: { name: "x", action: () => navigate("/ranking") },
      }}
      footerConfig={{
        action: () => window.history.back(),
        title: t("end_ranking_button"),
      }}
    >
      <S.Wrapper>
        {(isFirst({ key: "member" }) || isFirst({ key: "team" })) && (
          <S.Light
            style={{ height, width }}
            src={require("../assets/pictures/sun.png")}
          />
        )}
        <Title
          fontSize={22}
          textAlign="center"
          color={theme.colors.white}
          style={{ width: width / 1.5, marginTop: 60 }}
        >
          {t(!isYear ? "end_ranking_month" : "end_ranking_year")}
        </Title>

        <Text
          color={theme.colors.white}
          style={{ marginTop: 5, marginBottom: 28 }}
        >
          {!isYear
            ? `${common.months[currentMonth - 1]} ${year}`
            : currentYear - 1}
        </Text>

        {isDany && !loading && (
          <>
            <S.IllustrationWrapper>
              <S.IllustrationItem
                src={
                  !(isFirst({ key: "member" }) || isFirst({ key: "team" }))
                    ? require("../assets/images/win_versus_inad.png")
                    : require("../assets/images/encouragement_dany.png")
                }
                alt="Illustration"
              />
            </S.IllustrationWrapper>
            <Text
              style={{ marginVertical: 20, paddingHorizontal: 20 }}
              fontFamily={theme.fonts.medium}
              fontWeight={500}
              color={theme.colors.white}
              textAlign="center"
              fontSize={16}
            >
              {t(
                isFirst({ key: "member" }) || isFirst({ key: "team" })
                  ? "end_ranking_best"
                  : "end_ranking_encouragement"
              )}
            </Text>
          </>
        )}

        {loading ? (
          <S.LoadingIndicator color={theme.colors.white} size="large" />
        ) : (
          ["member", "team"].map((key) => (
            <S.Ranking
              key={key}
              width={width - 40}
              isFirst={isFirst({ key })}
              isTeam={key !== "member"}
            >
              {isFirst({ key }) && !isDany && (
                <S.Applause src={require("../assets/images/applause.png")} />
              )}

              {isFirst({ key }) && (
                <S.Bg src={require("../assets/images/bg_couronne.png")} />
              )}

              <Title
                color={theme.colors.white}
                fontSize={ranks[key] > 0 ? 35 : 16}
              >
                {ranks[key] > 0 ? `#${ranks[key]}` : "Non classé"}
              </Title>

              {ranks[key] > 0 && (
                <Text
                  fontFamily={theme.fonts.medium}
                  fontWeight={500}
                  color={theme.colors.white}
                  textAlign="center"
                  fontSize={16}
                >
                  {t(`end_ranking_${key}_label`)}
                </Text>
              )}
            </S.Ranking>
          ))
        )}

        {!isDany && (
          <Text
            textAlign="center"
            color={theme.colors.white}
            fontFamily={theme.fonts.medium}
            fontWeight={500}
          >
            {t("end_ranking_description")}
          </Text>
        )}
      </S.Wrapper>
    </Layout>
  );
};

/*
|--------------------------------------------------------------------------
| STYLES
|--------------------------------------------------------------------------
*/
const S = {
  Wrapper: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    padding: 0 20px;
    height: 100%;
  `,
  Light: styled.img`
    position: absolute;
    opacity: 0.4;
    z-index: 0;
    left: 0;
    top: -130px;
    width: 100%;
  `,
  Ranking: styled.div`
    min-height: 90px;
    overflow: hidden;
    position: relative;
    border-radius: 20px;
    align-items: center;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    background-color: ${(props) =>
      props.isTeam ? "#00C2FF" : props.theme.colors.violet};
    width: ${(props) => props.width}px;
  `,
  IllustrationWrapper: styled.div`
    height: 200px;
    width: 100%;
  `,
  IllustrationItem: styled.img`
    object-fit: contain;
    height: 100%;
    width: 100%;
  `,
  Applause: styled.img`
    left: 0;
    top: -40px;
    width: 80px;
    height: 80px;
    position: absolute;
    object-fit: contain;
  `,
  Bg: styled.img`
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
  `,
  LoadingIndicator: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${(props) => props.color};
    font-size: ${(props) => (props.size === "large" ? "32px" : "16px")};
  `,
};

export default withTheme(ResultRanking);
