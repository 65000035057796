import { action, createStore, persist } from 'easy-peasy';

const model = {
  notificationToken: null,
  setNotificationToken: action((state, payload) => ({
    ...state,
    notificationToken: payload,
  })),

  notification: null,
  setNotification: action((state, payload) => ({
    ...state,
    notification: payload,
  })),

  badges: [],
  setBadges: action((state, payload) => ({ ...state, badges: payload })),

  showOnboarding: true,
  setShowOnboarding: action((state, payload) => ({
    ...state,
    showOnboarding: payload,
  })),

  user: null,
  setUser: action((state, payload) => ({
    ...state,
    user: payload,
  })),

  token: null,
  setToken: action((state, payload) => ({ ...state, token: payload })),

  timezone: null,
  setTimezone: action((state, payload) => ({ ...state, timezone: payload })),

  companyId: null,
  setCompanyId: action((state, payload) => ({ ...state, companyId: payload })),

  isAuthenticated: false,
  setIsAuthenticated: action((state, payload) => ({
    ...state,
    isAuthenticated: payload,
  })),

  signIn: action((state, payload) => ({
    ...state,
    user: payload?.user,
    isAuthenticated: true,
    token: payload?.token,
  })),

  logOut: action((state) => ({
    ...state,
    user: null,
    notifToken: null,
    isAuthenticated: false,
    notificationToken: null,
    setShowOnboarding: false,
  })),

  isDany: false,
  setIsDany: action((state, payload) => ({ ...state, isDany: payload })),

  isFirstLog: true,
  setIsFirstLog: action((state, payload) => ({
    ...state,
    isFirstLog: payload,
  })),

  hydrated: false,
  setHydrated: action((state, payload) => ({ ...state, hydrated: payload })),

  themes: [],
  setThemes: action((state, payload) => ({ ...state, themes: payload })),

  countConnection: 0,
  setCountConnection: action((state, payload) => ({
    ...state,
    countConnection: payload,
  })),

  isRate: false,
  setIsRate: action((state, payload) => ({
    ...state,
    isRate: payload,
  })),
};

const store = createStore(persist(model));

export default store;
