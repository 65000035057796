import { gql, useMutation, useLazyQuery } from "@apollo/client";
import { useStoreActions, useStoreState } from "easy-peasy";
import _, { isNull } from "lodash";
import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import styled, { withTheme } from "styled-components";
import authBackground from "../assets/pictures/auth_background.png";
import { Layout, Text, Title } from "../components"; // Adjust the import path
import { LogoSVG } from "../components/svg"; // Adjust the import path
import { storage } from "../services"; // Adjust the import path
import { useNavigate } from "react-router-dom";
import { Oval } from "react-loader-spinner";

/*
|--------------------------------------------------------------------------
| QUERIES
|--------------------------------------------------------------------------
*/
const ADD_NOTIF_TOKEN = gql`
  mutation addPushNotificationRegistrationId($registrationId: String!) {
    addPushNotificationRegistrationId(registrationId: $registrationId) {
      success
    }
  }
`;

const WITH_EMAIL = gql`
  mutation signin($username: String!, $password: String!) {
    authenticateUserWithEmailPassword(email: $username, password: $password) {
      token
      item {
        id
      }
    }
  }
`;

const WITH_USERNAME = gql`
  mutation signin($username: String!, $password: String!) {
    authenticateUserWithUsernamePassword(
      username: $username
      password: $password
    ) {
      token
      item {
        id
      }
    }
  }
`;

const GET_USER = gql`
  query getUser($userId: ID!) {
    User(where: { id: $userId }) {
      id
      name
      username
      blockedWeekEnd
      blockedUntil
      email
      isBlocked
      notifications
      picture {
        id
        thumbnail
      }
      team {
        id
        name
        users {
          id
          name
          picture {
            thumbnail
          }
        }
      }
    }
  }
`;

const GET_COMPANY = gql`
  query getCompany($accessCode: String!) {
    getCompany(accessCode: $accessCode) {
      id
      name
      email
      showDany
    }
  }
`;

const Login = ({ theme, t }) => {
  /*
  |--------------------------------------------------------------------------
  | STATES
  |--------------------------------------------------------------------------
  */
  const [err, setErr] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isAccepted, setIsAccepted] = useState(false);
  const [loginFailed, setLoginFailed] = useState(false);

  /*
  |--------------------------------------------------------------------------
  | STORE
  |--------------------------------------------------------------------------
  */
  const signIn = useStoreActions((actions) => actions.signIn);
  const showCGU = useStoreState((state) => state.isFirstLog);
  const setNotificationToken = useStoreActions(
    (actions) => actions.setNotificationToken
  );
  const logOut = useStoreActions((actions) => actions.logOut);

  /*
  |--------------------------------------------------------------------------
  | VARIABLES
  |--------------------------------------------------------------------------
  */
  const params = new URLSearchParams(
    window.location.search.replace(/\+/g, "%2B")
  );

  let accessCode = params.get("accesscode");
  let username = params.get("username");
  let password = params.get("password");

  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
  const isEmail = !_.isEmpty(username) && username.includes("@");
  const currentMutation = isEmail ? WITH_EMAIL : WITH_USERNAME;

  const isDisabled =
    _.isEmpty(username) || _.isEmpty(password) || (showCGU && !isAccepted);
  const setIsDany = useStoreActions((actions) => actions.setIsDany);
  const navigate = useNavigate();
  const setTimezone = useStoreActions((actions) => actions.setTimezone);

  const [getCompany] = useLazyQuery(GET_COMPANY, { fetchPolicy: "no-cache" });
  const [login] = useMutation(currentMutation);
  const [getUser] = useLazyQuery(GET_USER);

  const getStorageItem = (key, callback, isObject = false) => {
    const item = localStorage.getItem(key);
    if (item) {
      const value = isObject ? JSON.parse(item) : item;
      return value;
    } else {
      return null;
    }
  };

  const handleLogin = () => {
    setErr(null);
    if (_.isEmpty(username) || _.isEmpty(password)) {
      setErr("code_error");
      setIsLoginFailed();
      return;
    }

    setLoading(true);
    getCompany({ variables: { accessCode: accessCode.trim() } })
      .then(({ data: companyData, error: companyError }) => {
        if (companyError || companyData?.getCompany === null) {
          setIsLoginFailed();
          throw new Error("code_error");
        }

        const company = companyData?.getCompany;
        setIsDany(company?.showDany);
        const danyValue = company?.showDany ? true : false;
        storage.set({ key: "@dany", value: danyValue });
        storage.set({ key: "@companyId", value: company?.id });
        if (_.isEmpty(getStorageItem("@token"))) {
          login({
            variables: { username: username.trim(), password: password.trim() },
          })
            .then(({ data: loginData, error: loginError }) => {
              if (loginError) {
                const message =
                  loginError.message === "blocked_user"
                    ? "blocked_user"
                    : "login_error";
                setIsLoginFailed();
                throw new Error(message);
              }

              const token =
                loginData?.authenticateUserWithUsernamePassword?.token ||
                loginData?.authenticateUserWithEmailPassword?.token;

              const userId =
                loginData?.authenticateUserWithEmailPassword?.item?.id ||
                loginData?.authenticateUserWithUsernamePassword?.item?.id;

              if (_.isEmpty(token) || _.isEmpty(userId)) {
                setIsLoginFailed();
                throw new Error("login_error");
              }
              storage.set({ key: "@token", value: token });

              getUser({ variables: { userId } })
                .then(({ data: userData, error: userError }) => {
                  if (userError || !userData?.User?.id) {
                    setIsLoginFailed();
                    throw new Error("login_error");
                  }

                  storage.set({
                    key: "@user",
                    value: userData?.User,
                    isObject: true,
                  });
                  signIn({
                    user: userData?.User,
                    token: storage.get("@token"),
                  });
                  navigate("/home");
                  setLoading(false);
                })
                .catch((err) => {
                  setIsLoginFailed();
                  setErr(err.message || "An unexpected error occurred.");
                  setLoading(false);
                });
            })
            .catch((err) => {
              setIsLoginFailed();
              setErr(err.message || "An unexpected error occurred.");
              setLoading(false);
            });
        } else {
          navigate("/home");
        }
      })
      .catch((err) => {
        setIsLoginFailed();
        setErr(err.message || "An unexpected error occurred.");
        setLoading(false);
      });
  };

  useEffect(() => {
    setTimezone(timeZone);
    storage.set({ key: "@timezone", value: timeZone });
  }, [timeZone]);

  useEffect(() => {
    handleLogin();
  }, []);

  const setIsLoginFailed = () => {
    setTimeout(() => {
      setLoginFailed(true);
    }, 1000);
  };

  /*
  |--------------------------------------------------------------------------
  | MAIN RENDER
  |--------------------------------------------------------------------------
  */

  return (
    <Layout
      backgroundColor={{ main: theme.colors.blue }}
      statusBarConfig={{ barStyle: "light-content" }}
    >
      <S.Background src={authBackground} alt="Background" />

      <S.KeyboardWrapper>
        <LogoSVG />

        <S.Content>
          {!loginFailed ? (
            <LoadingContainer>
              <Oval height={80} width={80} color={theme.colors.blue} />
            </LoadingContainer>
          ) : (
            <S.TextContainer>
              <Title
                textAlign="center"
                style={{ marginBottom: 42, paddingHorizontal: 20 }}
              >
                {t("connection_title")}
              </Title>
            </S.TextContainer>
          )}
        </S.Content>
      </S.KeyboardWrapper>
    </Layout>
  );
};

/*
|--------------------------------------------------------------------------
| STYLES
|--------------------------------------------------------------------------
*/
const S = {
  Background: styled.img`
    position: absolute;
    height: 500px;
    width: 500px;
  `,
  KeyboardWrapper: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 40px;
    z-index: 1;
  `,
  CGU: styled.div`
    display: flex;
    align-items: center;
    margin: 20px 0;
    width: ${({ width }) => `${width}px`};
  `,
  Box: styled.div`
    width: 20px;
    height: 20px;
    border-radius: 5px;
    margin-right: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.5);
    cursor: pointer;
  `,
  Content: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
  `,
  TextContainer: styled.div`
    margin: 20px;
  `,
};

const LoadingContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
`;

export default withTheme(withTranslation()(Login));
