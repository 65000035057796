import React from "react";
import { withTranslation } from "react-i18next";
import styled from "styled-components";
import { Text } from "../../atoms";
import { Title } from "../../molecules";
import { theme } from "../../../styles";

const StatisticsCard = ({ t, statistic, loading }) => {
  const width = window.innerWidth; // For web, consider using 'react-use' for window size

  function getLabel(item) {
    if (item.isPercentage) return `${item.value}%`; // Removed translation for value
    if (item.isRanking) return `#${item.value}`;
    return item.value;
  }

  return (
    <StyledCard
      width={(width - 70) / 2}
      backgroundColor={theme.colors[statistic.color]}
    >
      {loading ? (
        <StyledSpinner>
          <div className="loader"></div>
        </StyledSpinner>
      ) : (
        <>
          <Title
            fontSize={35}
            textAlign="center"
            fontStyle={"italic"}
            color={statistic.isDark ? theme.colors.black : theme.colors.white}
          >
            {getLabel(statistic)}
          </Title>
          <Text
            fontSize={16}
            textAlign="center"
            style={{ marginTop: 5 }}
            fontFamily={theme.fonts.medium}
            fontWeight={500}
            color={statistic.isDark ? theme.colors.black : theme.colors.white}
          >
            {t(statistic.label, { count: statistic.value })}
          </Text>
        </>
      )}
    </StyledCard>
  );
};

const StyledCard = styled.div`
  background-color: ${({ backgroundColor }) => backgroundColor};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  border-radius: 30px;
  height: 130px;
  padding: 20px;
  width: 145px;
  display: flex;
  flex-direction: column;
`;

const StyledSpinner = styled.div`
  .loader {
    border: 8px solid ${theme.colors.grey};
    border-top: 8px solid ${theme.colors.primary};
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export default withTranslation()(StatisticsCard);
