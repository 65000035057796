import React from "react";
import { withTranslation } from "react-i18next";
import styled from "styled-components";
import { Link, Title } from "../../molecules";
import { theme } from "../../../styles";
import { Countdown } from "../common";
import { useStoreState } from "easy-peasy";
import { CircularProgress } from "@mui/material";
import { X } from "feather-icons-react";

const CountdownCard = ({
  t,
  time,
  onClose,
  loading,
  onRecap,
  isBlocked,
  isDisabled,
  userLoading,
}) => {
  const width = window.innerWidth;
  const isFinish = time === 0;

  return (
    <Styled.Wrapper>
      <Styled.Countdown width={width}>
        {userLoading || loading ? (
          <CircularProgress color="primary" />
        ) : (
          <>
            <Styled.Close onClick={onClose}>
              <X size={20} />
            </Styled.Close>

            <Title
              fontSize={12}
              textAlign="center"
              color={theme.colors.black}
              style={{ marginBottom: isFinish || isDisabled ? 10 : 0 }}
            >
              {t(
                isBlocked
                  ? "countdown_blocked"
                  : isDisabled
                  ? "countdown_disabled"
                  : isFinish
                  ? "countdown_finish"
                  : "countdown_title"
              )}
            </Title>

            {!isFinish && !isDisabled && (
              <Countdown time={time} style={{ marginVertical: 10 }} />
            )}

            <StyledLink onClick={onRecap} color={theme.colors.grey}>
              {t("countdown_link")}
            </StyledLink>
          </>
        )}
      </Styled.Countdown>
    </Styled.Wrapper>
  );
};

const Styled = {
  Wrapper: styled.div`
    padding-top: 30px;
    padding-bottom: 60px;
  `,
  Countdown: styled.div`
    background-color: ${(props) => props.theme.colors.white};
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.13);
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    align-self: center;
    position: relative;
    elevation: 20;
    padding: 15px;
    display: flex;
    flex-direction: column;
    width: ${(props) => props.width / 1.5 + "px"};
  `,
  Close: styled.button`
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
    background-color: ${(p) => p.theme.colors.white};
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    position: absolute;
    height: 30px;
    right: -10px;
    width: 30px;
    top: -10px;
    border: none;
    cursor: pointer;
    display: flex;
  `,
};

const StyledLink = styled.a`
  font-size: 12px;
  color: ${(props) => props.color};
  cursor: pointer;
  text-decoration: underline;
  font-weight: 400;
  font-style: normal;
`;

export default withTranslation()(CountdownCard);
