import React from "react";
import { FiX } from "react-icons/fi";
import styled from "styled-components";
import { theme } from "../../../styles";

const OnClose = ({ onClose }) => (
  <StyledButton
    isRight
    onClick={onClose?.action}
    isAndroid={navigator.userAgent.includes("Android")}
  >
    <FiX size={35} color={onClose?.color || theme.colors.white} />
  </StyledButton>
);

const StyledButton = styled.button`
  z-index: 100;
  position: absolute;
  left: ${({ isRight }) => (isRight ? "auto" : "10px")};
  right: ${({ isRight }) => (!isRight ? "auto" : "10px")};
  top: ${({ isAndroid }) => (!isAndroid ? "0px" : "20px")};
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
`;

export default OnClose;
