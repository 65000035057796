import _ from "lodash";
import React, { useState, useEffect } from "react";
import { withTranslation } from "react-i18next";
import styled from "styled-components";
import { Text } from "../../atoms";
import { theme } from "../../../styles";
import FeatherIcon from "feather-icons-react";

const QuestionCard = ({
  t,
  answers,
  allAnswers,
  position,
  question,
  isCorrect,
  selectedAnswers,
}) => {
  const width = window.innerWidth;
  const [q, setQ] = React.useState([]);

  React.useEffect(() => {
    if (allAnswers)
      setQ({
        ...question,
        answers: allAnswers.filter(
          (answer) => answer.questionId === question.id
        ),
      });
    else setQ(question);
  }, [allAnswers, question]);

  const myAnswers = _.filter(answers, (o) => selectedAnswers.includes(o.id));

  return (
    <StyledCardWrapper width={width - 40}>
      {position && (
        <Text
          style={{ opacity: 0.6, marginBottom: 5 }}
          color={theme.colors.grey}
          fontSize={12}
        >
          {position}
        </Text>
      )}
      {question?.question && (
        <Text
          fontSize={17}
          style={{ marginBottom: 15 }}
          fontFamily={theme.fonts.bold}
          fontWeight={700}
        >
          {question.question}
        </Text>
      )}
      {question?.picture?.thumbnail && (
        <StyledIllustrationWrapper>
          <StyledIllustrationItem src={question?.picture?.thumbnail} />
        </StyledIllustrationWrapper>
      )}

      {myAnswers && !_.isEmpty(myAnswers) && (
        <StyledCardMyAnswer isCorrect={isCorrect}>
          <Text
            style={{ marginBottom: 10 }}
            fontFamily={theme.fonts.semiBold}
            fontWeight={600}
          >
            {t("your_answer", { count: _.size(myAnswers) })}
          </Text>
          {myAnswers.map((answer, myAnswerIndex) => (
            <Answer
              t={t}
              items={answers}
              answer={answer}
              key={answer.id}
              isCorrection={false}
              index={myAnswerIndex}
              isCorrect={answer.isCorrect}
            />
          ))}
        </StyledCardMyAnswer>
      )}

      {!isCorrect && (
        <>
          <Text
            style={{ marginBottom: 10 }}
            fontFamily={theme.fonts.semiBold}
            fontWeight={600}
          >
            {t("correct_answer", {
              count: _.size(_.filter(q.answers, (o) => o.isCorrect)),
            })}
          </Text>
          {q?.answers &&
            !_.isEmpty(q.answers) &&
            _.filter(q.answers, (o) => o.isCorrect).map(
              (answer, goodAnswerIndex) => (
                <Answer
                  t={t}
                  isCorrect
                  isCorrection
                  answer={answer}
                  key={answer.id}
                  index={goodAnswerIndex}
                  items={_.filter(q.answers, (o) => o.isCorrect)}
                />
              )
            )}
        </>
      )}

      {question?.correction && (
        <>
          <StyledCardDivider />
          <Text>{question.correction}</Text>
        </>
      )}
    </StyledCardWrapper>
  );
};

const Answer = ({ answer, index, isCorrection, isCorrect, items }) => (
  <StyledCardAnswer isLast={index === items.length - 1}>
    <StyledCardContent isCorrect={isCorrect}>
      {answer?.answer && <Text>{`" ${answer.answer} "`}</Text>}
    </StyledCardContent>

    <StyledCardIcon isCorrect={isCorrect} isCorrection={isCorrection}>
      <FeatherIcon
        size={25}
        icon={isCorrection ? "check" : isCorrect ? "check" : "x"}
        color={
          isCorrection
            ? theme.colors.black
            : isCorrect
            ? theme.colors.black
            : theme.colors.white
        }
      />
    </StyledCardIcon>
  </StyledCardAnswer>
);

const StyledCardWrapper = styled.div`
  background-color: ${(props) => props.theme.colors.white};
  border-radius: 20px;
  margin-bottom: 20px;
  align-self: center;
  padding: 20px;
  width: ${(props) => props.width}px;
`;

const StyledCardMyAnswer = styled.div`
  margin-bottom: ${({ isCorrect }) => (isCorrect ? "0px" : "20px")};
`;

const StyledCardAnswer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${({ isLast }) => (!isLast ? "20px" : "0")};
`;

const StyledCardDivider = styled.div`
  width: 100%;
  height: 0.5px;
  opacity: 0.3;
  margin: 20px 0;
  background-color: ${(props) => props.theme.colors.grey};
`;

const StyledCardContent = styled.div`
  flex: 1;
  padding-right: 30px;
  opacity: ${({ isCorrect }) => (isCorrect ? "1" : "0.5")};
`;

const StyledCardIcon = styled.div`
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ isCorrect, isCorrection }) =>
    isCorrection
      ? theme.colors.lightgrey
      : isCorrect
      ? theme.colors.green
      : theme.colors.red};
  border-radius: 50%;
`;

const StyledIllustrationWrapper = styled.div`
  height: 80px;
  width: 80px;
  border-radius: 25px;
  margin-bottom: 10px;
`;

const StyledIllustrationItem = styled.img`
  height: 100%;
  width: 100%;
  object-fit: contain;
`;

export default withTranslation()(QuestionCard);
