import { gql, useQuery } from "@apollo/client";
import { useStoreState } from "easy-peasy";
import _ from "lodash";
import React from "react";
import { withTranslation } from "react-i18next";
import styled from "styled-components";
import { Layout, StatisticsCard, Text, Title } from "../components";
import { theme } from "../styles";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";

/*
|--------------------------------------------------------------------------
| QUERIES
|--------------------------------------------------------------------------
*/
const GET_STATS = gql`
  query getThemeLevelSummary($themeLevelId: ID!) {
    getThemeLevelSummary(themeLevelId: $themeLevelId) {
      totalPoints
      questionsCount
      goodAnswersCount
      wrongAnswersCount
      goodAnswersPercentage
      wrongAnswersPercentage
      nextThemeLevel {
        id
        level
      }
      theme {
        id
        name
        backgroundColor
        color
        cover {
          file
        }
      }
    }
  }
`;

const QuizResult = ({ t }) => {
  const location = useLocation();
  const navigate = useNavigate();

  /*
  |--------------------------------------------------------------------------
  | VARIABLES
  |--------------------------------------------------------------------------
  */
  const { currentTheme, progression, nextThemeLevel } = location.state || {};
  /*
  |--------------------------------------------------------------------------
  | STORES
  |--------------------------------------------------------------------------
  */
  const isDany = useStoreState((state) => state.isDany);

  /*
  |--------------------------------------------------------------------------
  | FETCHING
  |--------------------------------------------------------------------------
  */
  const { data, loading } = useQuery(GET_STATS, {
    fetchPolicy: "network-only",
    variables: {
      themeLevelId: currentTheme.progression.themeLevelId,
    },
  });

  /*
  |--------------------------------------------------------------------------
  | VARIABLES
  |--------------------------------------------------------------------------
  */
  const width = window.innerWidth;

  const nextLevel = nextThemeLevel?.level;
  const isFinish = nextLevel === null || nextLevel === undefined;
  const statistics = [
    {
      isDark: true,
      color: "yellow",
      value: data?.getThemeLevelSummary?.totalPoints,
      label: "recap_win_points",
    },
    {
      color: "violet",
      value: data?.getThemeLevelSummary?.questionsCount,
      label: "recap_question_treated",
    },
    {
      isDark: true,
      color: "green",
      label: "recap_right_answers",
      value: data?.getThemeLevelSummary?.goodAnswersCount,
    },
    {
      isDark: true,
      color: "orange",
      isPercentage: true,
      value: data?.getThemeLevelSummary?.goodAnswersPercentage,
      label: "recap_right_answers_percent",
    },
  ];

  const illustration = isDany
    ? require("../assets/images/congrats_dany.png")
    : require("../assets/images/congrats.png");

  /*
  |--------------------------------------------------------------------------
  | FUNCTIONS
  |--------------------------------------------------------------------------
  */
  const onNextQuestions = () =>
    navigate("/quiz_rules", {
      state: { currentTheme: currentTheme?.id },
    });

  /*
  |--------------------------------------------------------------------------
  | MAIN RENDER
  |--------------------------------------------------------------------------
  */
  return (
    <Layout
      statusBarConfig={{ barStyle: "light-content" }}
      backgroundColor={{ main: theme.colors.blue }}
      onClose={{ action: () => navigate("/home", { refresh: true }) }}
      footerConfig={
        !isFinish && {
          title: t("next_level_button", { level: nextLevel }),
          action: onNextQuestions,
        }
      }
    >
      <Wrapper>
        <Illustration src={illustration} alt="Illustration" />
        <Title
          fontSize={28}
          textAlign="center"
          color={theme.colors.white}
          style={{ marginBottom: 5 }}
        >
          {t("congrats_title")}
        </Title>

        <Text
          fontSize={16}
          textAlign="center"
          color={theme.colors.white}
          fontFamily={theme.fonts.medium}
          fontWeight={500}
          style={{ padding: "0 40px" }}
        >
          {t("finish_theme_level", {
            level: progression?.level,
            theme: currentTheme?.name,
          })}
        </Text>

        <Statistics width={width - 40}>
          {statistics &&
            !_.isEmpty(statistics) &&
            statistics.map((statistic, index) => (
              <StatisticsCard
                statistic={statistic}
                loading={loading}
                index={index}
                key={index}
              />
            ))}
        </Statistics>

        {isFinish ? (
          <Text
            fontSize={16}
            textAlign="center"
            color={theme.colors.white}
            fontFamily={theme.fonts.medium}
            fontWeight={500}
          >
            {t("lvl_disabled", {
              level: progression?.level ? progression.level + 1 : 0,
            })}
          </Text>
        ) : (
          <>
            <Text
              fontSize={25}
              color={theme.colors.white}
              style={{ marginBottom: 10 }}
              fontFamily={theme.fonts.bold}
              fontWeight={700}
            >
              Bravo !
            </Text>

            <Text
              fontSize={16}
              textAlign="center"
              color={theme.colors.white}
              fontFamily={theme.fonts.medium}
              fontWeight={500}
            >
              {t("finish_theme_next_level", {
                level: nextLevel,
                theme: currentTheme?.name,
              })}
            </Text>
          </>
        )}
      </Wrapper>
    </Layout>
  );
};

/*
|--------------------------------------------------------------------------
| STYLES
|--------------------------------------------------------------------------
*/
const Wrapper = styled.div`
  display: flex;
  flex: 1;
  padding: 20px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const Illustration = styled.img`
  object-fit: contain;
  margin-bottom: 20px;
  height: 200px;
  width: 100%;
`;

const Statistics = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-self: center;
  align-items: center;
  flex-direction: row;
  margin: 10px 0;
  justify-content: space-evenly;
  gap: 5px;
`;

export default withTranslation()(QuizResult);
