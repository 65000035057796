import React from "react";
import styled from "styled-components";
import { theme } from "../../styles"; // Adjust the import path to your styles
import { Text } from "../atoms"; // Adjust the import path to your Text component
import { FaSpinner } from "react-icons/fa";

const Button = ({
  style,
  width,
  loading,
  onClick,
  fontSize,
  children,
  fontFamily,
  isDisabled,
  textTransform,
  backgroundColor,
  fontWeight,
  textAlignCenter,
}) => (
  <StyledButton
    width={width}
    style={style}
    onClick={!isDisabled ? onClick : null}
    disabled={isDisabled}
    backgroundColor={backgroundColor || theme.colors.yellow}
  >
    <Text
      fontSize={fontSize || 18}
      textTransform={textTransform || "none"}
      fontFamily={fontFamily || theme.fonts.blackItalic}
      fontWeight={fontWeight || 900}
      textAlign={textAlignCenter || "left"}
      style={{
        opacity: isDisabled ? 0.3 : 1,
        marginRight: loading ? 10 : 0,
      }}
      fontStyle={"italic"}
    >
      {children}
    </Text>
    {loading && <FaSpinner className="spinner" />}
  </StyledButton>
);

const StyledButton = styled.button`
  background-color: ${({ backgroundColor }) => backgroundColor};
  justify-content: center;
  flex-direction: row;
  border-radius: 15px;
  align-items: center;
  align-self: center;
  padding: 20px;
  display: flex;
  border: none;
  cursor: pointer;
  opacity: ${({ disabled }) => (disabled ? 0.6 : 1)};
  &:hover {
    opacity: ${({ disabled }) => (disabled ? 0.6 : 0.8)};
  }
  .spinner {
    margin-left: 10px;
    animation: spin 1s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export default Button;
