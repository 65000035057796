import { storage } from "../services";
import { useStoreActions } from "easy-peasy";

const Logout = ({}) => {
  const logOut = useStoreActions((actions) => actions.logOut);

  storage.set({ key: "@token", value: "" });
  storage.set({ key: "@companyId", value: "" });
  storage.set({ key: "@user", value: "" });
  logOut();
};

export default Logout;
