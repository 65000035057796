import React from "react";
import { FeatherIcon } from "feather-icons-react";
import styled from "styled-components";
import { theme } from "../../../styles";

const GoBack = ({ goBack }) => (
  <Styled.Button isRight={false} onClick={goBack?.action}>
    <FeatherIcon
      size={35}
      icon="chevron-left"
      color={goBack?.color || theme.colors.white}
    />
  </Styled.Button>
);

const Styled = {
  Button: styled.button`
    top: 0px;
    z-index: 100;
    position: absolute;
    left: ${({ isRight }) => (isRight ? "auto" : "20px")};
    right: ${({ isRight }) => (!isRight ? "auto" : "20px")};
    background-color: transparent;
    border: none;
    cursor: pointer;
  `,
};

export default GoBack;
