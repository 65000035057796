/* eslint-disable consistent-return */
import React, { useEffect, useRef } from "react";
import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  createHttpLink,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import {
  StoreProvider,
  useStoreActions,
  useStoreRehydrated,
  useStoreState,
} from "easy-peasy";
import { ThemeProvider } from "styled-components";
import { store } from "./store";
import { theme } from "./styles";
import { RootNavigator } from "./navigation";
import _ from "lodash";
import GlobalStyles from "./styles/GlobalStyles";
import { onError } from "@apollo/client/link/error";
import { storage } from "./services"; // Adjust the import path

const AppWithApollo = () => {
  const setNotification = useStoreActions((actions) => actions.setNotification);
  const navigationRef = useRef(null);

  useEffect(() => {
    // Notifications and app state logic for web
    // Implement your notification setup here
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <RootNavigator />
    </ThemeProvider>
  );
};

const AppWithStore = () => {
  const isRehydrated = useStoreRehydrated();
  const timezone = useStoreState((state) => state.timezone);
  const logOut = useStoreActions((actions) => actions.logOut);
  const setUser = useStoreActions((actions) => actions.setUser);
  const setIsDany = useStoreActions((actions) => actions.setIsDany);
  const setBadges = useStoreActions((actions) => actions.setBadges);
  const setTimezone = useStoreActions((actions) => actions.setTimezone);
  const setHydrated = useStoreActions((actions) => actions.setHydrated);
  const setShow = useStoreActions((actions) => actions.setShowOnboarding);
  const setAuth = useStoreActions((actions) => actions.setIsAuthenticated);
  const setCompanyId = useStoreActions((actions) => actions.setCompanyId);
  const setIsFirstLog = useStoreActions((actions) => actions.setIsFirstLog);
  const setNotificationToken = useStoreActions(
    (actions) => actions.setNotificationToken
  );
  const setIsRate = useStoreActions((actions) => actions.setIsRate);
  const setCountConnection = useStoreActions(
    (actions) => actions.setCountConnection
  );
  const token = useStoreState((state) => state.token);

  const setToken = useStoreActions((actions) => actions.setToken);
  const getStorageItem = (key, callback, isObject = false) => {
    const item = localStorage.getItem(key);
    if (item) {
      const value = isObject ? JSON.parse(item) : item;
      return value;
    } else {
      return null;
    }
  };
  useEffect(() => {
    getStorageItem("@onboarding", (v) => setShow(v !== "true"));
    getStorageItem("@dany", (v) => setIsDany(v === "true"));
    getStorageItem("@rate", (r) => setIsRate(r === "true"));
    getStorageItem("@timezone", (v) => setTimezone(v));
    getStorageItem("@companyId", (v) => setCompanyId(v));
    getStorageItem("@count", (v) => setCountConnection(v || 0), true);
    getStorageItem("@notificationToken", (tok) =>
      setNotificationToken(tok || null)
    );
    getStorageItem("@badges", (v) => setBadges(v !== "true"), true);
    getStorageItem("@token", (v) => (!_.isEmpty(v) ? setToken(v) : logOut()));
    getStorageItem(
      "@user",
      (v) => {
        if (!_.isEmpty(v)) {
          setUser(v);
          setAuth(true);
          setIsFirstLog(false);
        } else {
          logOut();
          storage.set({ key: "@token", value: "" });
          storage.set({ key: "@companyId", value: "" });
          storage.set({ key: "@user", value: "" });
        }
        setHydrated(true);
      },
      true
    );
  }, []);

  const httpLink = createHttpLink({
    uri: "https://backend.savedany.com/api",
  });

  const authLink = setContext((__, { headers }) => ({
    headers: getStorageItem("@token")
      ? {
          ...headers,
          Authorization: `Bearer ${getStorageItem("@token")}`,
          "x-timezone": timezone,
        }
      : { ...headers, "x-timezone": timezone },
  }));

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, extensions }) => {
        localStorage.removeItem("@token");
        logOut();
      });
    }

    if (networkError) {
      console.log(`[Network error]: ${networkError}`);
    }
  });

  const client = new ApolloClient({
    connectToDevTools: true,
    credentials: "include",
    cache: new InMemoryCache(),
    link: errorLink.concat(authLink).concat(httpLink),
  });

  return !isRehydrated ? null : (
    <ApolloProvider client={client}>
      <AppWithApollo />
    </ApolloProvider>
  );
};

const App = () => {
  return (
    <StoreProvider store={store}>
      <AppWithStore />
    </StoreProvider>
  );
};

export default App;
