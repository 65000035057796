import { useEffect, useState } from "react";
import _ from "lodash";
import styled from "styled-components";
import { Text } from "../atoms";
import { theme } from "../../styles";
import { Title } from "../molecules";
import { withTranslation, useTranslation } from "react-i18next";
import { useStoreState } from "easy-peasy";

const Swiper = ({ pages, onFinish }) => {
  const { t } = useTranslation();

  const isDany = useStoreState((state) => state.isDany);
  const [position, setPosition] = useState(1);
  const [dimensions, setDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const isSmallDevice = dimensions.height <= 680;

  useEffect(() => {
    const handleResize = () => {
      setDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const indicators =
    pages &&
    !_.isEmpty(pages) &&
    Array.from(Array(pages.length), (_, i) => i + 1);

  function onScroll(e) {
    const pageNumber = Math.round(e.target.scrollLeft / dimensions.width + 1);
    if (pageNumber !== position) {
      setPosition(pageNumber);
    }
  }

  useEffect(() => {
    if (position === pages.length) {
      onFinish();
    }
  }, [position, pages.length, onFinish]);

  return (
    pages &&
    !_.isEmpty(pages) && (
      <Styled.Container>
        <Styled.ScrollView
          onScroll={onScroll}
          style={{ width: dimensions.width }}
        >
          {pages.map((page, index) => (
            <Styled.Slide key={index} width={dimensions.width}>
              {page?.image && (
                <Styled.Illustration
                  isSmallDevice={isSmallDevice}
                  isFirst={index === 0}
                  src={page.image}
                  alt=""
                  isDany={isDany}
                />
              )}
              {page?.title && (
                <Title
                  fontSize={isSmallDevice ? 18 : 23}
                  textAlign="center"
                  style={{ marginBottom: 15 }}
                >
                  {t(page.title)}
                </Title>
              )}
              {page?.description && (
                <Text
                  fontSize={14}
                  textAlign="center"
                  color={theme.colors.white}
                  fontFamily={theme.fonts.medium}
                  fontWeight={500}
                >
                  {t(page.description)}
                </Text>
              )}
            </Styled.Slide>
          ))}
        </Styled.ScrollView>
        {indicators && !_.isEmpty(indicators) && (
          <Styled.IndicatorWrapper>
            {indicators.map((indicator, index) => (
              <Styled.IndicatorItem
                key={indicator}
                isLast={indicators.length - 1 === index}
                isActive={indicator === position}
              />
            ))}
          </Styled.IndicatorWrapper>
        )}
      </Styled.Container>
    )
  );
};

const Styled = {
  Container: styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
  `,
  ScrollView: styled.div`
    display: flex;
    flex-direction: row;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    width: 100%;
    height: 100%;
    -webkit-overflow-scrolling: touch;
  `,
  Slide: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 15px;
    width: ${({ width }) => width}px;
    scroll-snap-align: start;
    flex: 0 0 auto; /* Ensure that slides don't shrink */
  `,
  Illustration: styled.img`
    width: 100%;
    margin-bottom: 20px;
    object-fit: contain;
    margin-right: ${({ isFirst, isDany }) =>
      isFirst && isDany ? "-70px" : "0px"};
    height: ${({ isSmallDevice }) => (isSmallDevice ? "200px" : "230px")};
  `,
  IndicatorWrapper: styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
  `,
  IndicatorItem: styled.div`
    width: 9px;
    height: 9px;
    border-radius: 50%;
    background-color: ${({ isActive }) =>
      isActive ? theme.colors.white : "rgba(255, 255, 255, 0.3)"};
    margin-right: ${({ isLast }) => (isLast ? "0px" : "9px")};
  `,
};

export default withTranslation()(Swiper);
