import React from "react";

const ClockSVG = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="26"
    fill="none"
    viewBox="0 0 22 26"
  >
    <path fill="#E6E6E6" d="M11.786 1.021h-1.572V3.38h1.572V1.02z" />
    <path
      fill="#CCC"
      d="M12.336 0H9.665c-.55 0-1.022.445-1.022.995s.445.996.995.996h2.698c.55 0 .995-.446.995-.996.026-.55-.445-.995-.995-.995z"
    />
    <path
      fill="#E6E6E6"
      d="M2.592 4.822l-.87.87 1.314 1.315.87-.87-1.314-1.315z"
    />
    <path
      fill="#CCC"
      d="M2.331 3.929L.838 5.42c-.314.315-.314.812 0 1.1.315.315.812.315 1.1 0L3.431 5.03c.314-.315.314-.812 0-1.1-.288-.288-.786-.288-1.1 0z"
    />
    <path
      fill="#E6E6E6"
      d="M19.352 4.82l-1.315 1.315.87.87 1.315-1.314-.87-.87z"
    />
    <path
      fill="#CCC"
      d="M19.616 3.928l1.493 1.493c.315.315.315.812 0 1.1-.314.315-.812.315-1.1 0l-1.492-1.493c-.315-.314-.315-.811 0-1.1a.808.808 0 011.1 0z"
    />
    <path
      fill="#CCC"
      d="M11 25.09c6.075 0 11-4.924 11-11 0-6.075-4.925-11-11-11s-11 4.925-11 11c0 6.076 4.925 11 11 11z"
    />
    <path
      fill="#E6E6E6"
      d="M11 23.257a9.167 9.167 0 100-18.333 9.167 9.167 0 000 18.333z"
    />
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      strokeWidth="1.048"
      d="M8.826 21.372A7.445 7.445 0 014.4 17.6M16.919 9.717c.314.393.576.812.786 1.257"
      opacity="0.5"
    />
    <path fill="#666" d="M11.157 15.321a.89.89 0 100-1.78.89.89 0 000 1.78z" />
    <path fill="#666" d="M10.712 14.222l.445-5.317.472 5.317h-.917z" />
    <path fill="#666" d="M11.315 14.117l1.964-1.44-1.703 1.754-.261-.314z" />
  </svg>
);

export default ClockSVG;
