import { useStoreState } from "easy-peasy";
import React from "react";
import { withTranslation } from "react-i18next";
import styled from "styled-components";
import { Avatar, Text } from "../../atoms";
import { Title } from "../../molecules";
import { theme } from "../../../styles";

const HomeHeader = ({ t, width }) => {
  const isDany = useStoreState((state) => state.isDany);
  const isSmall = width < 400;

  return (
    <StyledHeader isdany={isDany ? true : false}>
      {isDany && (
        <div>
          <Avatar
            isLocal
            size={isSmall ? 90 : 128}
            src={require("../../../assets/images/avatar_dany.png")}
          />
        </div>
      )}
      <div style={{ flex: 1, paddingLeft: isDany ? 15 : 0 }}>
        {isDany && (
          <Title
            color={theme.colors.black}
            style={{ marginBottom: 5 }}
            fontSize={isSmall ? 18 : 22}
          >
            {t("home_title")}
          </Title>
        )}
        <Text
          fontSize={isSmall ? 12 : 16}
          textAlign={isDany ? "left" : "center"}
          textTransform={isDany ? "none" : "uppercase"}
          fontFamily={isDany ? theme.fonts.bold : theme.fonts.blackItalic}
          fontWeight={700}
        >
          {t(isDany ? "home_dany_description" : "home_description")}
        </Text>
      </div>
    </StyledHeader>
  );
};

const StyledHeader = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  border-bottom-left-radius: 50px;
  border-bottom-right-radius: 50px;
  background-color: ${(props) => props.theme.colors.white};
  padding: ${({ isdany }) => (isdany ? "25px" : "15px 40px 25px 40px")};
`;

export default withTranslation()(HomeHeader);
