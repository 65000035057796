import React from "react";
import styled from "styled-components";
import FeatherIcon from "feather-icons-react";
import { BottomButton } from "../../molecules";
import { theme } from "../../../styles";

const CustomModal = ({
  visible,
  onClose,
  children,
  backgroundColor,
  footerConfig,
  noPadding,
  isBottomButton,
}) => (
  <div style={{ display: visible ? "block" : "none" }}>
    <Styled.Container
      backgroundColor={backgroundColor?.main || theme.colors.blue}
    >
      <div>
        <Styled.Content noPadding={noPadding} isBottomButton={isBottomButton}>
          <Styled.Close onClick={onClose}>
            <FeatherIcon icon="x" size={30} color={theme.colors.white} />
          </Styled.Close>
          {children}
        </Styled.Content>
      </div>

      {footerConfig && (
        <BottomButton onClick={footerConfig?.action}>
          {footerConfig?.title}
        </BottomButton>
      )}
    </Styled.Container>

    {(backgroundColor?.bottom || footerConfig) && (
      <div
        style={{
          backgroundColor: footerConfig
            ? theme.colors.yellow
            : backgroundColor.bottom,
        }}
      />
    )}
  </div>
);

const Styled = {
  Container: styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${(props) => props.backgroundColor};
    padding-top: ${({ isAndroid }) => (isAndroid ? "20px " : "0px")};
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    z-index: 999999;
  `,
  Content: styled.div`
    flex: 1;
    padding: ${({ noPadding }) => (noPadding ? "0px" : "20px")};
    position: relative;
    align-items: center;
    justify-content: center;
    overflow-y: auto;
    height: ${({ isBottomButton }) =>
      isBottomButton ? "calc(100% - 50px)" : "100%"};
  `,
  Close: styled.button`
    position: absolute;
    z-index: 999999;
    right: 20px;
    top: 10px;
    background: none;
    border: none;
    cursor: pointer;
  `,
};

export default CustomModal;
