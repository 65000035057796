import _ from "lodash";
import React from "react";
import styled from "styled-components";
import { Title } from "../../molecules";
import { ClockSVG } from "../../svg";
import { theme } from "../../../styles";

function onChrono(timestamp) {
  if (timestamp) {
    const minutes = Math.floor(timestamp / 60);
    const seconds = timestamp - minutes * 60;
    return `${minutes.toString().padStart(2, "0")}:${seconds
      .toString()
      .padStart(2, "0")}`;
  }
  return null;
}

const Countdown = ({ time, style }) => (
  <StyledTime style={style}>
    <ClockSVG />
    <Title
      fontSize={24}
      style={{ marginLeft: 8 }}
      color={
        time < 30 || time === "00:00" ? theme.colors.red : theme.colors.blue
      }
    >
      {time ? (_.isNumber(time) ? onChrono(time) : time) : "00:00"}
    </Title>
  </StyledTime>
);

const StyledTime = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export default Countdown;
