// GlobalStyles.js
import { createGlobalStyle } from 'styled-components';
import '@fontsource/montserrat/300.css'; // Light
import '@fontsource/montserrat/300-italic.css'; // Light
import '@fontsource/montserrat/400.css'; // Regular
import '@fontsource/montserrat/400-italic.css'; // Regular
import '@fontsource/montserrat/500.css'; // Medium
import '@fontsource/montserrat/600.css'; // SemiBold
import '@fontsource/montserrat/700.css'; // Bold
import '@fontsource/montserrat/800.css'; // ExtraBold
import '@fontsource/montserrat/900-italic.css'; // Black Italic

const GlobalStyles = createGlobalStyle`
  body {
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: 900;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    background-color: ${({ theme }) => theme.colors.background};
    color: ${({ theme }) => theme.colors.text};
  }

  * {
    box-sizing: inherit;
  }

  h1, h2, h3, h4, h5, h6 {
    margin: 0;
    font-family: ${({ theme }) => theme.fonts.bold};
    font-weight: 700;
  }

  p {
    margin: 0;
  }
`;

export default GlobalStyles;
