import React, { useState } from "react";
import styled from "styled-components";
import { Text } from "../atoms";
import { theme } from "../../styles";
import FeatherIcon from "feather-icons-react";
import { useTranslation } from "react-i18next";

const Input = ({
  error,
  width,
  value,
  label,
  style,
  isDark,
  advice,
  isError,
  fontSize,
  hideError,
  isTextarea,
  adviceColor,
  placeholder,
  onChangeText,
  backgroundColor,
  secureTextEntry,
  autoCompleteType,
  placeholderTextColor,
  autoCapitalize = "none",
}) => {
  const [passwordHidden, setPasswordHidden] = useState(
    secureTextEntry || false
  );

  const hasError = !!error || isError;

  const { t } = useTranslation();

  return (
    <Wrapper style={style}>
      {label && (
        <LabelText fontFamily={theme.fonts.semiBold} fontWeight={600}>
          {label}
        </LabelText>
      )}

      {advice && (
        <AdviceText
          fontSize={12}
          color={adviceColor}
          fontFamily={theme.fonts.light}
          fontWeight={300}
        >
          {advice}
        </AdviceText>
      )}

      <InputWrapper>
        <InputField
          value={value}
          onChange={onChangeText}
          width={width}
          isDark={isDark}
          isError={hasError}
          placeholder={t(placeholder)}
          autoComplete={autoCompleteType}
          isTextarea={isTextarea}
          fontSize={fontSize || 14}
          autoCapitalize={autoCapitalize}
          placeholderTextColor={placeholderTextColor || theme.colors.grey}
          backgroundColor={
            backgroundColor ||
            (isDark ? theme.colors.lightgrey : theme.colors.white)
          }
          type={secureTextEntry && passwordHidden ? "password" : "text"}
        />

        {secureTextEntry && (
          <TogglePasswordButton
            onClick={() => setPasswordHidden(!passwordHidden)}
          >
            <FeatherIcon
              size={20}
              color="rgba(0,0,0,0.3)"
              icon={passwordHidden ? "eye-off" : "eye"}
            />
          </TogglePasswordButton>
        )}
      </InputWrapper>

      {error && !hideError && <ErrorText>{`Erreur : ${t(error)}`}</ErrorText>}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const InputWrapper = styled.div`
  position: relative;
`;

const InputField = styled.input`
  padding: 18px 0px;
  border-radius: 15px;
  color: ${(props) => props.color};
  font-family: ${(props) => props.fontFamily};
  font-size: ${({ fontSize }) => `${fontSize}px`};
  border: ${({ isError }) =>
    isError ? "1px solid " + theme.colors.red : "none"};
  background-color: ${(props) => props.backgroundColor};
  width: ${({ width }) => (width ? `${width}px` : "100%")};
  min-height: ${({ isTextarea }) => (isTextarea ? "280px" : "45px")};
`;

const LabelText = styled(Text)`
  margin-right: auto;
  font-weight: ${({ fontWeight }) => fontWeight};
`;

const AdviceText = styled(Text)`
  margin-bottom: 10px;
  margin-right: auto;
  width: 70%;
`;

const TogglePasswordButton = styled.button`
  position: absolute;
  right: 20px;
  z-index: 10;
  background: none;
  border: none;
  cursor: pointer;
`;

const ErrorText = styled(Text)`
  width: calc(100% - 40px);
  margin-top: 10px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  color: ${theme.colors.red};
`;

export default Input;
