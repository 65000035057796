/* eslint-disable react/jsx-props-no-spreading */
import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { useEffect, useState, useRef, useCallback } from "react";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import styled, { withTheme } from "styled-components";
import { Layout, QuizChrono, QuizHeader, Text, Title } from "../components"; // Ensure these components are adapted for web usage
import { useLocation } from "react-router-dom";
import { useStoreState } from "easy-peasy";
import { Oval } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import FeatherIcon from "feather-icons-react";

/*
|--------------------------------------------------------------------------
| QUERIES
|--------------------------------------------------------------------------
*/
const CREATE_SESSION = gql`
  mutation createSession {
    createSession
  }
`;

const GET_TIME = gql`
  query getRemainingTime {
    authenticatedUser {
      id
      remainingTime
    }
  }
`;

const SET_TIME = gql`
  mutation consumeRemainingTime($seconds: Int) {
    consumeRemainingTime(seconds: $seconds)
  }
`;

const GET_QUESTION = gql`
  query nextQuestion($themeLevelId: ID!) {
    nextQuestion(themeLevelId: $themeLevelId) {
      progression {
        questionsCount
        currentQuestion
        percentage
        level
        themeLevelId
      }
      nextQuestion {
        id
        question
        answers {
          id
          answer
        }
        picture {
          thumbnail
        }
      }
    }
  }
`;

const GET_ANSWER = gql`
  mutation answerQuestion(
    $themeLevelId: ID!
    $questionId: ID!
    $answers: [ID!]!
    $secondsSpent: Int!
  ) {
    answerQuestion(
      themeLevelId: $themeLevelId
      questionId: $questionId
      answers: $answers
      secondsSpent: $secondsSpent
    ) {
      selectedAnswers
      correctAnswers
      isCorrect
      progression {
        questionsCount
        currentQuestion
        percentage
        level
        themeLevelId
      }
      answeredQuestion {
        id
        correction
      }
      nextQuestion {
        id
        question
        answers {
          id
          answer
        }
        picture {
          thumbnail
        }
      }
      nextThemeLevel {
        id
        level
      }
      remainingTime
    }
  }
`;

const QuizQuestions = ({ theme }) => {
  const navigate = useNavigate();

  /*
  |--------------------------------------------------------------------------
  | VARIABLES
  |--------------------------------------------------------------------------
  */
  const { t } = useTranslation();
  const isDany = useStoreState((state) => state.isDany);

  const location = useLocation();

  const { currentTheme } = location.state || {};

  const themeLevelId = currentTheme?.progression?.themeLevelId;

  const config = {
    backgroundColor: currentTheme?.backgroundColor || theme.colors.violet,
    color: currentTheme?.color || theme.colors.white,
  };

  /*
  |--------------------------------------------------------------------------
  | STATES
  |--------------------------------------------------------------------------
  */
  const [nextThemeLevel, setNextThemeLevel] = useState(null);
  const [progression, setProgression] = useState(null);
  const [secondsSpent, setSecondsSpent] = useState(0);
  const [questionId, setQuestionId] = useState(null);
  const [correction, setCorrection] = useState(null);
  const [isCorrect, setIsCorrect] = useState(null);
  const [question, setQuestion] = useState(null);
  const [confirm, setConfirm] = useState(false);
  const [picture, setPicture] = useState(null);
  const [answers, setAnswers] = useState(null);
  const [selected, setSelected] = useState([]);
  const [isEnd, setIsEnd] = useState(false);
  const [time, setTime] = useState(null);

  /*
  |--------------------------------------------------------------------------
  | FETCHING
  |--------------------------------------------------------------------------
  */
  const [getTime, { data: timeData, loading: timeLoading }] = useLazyQuery(
    GET_TIME,
    { fetchPolicy: "network-only" }
  );

  const [getQuestion, { data: questionData, loading: questionLoading }] =
    useLazyQuery(GET_QUESTION, {
      fetchPolicy: "network-only",
      variables: { themeLevelId },
      onCompleted(res) {
        console.debug("next question:", res?.nextQuestion?.nextQuestion);
      },
    });

  const [getResult, { data: result, loading: resultLoading }] = useMutation(
    GET_ANSWER,
    {
      onCompleted(res) {
        console.debug(
          "answer question id:",
          res?.answerQuestion?.nextQuestion?.id
        );
        console.debug(
          "answer question:",
          res?.answerQuestion?.nextQuestion?.question
        );
      },
    }
  );

  // eslint-disable-next-line no-unused-vars
  const [setRemainingTime, { data: setTimeData }] = useMutation(SET_TIME);

  // eslint-disable-next-line no-unused-vars
  const [createSession, createSessionResult] = useMutation(CREATE_SESSION);

  /*
  |--------------------------------------------------------------------------
  | VARIABLES
  |--------------------------------------------------------------------------
  */
  const loading = timeLoading || questionLoading;
  const isFinish = time === 0;

  /*
  |--------------------------------------------------------------------------
  | FUNCTIONS
  |--------------------------------------------------------------------------
  */
  const resetState = () => {
    setNextThemeLevel(null);
    setProgression(null);
    setQuestionId(null);
    setCorrection(null);
    setIsCorrect(null);
    setSecondsSpent(0);
    setQuestion(null);
    setConfirm(false);
    setPicture(null);
    setAnswers(null);
    setSelected([]);
    setIsEnd(false);
    setTime(null);
  };

  const useInterval = (callback, delay) => {
    const savedCallback = useRef();

    useEffect(() => {
      savedCallback.current = callback;
    }, [callback]);

    useEffect(() => {
      const id = setInterval(() => {
        savedCallback.current();
      }, delay);
      return () => clearInterval(id);
    }, [delay]);
  };

  const onSetTime = ({ seconds }) =>
    setRemainingTime({ variables: { seconds: seconds || secondsSpent } });

  const onClose = () => {
    if (!confirm) {
      onSetTime({ seconds: secondsSpent });
    }
    resetState();
    navigate("/home", { refresh: true });
  };

  const onSelectQuestion = ({ id }) => {
    const arr = selected.includes(id)
      ? _.filter(selected, (s) => s !== id)
      : [...selected, id];
    setSelected(arr);
  };

  const onGetNextQuestion = ({ nextQuestion }) => {
    setSelected([]);
    setConfirm(false);
    setIsCorrect(null);
    setSecondsSpent(0);
    setCorrection(null);
    setTime(nextQuestion?.remainingTime);
    setProgression(nextQuestion?.progression);
    setQuestionId(nextQuestion?.nextQuestion?.id);
    setPicture(nextQuestion?.nextQuestion?.picture);
    setQuestion(nextQuestion?.nextQuestion?.question);
    setAnswers(_.shuffle(nextQuestion?.nextQuestion?.answers));
  };

  const onConfirm = () => {
    if (isEnd) {
      navigate("/quiz_result", {
        state: {
          nextThemeLevel,
          currentTheme,
          progression,
        },
      });
      resetState();
    } else if (isCorrect !== null) {
      onGetNextQuestion({ nextQuestion: result?.answerQuestion });
    } else {
      setConfirm(true);
      getResult({
        variables: {
          questionId,
          secondsSpent,
          answers: selected,
          themeLevelId: questionData?.nextQuestion?.progression?.themeLevelId,
        },
      });
    }
  };

  const checkIfIsCorrect = ({ id }) => {
    const correctAnswers = result?.answerQuestion?.correctAnswers || [];

    if (correctAnswers.includes(id)) {
      if (selected.includes(id)) return "correct_selected";
      return "correct";
    }

    if (selected.includes(id)) {
      return "wrong_selected";
    }

    return null;
  };

  /*
  |--------------------------------------------------------------------------
  | CYCLE LIFE
  |--------------------------------------------------------------------------
  */
  useEffect(() => {
    getTime();
    getQuestion();
  }, []);

  useEffect(() => {
    if (questionData?.nextQuestion?.nextQuestion?.question) {
      const { nextQuestion } = questionData.nextQuestion;
      setProgression(questionData?.nextQuestion?.progression);
      setQuestion(nextQuestion?.question);
      setAnswers(nextQuestion?.answers);
      setPicture(nextQuestion?.picture);
      setQuestionId(nextQuestion?.id);
      createSession();
    }
  }, [questionData]);

  useEffect(() => {
    if (timeData?.authenticatedUser?.remainingTime !== null) {
      setTime(timeData?.authenticatedUser?.remainingTime);
    }
  }, [timeData]);

  useEffect(() => {
    if (result?.answerQuestion) {
      if (result?.answerQuestion?.nextQuestion === null) {
        setIsEnd(true);
        if (result?.answerQuestion?.nextThemeLevel) {
          setNextThemeLevel(result.answerQuestion.nextThemeLevel);
        }
      }
      setIsCorrect(result?.answerQuestion?.isCorrect);
      setCorrection(result?.answerQuestion?.answeredQuestion?.correction);
    }
  }, [result]);

  useEffect(() => {
    if (questionData?.nextQuestion?.nextQuestion?.question && isFinish) {
      onSetTime({ seconds: 0 });
      navigate("/transition", {
        state: {
          route: "home",
          title: `BRAVO !\nc’est terminé pour aujourd’hui !`,
          description: "Vous avez rempli vos 2 minutes de quiz de la journée !",
          image: isDany
            ? require("../assets/images/success_time_dany.png")
            : require("../assets/images/clock.png"),
          // footerConfig: {
          //   title: "Consulter votre récap du jour",
          //   action: () => navigate("/recap", { refresh: true }),
          // },
        },
      });
    }
  }, [isFinish]);

  useInterval(() => {
    if (
      questionData?.nextQuestion?.nextQuestion?.question &&
      !confirm &&
      !isEnd
    ) {
      setSecondsSpent(secondsSpent + 1);
      setTime(time - 1);
    }
  }, 1000);

  /*
  |--------------------------------------------------------------------------
  | RENDER
  |--------------------------------------------------------------------------
  */
  if (loading) {
    return (
      <Layout>
        <LoadingContainer>
          <Oval height={80} width={80} color={theme.colors.violet} />
        </LoadingContainer>
      </Layout>
    );
  }

  return (
    <Layout
      onTime={() => onSetTime({ seconds: 0 })}
      backgroundColor={{ top: config.backgroundColor }}
      onClose={{ action: onClose, color: config.color }}
      footerConfig={{
        isDisabled: _.isEmpty(selected) || loading || resultLoading,
        loading: resultLoading,
        action: onConfirm,
        title: t(
          isEnd
            ? "quiz_access_result"
            : resultLoading
            ? "Chargement"
            : `quiz_question_button_${confirm}`
        ),
      }}
      header={
        <QuizHeader
          {...progression}
          loading={loading}
          colorConfig={config}
          currentTheme={currentTheme}
        />
      }
      noSafe={true}
    >
      <Content>
        <QuizChrono isCorrect={isCorrect} time={time > 0 ? time : "00:00"} />
        {!!question && (
          <Title
            style={{ margin: "15px 0" }}
            color={theme.colors.black}
            textAlign="center"
            fontSize={16}
          >
            {question}
          </Title>
        )}
        {picture?.thumbnail && (
          <ImageContainer>
            <StyledImage src={picture?.thumbnail} alt="Question" />
          </ImageContainer>
        )}

        <Answers>
          {!_.isEmpty(answers) &&
            answers.map((answer) => (
              <Answer
                {...answer}
                theme={theme}
                id={answer?.id}
                key={answer?.id}
                isCorrect={isCorrect}
                disabled={resultLoading || confirm}
                isSelected={selected.includes(answer?.id)}
                status={checkIfIsCorrect({ id: answer?.id })}
                onClick={() => onSelectQuestion({ id: answer?.id })}
                color={config.color}
              />
            ))}
        </Answers>
        {correction && (
          <CorrectionContainer>
            <Text style={{ color: config.color }}>{correction}</Text>
          </CorrectionContainer>
        )}
      </Content>
    </Layout>
  );
};

export default withTheme(QuizQuestions);

/*
|--------------------------------------------------------------------------
| COMPONENTS
|--------------------------------------------------------------------------
*/

const Answer = ({
  theme,
  answer,
  status,
  onClick,
  disabled,
  isCorrect,
  isSelected,
}) => {
  const config = {
    icon:
      isCorrect !== null
        ? status === "correct_selected"
          ? "check"
          : status === "wrong_selected"
          ? "x"
          : null
        : null,

    font: isSelected ? theme.fonts.bold : theme.fonts.regular,
    weight: isSelected ? 700 : 400,
    opacity: isCorrect !== null && !isSelected ? 0.7 : 1,

    color:
      isCorrect !== null
        ? isSelected
          ? status === "correct_selected"
            ? theme.colors.black
            : theme.colors.white
          : theme.colors.black
        : isSelected
        ? theme.colors.white
        : theme.colors.black,

    backgroundColor:
      isCorrect !== null
        ? isSelected
          ? status === "correct_selected"
            ? theme.colors.green
            : theme.colors.red
          : status === "correct"
          ? theme.colors.green
          : "#F8F8F8"
        : isSelected
        ? theme.colors.violet
        : "#F8F8F8",
  };

  return (
    <S.Answer
      disabled={disabled}
      onClick={disabled ? null : onClick}
      backgroundColor={config.backgroundColor}
    >
      <Text
        textAlign="center"
        color={config.color}
        fontFamily={config.font}
        fontWeight={config.weight}
        style={{ flex: 1, opacity: config.opacity }}
      >
        {answer}
      </Text>

      {config?.icon && (
        <FeatherIcon
          icon={config.icon}
          size={30}
          color={config.color}
          style={{ opacity: config.opacity }}
        />
      )}
    </S.Answer>
  );
};

/*
|--------------------------------------------------------------------------
| STYLES
|--------------------------------------------------------------------------
*/

const S = {
  Illustration: {
    Wrapper: styled.div`
      height: 100px;
      width: 100px;
      border-radius: 25px;
      margin-bottom: 10px;
    `,
    Item: styled.image`
      height: 100%;
      width: 100%;
      resize-mode: contain;
    `,
  },

  Answer: styled.div`
    opacity: ${({ disabled }) => (disabled ? "0.8" : "1")};
    background-color: ${(props) => props.backgroundColor};
    justify-content: center;
    flex-direction: row;
    align-items: center;
    border-radius: 15px;
    margin-bottom: 15px;
    padding: 20px 14px;
    display: flex;
  `,
};

const LoadingContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0px 20px;
  margin-bottom: 85px;
`;

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 10px;
`;

const StyledImage = styled.img`
  width: 100%;
  height: 100px;
  object-fit: contain;
`;

const Answers = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 10px;
`;

// const Answer = styled.div`
//   padding: 20px 10px;
//   background-color: ${({ theme, selected }) =>
//     selected ? theme.colors.lightViolet : theme.colors.lightGrey};
//   border-radius: 10px;
//   margin-bottom: 10px;
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   border: ${({ selected }) => (selected ? "1px solid" : "none")};
//   border-color: ${({ theme, selected }) =>
//     selected ? theme.colors.violet : "transparent"};
// `;

const Button = styled.div`
  margin-top: 20px;
  padding: 15px;
  background-color: ${({ theme }) => theme.colors.violet};
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CorrectionContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 10px;
`;

const ActivityIndicator = styled.div`
  width: 30px;
  height: 30px;
  border: 4px solid ${({ theme }) => theme.colors.white};
  border-top: 4px solid transparent;
  border-radius: 50%;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
