import { gql, useLazyQuery } from "@apollo/client";
import _ from "lodash";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import {
  CircularProgress,
  List,
  ListItem,
  Button,
  Typography,
  Box,
} from "@mui/material";
import { Layout, QuestionCard } from "../components";
import { theme } from "../styles";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";

/*
|--------------------------------------------------------------------------
| QUERIES
|--------------------------------------------------------------------------
*/
const GET = gql`
  query getThemeLevelAnsweredQuestions(
    $themeLevelId: ID!
    $limit: Int!
    $offset: Int!
  ) {
    getThemeLevelAnsweredQuestions(
      themeLevelId: $themeLevelId
      offset: $offset
      limit: $limit
    ) {
      totalAnswers
      progression {
        questionsCount
        currentQuestion
        percentage
        level
      }
      userAnswers {
        selectedAnswers
        isCorrect
        question {
          id
          question
          correction
          picture {
            thumbnail
          }
        }
      }
      answers {
        id
        questionId
        isCorrect
        answer
      }
    }
  }
`;

const LIMIT = 25;

const QuizRecap = ({ navigation, route }) => {
  /*
  |--------------------------------------------------------------------------
  | ROUTES
  |--------------------------------------------------------------------------
  */
  const navigate = useNavigate();

  const location = useLocation();

  const { currentTheme } = location.state || {};
  const themeLevelId = currentTheme?.progression?.themeLevelId;

  /*
  |--------------------------------------------------------------------------
  | STATES
  |--------------------------------------------------------------------------
  */
  const [offset, setOffset] = React.useState(0);
  const [items, setItems] = React.useState([]);
  const [answers, setAnswers] = React.useState([]);

  /*
  |--------------------------------------------------------------------------
  | GRAPHQL
  |--------------------------------------------------------------------------
  */
  const [getQuestions, { loading, data }] = useLazyQuery(GET, {
    fetchPolicy: "cache-and-network",
    onError(err) {
      console.error(err);
    },
  });

  /*
  |--------------------------------------------------------------------------
  | VARIABLES
  |--------------------------------------------------------------------------
  */
  const { t } = useTranslation();

  const level = React.useMemo(
    () => currentTheme?.progression?.level,
    [currentTheme]
  );

  const description = React.useMemo(
    () => `${currentTheme?.name} - ${t("level", { level })}`,
    [currentTheme, level]
  );

  const steps = React.useMemo(() => {
    const limit = data?.getThemeLevelAnsweredQuestions?.totalAnswers;
    const result = [0, 25, 50, 75].filter((o) => o <= limit);
    return result;
  }, [data?.getThemeLevelAnsweredQuestions?.totalAnswers]);

  const colorConfig = React.useMemo(
    () => ({
      backgroundColor: currentTheme?.backgroundColor || theme.colors.violet,
      color: currentTheme?.color || theme.colors.white,
    }),
    [currentTheme]
  );

  function removeDuplicateObjects(array, property) {
    const uniqueIds = [];
    const unique = array.filter((element) => {
      const isDuplicate = uniqueIds.includes(element[property]);
      if (!isDuplicate) {
        uniqueIds.push(element[property]);
        return true;
      }
      return false;
    });
    return unique;
  }

  /*
  |--------------------------------------------------------------------------
  | CYCLE LIFE
  |--------------------------------------------------------------------------
  */
  React.useEffect(() => {
    getQuestions({ variables: { themeLevelId, offset: 0, limit: LIMIT } });
  }, [themeLevelId, getQuestions]);

  React.useEffect(() => {
    if (data?.getThemeLevelAnsweredQuestions?.userAnswers) {
      setItems(data?.getThemeLevelAnsweredQuestions?.userAnswers);
    }
  }, [data?.getThemeLevelAnsweredQuestions?.userAnswers]);

  React.useEffect(() => {
    if (data?.getThemeLevelAnsweredQuestions?.answers) {
      setAnswers(
        removeDuplicateObjects(
          data?.getThemeLevelAnsweredQuestions?.answers,
          "id"
        )
      );
    }
  }, [data?.getThemeLevelAnsweredQuestions?.answers]);

  /*
  |--------------------------------------------------------------------------
  | FUNCTIONS
  |--------------------------------------------------------------------------
  */
  const getPosition = React.useCallback(
    (index) => {
      const total =
        data?.getThemeLevelAnsweredQuestions?.startProgression
          ?.questionsCount ||
        data?.getThemeLevelAnsweredQuestions?.progression?.questionsCount;
      return `${index + 1} / ${total}`;
    },
    [
      data?.getThemeLevelAnsweredQuestions?.startProgression,
      data?.getThemeLevelAnsweredQuestions?.progression,
    ]
  );

  const isCorrect = React.useCallback(
    (index) => {
      let target;
      if (items && index !== null) {
        target = items[index];
      }
      return target?.isCorrect;
    },
    [items]
  );

  const getMyAnswer = React.useCallback(
    (index) => {
      const arr = [];
      let target;
      if (items && index !== null) {
        target = items[index];
      }
      if (target) {
        const { selectedAnswers, question } = target;
        const questionAnswers = answers.filter(
          (answer) => answer.questionId === question.id
        );
        if (questionAnswers && !_.isEmpty(questionAnswers)) {
          questionAnswers.forEach((item) => {
            if (
              selectedAnswers &&
              !_.isEmpty(selectedAnswers) &&
              selectedAnswers.includes(item.id)
            ) {
              arr.push(item);
            }
          });
        }
      }
      return arr;
    },
    [items, answers]
  );

  const ListEmptyComponent = useCallback(
    () =>
      !loading && (
        <Typography
          variant="body1"
          style={{ padding: "20px", textAlign: "center" }}
        >
          {t(`empty_questions_treated_false`)}
        </Typography>
      ),
    [loading, t]
  );

  /*
  |--------------------------------------------------------------------------
  | MAIN RENDER
  |--------------------------------------------------------------------------
  */
  return (
    <Layout
      noScroll
      isLoading={loading && !data}
      backgroundColor={{ top: colorConfig.backgroundColor }}
      headerConfig={{
        description,
        ...colorConfig,
        title: t(`quiz_recap_title_false`),
        backgroundImage: currentTheme?.cover?.file,
        leftIconConfig: {
          name: "chevron-left",
          action: () => window.history.back(),
        },
        rightIconConfig: {
          name: "x",
          action: () => navigate("/home", { refresh: true }),
        },
      }}
    >
      <Box
        display="flex"
        p={2}
        style={{ marginTop: "140px", flexDirection: "column" }}
      >
        {steps.map((item) => (
          <Button
            key={item}
            style={{ marginRight: "20px", display: "block", width: "100%" }}
            onClick={() => {
              setOffset(item);
              getQuestions({
                variables: { themeLevelId, offset: item, limit: LIMIT },
              });
            }}
          >
            <Typography
              style={{
                fontWeight: offset === item ? "bold" : "normal",
                fontSize: "12px",
                whiteSpace: "nowrap",
              }}
            >
              {`Questions de ${item + 1} à ${item + LIMIT}`}
            </Typography>
          </Button>
        ))}
      </Box>

      {loading && !data ? (
        <CircularProgress
          style={{ marginTop: "20px", color: colorConfig.backgroundColor }}
        />
      ) : (
        <List style={{ width: "100%", overflow: "auto" }}>
          {items.length === 0
            ? ListEmptyComponent()
            : items.map((item, index) => (
                <ListItem key={index}>
                  <QuestionCard
                    {...item}
                    isCorrect={isCorrect(index)}
                    allAnswers={answers}
                    answers={getMyAnswer(index)}
                    position={getPosition(index + offset)}
                  />
                </ListItem>
              ))}
        </List>
      )}
    </Layout>
  );
};

export default QuizRecap;
