import _ from "lodash";
import React from "react";
import { withTranslation } from "react-i18next";
import styled from "styled-components";
import { Text } from "../atoms";
import { theme } from "../../styles";
import { motion } from "framer-motion";

const Filters = ({ t, categories, color, style, setActive, active }) => (
  <StyledScrollView style={{ ...style, maxHeight: 40, width: "100%" }}>
    {categories &&
      !_.isEmpty(categories) &&
      categories.map((category) => (
        <StyledItem
          key={category}
          onClick={() => (setActive ? setActive(category) : null)}
        >
          <Text
            color={color || theme.colors.white}
            fontFamily={
              active === category ? theme.fonts.bold : theme.fonts.regular
            }
            fontWeight={active === category ? 700 : 400}
          >
            {t(category)}
          </Text>

          {active === category && (
            <StyledDivider
              initial={{ width: 0 }}
              animate={{ width: "100%" }}
              transition={{ duration: 0.5, ease: "easeOut" }}
            />
          )}
        </StyledItem>
      ))}
  </StyledScrollView>
);

const StyledScrollView = styled.div`
  display: flex;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
`;

const StyledItem = styled.div`
  margin: 0 15px;
  align-self: center;
  cursor: pointer;
`;

const StyledDivider = styled(motion.div)`
  height: 3px;
  width: 100%;
  margin-top: 5px;
  background-color: ${theme.colors.white};
`;

export default withTranslation()(Filters);
