import React from "react";
import FeatherIcon from "feather-icons-react";
import styled from "styled-components";
import { Text } from "../../atoms";
import { Filters, Title } from "../../molecules";
import { theme } from "../../../styles";

const Header = ({ headerConfig }) => {
  const width = window.innerWidth;
  return (
    <StyledHeader
      width={width}
      style={headerConfig.wrapperStyle}
      backgroundColor={headerConfig?.backgroundColor || theme.colors.blue}
      position={headerConfig?.position || null}
    >
      {headerConfig?.backgroundImage && (
        <StyledIllustration
          src={headerConfig.backgroundImage}
          alt="background"
        />
      )}

      {headerConfig?.rightIconConfig && (
        <StyledButton isRight onClick={headerConfig.rightIconConfig?.action}>
          <FeatherIcon
            size={30}
            icon={headerConfig.rightIconConfig?.name}
            color={headerConfig?.color || theme.colors.white}
          />
        </StyledButton>
      )}

      {headerConfig?.leftIconConfig && (
        <StyledButton
          isRight={false}
          onClick={headerConfig.leftIconConfig?.action}
        >
          <FeatherIcon
            size={30}
            icon={headerConfig.leftIconConfig?.name}
            color={headerConfig?.color || theme.colors.white}
          />
        </StyledButton>
      )}

      <StyledContent style={headerConfig?.style}>
        {headerConfig?.title && (
          <Title
            fontSize={16}
            textAlign="center"
            color={headerConfig?.color || theme.colors.white}
            style={{
              marginBottom:
                headerConfig?.filters || headerConfig?.description ? 20 : 0,
              alignSelf: "center",
              maxWidth: "140px",
              lineHeight: "20px",
              fontWeight: 800,
            }}
          >
            {headerConfig.title}
          </Title>
        )}

        {headerConfig?.description && (
          <Text
            textAlign="center"
            fontFamily={theme.fonts.medium}
            fontWeight={500}
            color={headerConfig?.color || theme.colors.white}
            style={{ alignSelf: "center", flexGrow: 1, paddingHorizontal: 50 }}
          >
            {headerConfig?.description}
          </Text>
        )}

        {headerConfig?.filters && (
          <div style={{ marginBottom: headerConfig?.children ? 20 : 0 }}>
            <Filters
              active={headerConfig?.filters?.active}
              categories={headerConfig?.filters?.menu}
              setActive={headerConfig?.filters?.setActive}
            />
          </div>
        )}

        {headerConfig?.children}
      </StyledContent>
    </StyledHeader>
  );
};

const StyledHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-bottom-left-radius: 50px;
  border-bottom-right-radius: 50px;
  background-color: ${(props) => props.backgroundColor};
  position: ${({ position }) => (position ? position : "fixed")};
  z-index: 9999;
  top: 0;
  width: 100%;
`;

const StyledButton = styled.button`
  top: 20px;
  z-index: 100000;
  position: absolute;
  left: ${({ isRight }) => (isRight ? "auto" : "20px")};
  right: ${({ isRight }) => (!isRight ? "auto" : "20px")};
  background: none;
  border: none;
  cursor: pointer;
`;

const StyledIllustration = styled.img`
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  height: 100%;
  opacity: 0.8;
  position: absolute;
  object-fit: cover;
`;

const StyledContent = styled.div`
  z-index: 100;
  padding: 30px 0;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
`;

export default Header;
