import React from "react";
import { withTranslation } from "react-i18next";
import styled from "styled-components";
import { Avatar, Text } from "../../atoms";
import { Title } from "../../molecules";
import { theme } from "../../../styles";
import { FilterButton } from "../common";

const Profile = ({ isTeam, onFilter, connectedUser, t, buttonLabel }) => (
  <S.Wrapper>
    <FilterButton
      style={{ width: 250, justifyContent: "flex-start" }}
      label={t("profile_period")}
      value={buttonLabel}
      onClick={onFilter}
    />

    {!isTeam && (
      <Avatar
        isLocal
        size={67}
        style={{ marginBottom: 15, marginTop: 20 }}
        src={
          connectedUser?.picture?.thumbnail
            ? connectedUser.picture.thumbnail
            : require("../../../assets/images/default_avatar.png")
        }
      />
    )}

    <Title fontSize={20} style={{ marginTop: isTeam ? 15 : 0 }}>
      {isTeam
        ? t("team_name", { name: connectedUser?.team?.name })
        : connectedUser?.name}
    </Title>

    <Text
      style={{ marginTop: 5, marginBottom: 30 }}
      color={theme.colors.white}
      fontSize={12}
    >
      {isTeam
        ? t("members_count", {
            count: connectedUser?.team?.users?.length,
          })
        : t("team_name", { name: connectedUser?.team?.name })}
    </Text>
  </S.Wrapper>
);

const S = {
  Wrapper: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${theme.colors.blue};
    flex-direction: column;
  `,
};

export default withTranslation()(Profile);
