import { gql, useQuery } from "@apollo/client";
import _ from "lodash";
import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import { TailSpin } from "react-loader-spinner";
import ReactMarkdown from "react-markdown";
import styled from "styled-components";
import { Layout, Text } from "../components";
import { theme } from "../styles";

/*
|--------------------------------------------------------------------------
| VARIABLES
|--------------------------------------------------------------------------
*/
const menu = ["cgu_title", "privacy_title"];

/*
|--------------------------------------------------------------------------
| QUERIES
|--------------------------------------------------------------------------
*/
const GET_PAGES = gql`
  query allPages {
    allPages {
      id
      title
      description
      sections {
        id
        title
        content
        sections {
          id
          title
          content
        }
      }
    }
  }
`;

const GeneralCondition = ({ navigation }) => {
  /*
  |--------------------------------------------------------------------------
  | STATES
  |--------------------------------------------------------------------------
  */
  const [active, setActive] = useState(menu[0]);

  /*
  |--------------------------------------------------------------------------
  | VARIABLES
  |--------------------------------------------------------------------------
  */
  const position = active === menu[0] ? 0 : 1;

  /*
  |--------------------------------------------------------------------------
  | FETCHING
  |--------------------------------------------------------------------------
  */
  const { data, loading } = useQuery(GET_PAGES);

  const currentData = data?.allPages?.[position];

  /*
  |--------------------------------------------------------------------------
  | MAIN RENDER
  |--------------------------------------------------------------------------
  */
  return (
    <Layout
      noScroll
      onClose={{ action: () => navigation.goBack() }}
      headerConfig={{
        title: currentData?.title,
        filters: { active, menu, setActive },
      }}
    >
      {loading ? (
        <div
          style={{ marginTop: 20, display: "flex", justifyContent: "center" }}
        >
          <TailSpin color={theme.colors.blue} height={80} width={80} />
        </div>
      ) : (
        <ScrollContainer>
          {currentData?.description && (
            <StyledMarkdown>
              <ReactMarkdown>{currentData.description}</ReactMarkdown>
            </StyledMarkdown>
          )}

          {currentData?.sections &&
            !_.isEmpty(currentData?.sections) &&
            currentData.sections.map((section, index) => (
              <Section
                key={index}
                isLast={index === currentData.sections.length - 1}
              >
                {section?.title && (
                  <Text
                    fontSize={16}
                    fontFamily={theme.fonts.bold}
                    fontWeight={700}
                    style={{ marginBottom: 5 }}
                  >
                    {section.title}
                  </Text>
                )}

                {section?.content && (
                  <div
                    style={{
                      marginBottom:
                        section?.sections && !_.isEmpty(section.sections)
                          ? 20
                          : 0,
                    }}
                  >
                    <StyledMarkdown>
                      <ReactMarkdown>{section.content}</ReactMarkdown>
                    </StyledMarkdown>
                  </div>
                )}

                {section?.sections &&
                  !_.isEmpty(section.sections) &&
                  section.sections.map((subSection, subIndex) => (
                    <SubSection
                      key={subIndex}
                      isLast={subIndex === section.sections.length - 1}
                    >
                      {subSection?.title && (
                        <Text
                          fontSize={14}
                          fontFamily={theme.fonts.bold}
                          fontWeight={700}
                          style={{ marginBottom: 5 }}
                        >
                          {`${index + 1}. ${subSection.title}`}
                        </Text>
                      )}
                      {subSection?.content && (
                        <StyledMarkdown>
                          <ReactMarkdown>{subSection.content}</ReactMarkdown>
                        </StyledMarkdown>
                      )}
                    </SubSection>
                  ))}
              </Section>
            ))}
        </ScrollContainer>
      )}
    </Layout>
  );
};

const ScrollContainer = styled.div`
  padding: 20px;
  max-height: 80vh;
  overflow-y: auto;
`;

const Section = styled.div`
  padding-left: ${({ isSub }) => (isSub ? "20px" : "0px")};
  margin-bottom: ${({ isLast }) => (isLast ? "0px" : "30px")};
`;

const SubSection = styled(Section)`
  padding-left: 20px;
`;

const StyledMarkdown = styled.div`
  p {
    text-align: justify;
  }
`;

export default withTranslation()(GeneralCondition);
