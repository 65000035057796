import { useStoreActions, useStoreState } from "easy-peasy";
import _ from "lodash";
import React, { useState, useEffect } from "react";
import { withTranslation } from "react-i18next";
import styled from "styled-components";
import { Text } from "../../atoms";
// import { storage } from "_services";
import { theme } from "../../../styles";
import FeatherIcon from "feather-icons-react";
import { useNavigate } from "react-router-dom";

/*
|--------------------------------------------------------------------------
| VARIABLES
|--------------------------------------------------------------------------
*/
const routes = [
  { name: "menu_home", path: "/home", icon: "book" },
  { name: "menu_missions", path: "/missions", icon: "flag" },
  { name: "menu_ranking", path: "/ranking", icon: "trending-up" },
  { name: "menu_profile", path: "/profile", icon: "user" },
];

const Menu = ({ t, navigation }) => {
  const navigate = useNavigate();

  /*
  |--------------------------------------------------------------------------
  | STATES
  |--------------------------------------------------------------------------
  */
  const [currentRoute, setCurrentRoute] = useState("");
  const [width, setWidth] = useState(window.innerWidth);
  const [badges, setBadges] = useState([]);
  const [countConnection, setCountConnection] = useState(0);
  const [isRate, setIsRate] = useState(false);
  const [rateAppIsOpen, setRateAppIsOpen] = useState(false);

  /*
  |--------------------------------------------------------------------------
  | FUNCTIONS
  |--------------------------------------------------------------------------
  */
  const getColor = ({ path }) =>
    path === currentRoute ? theme.colors.blue : theme.colors.grey;

  const badgeIsVisible = ({ path }) => {
    if (!_.isEmpty(badges)) {
      if (path === "missions" && badges.includes("badges")) return true;
      if (path === "ranking" && badges.includes("ranks")) return true;
    }
    return false;
  };

  const menuAction = ({ path }) => {
    navigate(path);

    if (path === "home") {
      if (countConnection < 10 && !isRate) {
        setCountConnection(countConnection + 1);
        // storage.set({
        //   key: "@count",
        //   isObject: true,
        //   value: countConnection + 1,
        // });
      }
    }

    if (path === "missions") {
      if (!_.isEmpty(badges)) {
        const value = _.filter(badges, (b) => b !== "badges");
        // storage.set({
        //   isObject: true,
        //   key: "@badges",
        //   value,
        // });
        setBadges(value);
      }
    }

    if (path === "ranking") {
      if (!_.isEmpty(badges)) {
        const value = _.filter(badges, (b) => b !== "ranks");
        // storage.set({
        //   isObject: true,
        //   key: "@ranks",
        //   value,
        // });
        setBadges(value);
      }
    }
  };

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (countConnection >= 10 && !isRate) {
      setRateAppIsOpen(true);
      setCountConnection(0);
      // storage.set({
      //   value: 0,
      //   key: "@count",
      //   isObject: true,
      // });
    }
  }, [countConnection, isRate]);

  useEffect(() => {
    const currentRoutePath = window.location.pathname;
    setCurrentRoute(currentRoutePath);
  }, [navigation]);

  /*
  |--------------------------------------------------------------------------
  | MAIN RENDER
  |--------------------------------------------------------------------------
  */
  return (
    <>
      <S.Menu.Wrapper>
        {routes.map(({ path, name, icon }) => (
          <S.Menu.Item
            isactive={path === currentRoute ? true : false}
            onClick={() => menuAction({ path })}
            width={`${width / routes.length}px`}
            key={path}
          >
            {badgeIsVisible({ path }) && <S.Badges />}

            <FeatherIcon
              style={{ marginBottom: "6px" }}
              color={getColor({ path })}
              icon={icon}
              size={20}
            />
            <Text
              color={getColor({ path })}
              fontSize="12px"
              fontFamily={
                path === currentRoute ? theme.fonts.bold : theme.fonts.regular
              }
              fontWeight={path === currentRoute ? 700 : 400}
            >
              {t(name)}
            </Text>
          </S.Menu.Item>
        ))}
      </S.Menu.Wrapper>
    </>
  );
};

const S = {
  Menu: {
    Wrapper: styled.div`
      background-color: ${theme.colors.lightgrey};
      box-shadow: 0px -6px 50px rgba(0, 0, 0, 0.13);
      display: flex;
      flex-direction: row;
      align-items: center;
      position: fixed;
      elevation: 20;
      height: 60px;
      bottom: 0px;
      left: 0px;
      width: 100%;
      z-index: 9999;
    `,
    Item: styled.button`
      background-color: ${({ isactive }) =>
        isactive ? theme.colors.white : theme.colors.lightgrey};
      width: ${({ width }) => width};
      justify-content: center;
      align-items: center;
      position: relative;
      height: 100%;
      border: none;
      cursor: pointer;
      display: flex;
      flex-direction: column;
    `,
  },
  Badges: styled.div`
    background-color: ${theme.colors.blue};
    border-radius: 20px;
    position: absolute;
    height: 10px;
    width: 10px;
    right: 25px;
    top: 10px;
  `,
};

export default withTranslation()(Menu);
