import _ from "lodash";
import React, { useState, useEffect } from "react";
import { withTranslation } from "react-i18next";
import styled from "styled-components";
import FeatherIcon from "feather-icons-react";
import { Text } from "./../../atoms";
import { Tags } from "./../../molecules";
import { theme } from "../../../styles";
import { common, range } from "../../../utils";

const tags = ["week", "year", "month"];
const colorConfig = {
  active: { color: theme.colors.white, backgroundColor: theme.colors.blue },
  inactive: {
    color: theme.colors.grey,
    backgroundColor: theme.colors.lightgrey,
  },
};

const now = new Date();
const currentYear = now.getFullYear();
const years = range(currentYear, currentYear - 10, -1).map((item) => item);

const getDaysInMonth = (m, y) => new Date(y, m + 1, 0).getDate();

const Calendar = ({ t, filters, settings, isRanking, setSettings }) => {
  const width = window.innerWidth;
  const categories = isRanking
    ? _.filter(tags, (o) => o !== "week")
    : _.filter(tags, (o) => o !== "year");
  const length = settings?.length || filters?.length;

  const [monthDays, setMonthDays] = useState([]);
  const [positions, setPositions] = useState({ start: null, end: null });
  const { start, end } = positions;

  const formattedMonth =
    _.size(_.toString(settings.month + 1)) > 1
      ? settings.month + 1
      : `0${settings.month + 1}`;

  function getDays() {
    const { month, year } = settings;
    const result = getDaysInMonth(month, year);
    if (result > 0) return Array.from(Array(result), (__, i) => i + 1);
    return [];
  }

  function onSelectYear({ year }) {
    const { day } = settings;
    const date = `${year}/01/${day}`;
    setSettings({ ...settings, year, date, length: "year" });
  }

  function onSelectMonth({ index }) {
    const { year, day } = settings;
    const formattedIndex =
      _.size(_.toString(index + 1)) > 1 ? index + 1 : `0${index + 1}`;

    const date = `${year}/${formattedIndex}/${day}`;
    setSettings({ ...settings, month: index, date, length: "month" });
  }

  function onChangeYearMonth({ year }) {
    const date = `${year}/${formattedMonth}/${settings.day}`;
    setSettings({ ...settings, date, length: "month", year });
  }

  function onSelectWeek({ day }) {
    const newDay = _.size(_.toString(day)) > 1 ? _.toString(day) : `0${day}`;
    const selectedDate = `${settings.year}/${formattedMonth}/${newDay}`;

    setSettings({
      ...settings,
      date: selectedDate,
      length: "week",
      day: newDay,
    });
  }

  useEffect(() => {
    if (settings?.year && settings?.month && settings?.day && !isRanking) {
      const date = `${settings.year}/${settings.month + 1}/${settings.day}`;
      const selectedDate = new Date(date);
      const position = selectedDate.getDay();

      if (position === 0) {
        setPositions({
          start: _.toNumber(settings.day) - 6,
          end: _.toNumber(settings.day),
        });
      }

      if (position > 0) {
        setPositions({
          start: _.toNumber(settings.day) - position + 1,
          end: _.toNumber(settings.day) + (7 - position),
        });
      }
    }
  }, [settings.day]);

  useEffect(() => {
    setMonthDays(getDays());
  }, [settings.month, settings.year]);

  return (
    <Container width={width - 40}>
      <Mode>
        {categories.map((tag) => (
          <Tags
            onClick={() => setSettings({ ...settings, length: tag })}
            isActive={tag === length}
            value={t(tag)}
            key={tag}
          />
        ))}
      </Mode>

      <CalendarContainer>
        {length === "month" && (
          <>
            <Header>
              <Arrow
                isPrev
                disabled={settings.year === currentYear - 10}
                onChange={() => onChangeYearMonth({ year: settings.year - 1 })}
              />
              <Text
                fontSize={20}
                color={theme.colors.black}
                style={{ marginHorizontal: 10 }}
                fontFamily={theme.fonts.blackItalic}
                fontWeight={900}
                fontStyle={"italic"}
              >
                {settings.year}
              </Text>
              <Arrow
                isPrev={false}
                disabled={settings.year === currentYear}
                onChange={() => onChangeYearMonth({ year: settings.year + 1 })}
              />
            </Header>
            <Divider />
          </>
        )}

        {length === "year" && (
          <TagsContainer>
            {years.map((year) => (
              <Tags
                onClick={() => onSelectYear({ year })}
                isActive={settings.year === year}
                style={{ marginBottom: 10 }}
                colorConfig={colorConfig}
                value={year}
                key={year}
              />
            ))}
          </TagsContainer>
        )}

        {length === "month" && (
          <TagsContainer>
            {common.months.map((month, index) => (
              <Tags
                onClick={() => onSelectMonth({ index })}
                isActive={settings.month === index}
                style={{ marginBottom: 10 }}
                colorConfig={colorConfig}
                value={month}
                key={month}
              />
            ))}
          </TagsContainer>
        )}

        {length === "week" && !_.isEmpty(monthDays) && (
          <TagsContainer>
            {monthDays.map((day) => (
              <Tags
                isActive={day >= start && day <= end}
                onClick={() => onSelectWeek({ day })}
                style={{ marginBottom: 10 }}
                colorConfig={colorConfig}
                value={day}
                key={day}
              />
            ))}
          </TagsContainer>
        )}
      </CalendarContainer>
    </Container>
  );
};

const Arrow = ({ disabled, onChange, isPrev }) => (
  <ArrowButton disabled={disabled} onClick={() => !disabled && onChange()}>
    <FeatherIcon
      icon={isPrev ? "chevron-left" : "chevron-right"}
      size={20}
      color={disabled ? "rgba(0,0,0,0.5)" : "black"}
    />
  </ArrowButton>
);

const Container = styled.div`
  width: ${(props) => props.width}px;
`;

const Mode = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
`;

const CalendarContainer = styled.div`
  padding: 20px;
  min-height: 250px;
  border-radius: 15px;
  background-color: ${theme.colors.white};
  margin-bottom: 30px;
`;

const Divider = styled.div`
  width: 100%;
  height: 2px;
  margin: 15px 0;
  background-color: ${theme.colors.lightgrey};
`;

const TagsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: auto;
`;

const ArrowButton = styled.button`
  background: none;
  border: none;
`;

export default withTranslation()(Calendar);
