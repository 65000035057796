import React from "react";
import styled from "styled-components";
import { Text } from "../../atoms";
import { theme } from "../../../styles";
import { common } from "./../../../utils";

const Medals = ({ medal, count }) => {
  const width = window.innerWidth;

  return (
    <StyledMedalsItem width={width / common.medals.length - 10}>
      <StyledMedalsIllustration
        width={width / 4}
        src={theme.icons.medals[medal]} // Changed to `src` for web
        alt={medal} // Added alt attribute for accessibility
      />
      <Text
        fontSize={18}
        color={theme.colors.grey}
        fontFamily={theme.fonts.semiBold}
        fontWeight={600}
        style={{ marginTop: -10 }}
      >
        {count || 0}
      </Text>
    </StyledMedalsItem>
  );
};

const StyledMedalsItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 5px; // Added margin for spacing
`;

const StyledMedalsIllustration = styled.img`
  object-fit: contain;
  height: 120px;
  width: ${({ width }) => `${width}px`};
`;

export default Medals;
