import React from "react";
import styled, { withTheme } from "styled-components";
import { useSpring, animated } from "react-spring";
import _ from "lodash";

const Progress = ({
  color,
  index,
  theme,
  style,
  height,
  opacityColor,
  progression = 0,
  backgroundColor,
}) => {
  const prevProgressRef = React.useRef();

  React.useEffect(() => {
    prevProgressRef.current = progression;
  });

  const prevProgress = prevProgressRef.current;

  const fromWidth =
    prevProgress && prevProgress > 0 ? `${_.toString(prevProgress)}%` : "0%";
  const toWidth =
    progression && progression > 0 ? `${_.toString(progression)}%` : "0%";

  const animationProps = useSpring({
    from: { width: fromWidth },
    to: { width: toWidth },
    delay: 500 + index * 200,
  });

  return (
    <Wrapper
      style={style}
      height={height || 11}
      backgroundcolor={backgroundColor || "rgba(255, 255, 255, 0.7)"}
    >
      <AnimatedFill
        style={{
          ...animationProps,
          backgroundColor: color || theme.colors.white,
          height: `${height || 11}px`,
          opacity: opacityColor,
        }}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  height: ${({ height }) => `${height}px`};
  background-color: ${({ backgroundcolor }) => backgroundcolor};
  opacity: 0.7;
`;

const Fill = styled(animated.div)`
  height: ${({ height }) => `${height}px`};
  background-color: ${({ color }) => color};
`;

const AnimatedFill = Fill;

export default withTheme(Progress);
