import { gql, useLazyQuery } from "@apollo/client";
import { useEffect, useCallback } from "react";
import { withTranslation } from "react-i18next";
import styled from "styled-components";
import _ from "lodash";
import {
  Avatar,
  Layout,
  Medals,
  StatisticsCard,
  Text,
  Title,
} from "../components";
import { theme } from "../styles";
import { common } from "../utils";
import { useNavigate, useParams } from "react-router-dom";

/*
|--------------------------------------------------------------------------
| QUERIES
|--------------------------------------------------------------------------
*/
const GET_USER = gql`
  query ($userId: ID!) {
    User(where: { id: $userId }) {
      id
      name
      picture {
        thumbnail
      }
      team {
        name
      }
      stats {
        totalPoints
        percentage
        questionsCount
        monthRank
      }
      badgesCount
    }
  }
`;

const SelectedProfile = ({ t }) => {
  /*
  |--------------------------------------------------------------------------
  | VARIABLES
  |--------------------------------------------------------------------------
  */
  const { id: userId } = useParams();
  const width = window.innerWidth;
  const navigate = useNavigate();

  /*
  |--------------------------------------------------------------------------
  | FETCHING
  |--------------------------------------------------------------------------
  */
  const [getUser, { loading, data }] = useLazyQuery(GET_USER, {
    variables: { userId },
  });

  /*
  |--------------------------------------------------------------------------
  | CYCLE LIFE
  |--------------------------------------------------------------------------
  */
  useEffect(() => {
    if (userId) {
      getUser();
    }
  }, [userId, getUser]);

  const badges = data?.User?.badgesCount;

  const count =
    badges && !_.isEmpty(badges) ? badges.reduce((a, b) => a + b, 0) : 0;

  const stats = data?.User?.stats;

  const statistics = [
    {
      isDark: true,
      color: "yellow",
      value: stats?.totalPoints,
      label: "recap_win_points",
    },
    {
      color: "red",
      isRanking: true,
      value: stats?.monthRank,
      label: "recap_month_ranking",
    },
    {
      isDark: true,
      color: "lightblue",
      value: stats?.questionsCount,
      label: "recap_question_treated",
    },
    {
      color: "violet",
      isPercentage: true,
      value: stats?.percentage,
      label: "recap_right_answers_percent",
    },
  ];

  return (
    <Layout
      headerConfig={{
        style: { paddingBottom: 100 },
        children: <Header user={data?.User} t={t} />,
        rightIconConfig: { name: "x", action: () => window.history.back() },
      }}
    >
      {loading ? (
        <ActivityIndicator size="large" color={theme.colors.blue} />
      ) : (
        <>
          <S.Cards width={width - 40}>
            {statistics.map((statistic, index) => (
              <StatisticsCard statistic={statistic} index={index} key={index} />
            ))}
          </S.Cards>

          <S.Wrapper width={width - 40}>
            <Text
              fontSize={20}
              style={{ marginBottom: 10 }}
              fontFamily={theme.fonts.bold}
              fontWeight={700}
            >
              {t("badges_earned", { count })}
            </Text>

            {common?.medals && !_.isEmpty(common.medals) && (
              <S.Medals>
                {common.medals.map((medal, index) => (
                  <Medals key={medal} medal={medal} count={badges?.[index]} />
                ))}
              </S.Medals>
            )}
          </S.Wrapper>
        </>
      )}
    </Layout>
  );
};

/*
|--------------------------------------------------------------------------
| STYLES
|--------------------------------------------------------------------------
*/
const S = {
  Cards: styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-top: -75px;
    align-self: center;
    flex-direction: row;
    align-items: center;
    margin-bottom: 30px;
    justify-content: space-between;
  `,
  Wrapper: styled.div`
    align-self: center;
    align-items: center;
    margin-bottom: 20px;
    justify-content: center;
  `,
  Medals: styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
  `,
};

/*
|--------------------------------------------------------------------------
| COMPONENTS
|--------------------------------------------------------------------------
*/
const Header = ({ user, t }) => (
  <>
    <Avatar
      isLocal
      size={67}
      style={{ marginBottom: 15 }}
      src={
        user?.picture?.thumbnail
          ? user.picture.thumbnail
          : require("../assets/images/default_avatar.png")
      }
    />
    {user?.name && <Title fontSize={20}>{user.name}</Title>}
    {user?.team?.name && (
      <Text style={{ marginTop: 5 }} color={theme.colors.white}>
        {t("team_name", { name: user.team.name })}
      </Text>
    )}
  </>
);

const ActivityIndicator = styled.div`
  width: 30px;
  height: 30px;
  border: 4px solid ${theme.colors.blue};
  border-top: 4px solid transparent;
  border-radius: 50%;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export default withTranslation()(SelectedProfile);
