import { gql, useLazyQuery, useQuery } from "@apollo/client";
import { useEffect, useState, useCallback } from "react";
import { useStoreActions, useStoreState } from "easy-peasy";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import styled, { withTheme } from "styled-components";

import {
  FilterRankModal,
  Layout,
  MemberCard,
  RankingHeader,
  RankingModal,
  Text,
} from "../components";
// import { storage } from "_services";
import { common } from "../utils";

import { theme } from "../styles";
import { useNavigate } from "react-router-dom";
import { storage } from "../services";

/*
|--------------------------------------------------------------------------
| QUERIES
|--------------------------------------------------------------------------
*/
const GET_NOTIFICATION = gql`
  query getPendingNotifications($notifications: [GetNotificationsEnum!]!) {
    getNotifications(notifications: $notifications) {
      firstRank
      secondRank
      thirdRank
    }
  }
`;

const GET_BADGES = gql`
  query getPendingMenuBadges {
    getPendingMenuBadges
  }
`;

const GET_RANK = gql`
  query getRanks(
    $themeId: ID
    $date: String!
    $type: GetRankTypeEnum!
    $scope: GetRankScopeEnum
    $length: GetRankLengthEnum!
    $includeProgression: Boolean
  ) {
    getRanks(
      type: $type
      date: $date
      scope: $scope
      length: $length
      themeId: $themeId
      includeProgression: $includeProgression
    ) {
      progression {
        date
        points
      }
      ranks {
        id
        name
        rank
        points
        teamName
        picture {
          thumbnail
        }
      }
    }
  }
`;

/*
|--------------------------------------------------------------------------
| VARIABLES
|--------------------------------------------------------------------------
*/
const menu = ["ranking_individual", "ranking_teams", "ranking_current_team"];

const config = {
  [menu[0]]: { type: "user", scope: null },
  [menu[1]]: { type: "team", scope: null },
  [menu[2]]: { type: "user", scope: "team" },
};

const date = new Date();
const currentMonth = date.getMonth();
const currentYear = date.getFullYear();
const formattedMonth =
  _.size(_.toString(currentMonth + 1)) > 1
    ? currentMonth + 1
    : `0${currentMonth + 1}`;

const Ranking = ({ theme }) => {
  const navigate = useNavigate();

  /*
  |--------------------------------------------------------------------------
  | STATES
  |--------------------------------------------------------------------------
  */
  const [modal, setModal] = useState(null);
  const [active, setActive] = useState(menu[0]);
  const [refresh, setRefresh] = useState(false);
  const [filterModal, setFilterModal] = useState(false);

  const [filters, setFilters] = useState({
    date: `${currentYear}-${formattedMonth}-01`,
    month: currentMonth,
    year: currentYear,
    length: "month",
    themeId: "all",
    day: "01",
  });

  /*
  |--------------------------------------------------------------------------
  | STORE
  |--------------------------------------------------------------------------
  */
  const user = useStoreState((state) => state.user);
  const themes = useStoreState((state) => state.themes);
  const setBadges = useStoreActions((actions) => actions.setBadges);

  /*
  |--------------------------------------------------------------------------
  | GRAPHQL
  |--------------------------------------------------------------------------
  */
  const {
    data: ranks,
    refetch: getRanks,
    loading: ranksLoading,
  } = useQuery(GET_RANK, {
    variables: {
      date: filters.date,
      length: filters.length,
      includeProgression: true,
      type: config[active].type,
      scope: config[active]?.scope,
      themeId: filters.themeId === "all" ? null : filters.themeId,
    },
    fetchPolicy: "no-cache",
  });

  const [getNotification, { data: notifications }] = useLazyQuery(
    GET_NOTIFICATION,
    {
      fetchPolicy: "network-only",
      variables: { notifications: ["firstRank", "secondRank", "thirdRank"] },
    }
  );

  const [getBadges, { data: badges }] = useLazyQuery(GET_BADGES, {
    fetchPolicy: "cache-and-network",
  });

  /*
  |--------------------------------------------------------------------------
  | FUNCTIONS
  |--------------------------------------------------------------------------
  */
  const onProfile = ({ id }) => navigate("/selected_profile", { id });

  function getButtonLabel() {
    let currentDate = null;
    const currentTheme = _.find(themes, (th) => th.id === filters.themeId);

    if (filters.length === "month") {
      currentDate = `${common.months[filters.month]} ${filters.year}`;
    }

    if (filters.length === "year") {
      currentDate = filters.year;
    }

    if (currentTheme) return `${currentTheme?.name} - ${currentDate}`;

    return `Tous thèmes confondus, ${currentDate}`;
  }

  const onRefresh = async () => {
    setRefresh(true);
    await getRanks();
    setRefresh(false);
  };

  /*
  |--------------------------------------------------------------------------
  | VARIABLES
  |--------------------------------------------------------------------------
  */
  const { t } = useTranslation();
  const width = window.innerWidth;

  const isTeam = active === "ranking_teams";
  const ranking = ranks?.getRanks?.ranks || [];

  const currentRanking = !_.isEmpty(ranking)
    ? _.find(ranking, (o) => o.id === user?.id || o.name === user?.team?.name)
    : null;

  const progressions =
    ranks?.getRanks?.progression && !_.isEmpty(ranks?.getRanks?.progression)
      ? _.filter(ranks?.getRanks?.progression, (o) => {
          const d = new Date(o.date);
          return d < date;
        })
      : [];

  /*
  |--------------------------------------------------------------------------
  | CYCLE LIFE
  |--------------------------------------------------------------------------
  */
  useEffect(() => {
    getBadges();
    getNotification();

    storage.get({
      key: "@year",
      callback: (value) => {
        if (!_.isEmpty(value)) {
          if (value !== _.toString(currentYear)) {
            navigate("/result_ranking", { target: "year" });
            storage.set({ key: "@year", value: _.toString(currentYear) });
          }
        } else {
          storage.set({ key: "@year", value: _.toString(currentYear) });
        }
      },
    });

    storage.get({
      key: "@month",
      callback: (value) => {
        if (!_.isEmpty(value)) {
          if (value !== _.toString(currentMonth)) {
            navigate("/result_ranking", { target: "month" });
            storage.set({ key: "@month", value: _.toString(currentMonth) });
          }
        } else {
          storage.set({ key: "@month", value: _.toString(currentMonth) });
        }
      },
    });
  }, []);

  useEffect(() => {
    if (
      badges?.data?.getPendingMenuBadges &&
      !_.isEmpty(badges?.data?.getPendingMenuBadges)
    ) {
      setBadges(badges.data?.getPendingMenuBadges);
      storage.set({
        key: "@badges",
        isObject: true,
        value: badges.data?.getPendingMenuBadges,
      });
    }
  }, [badges]);

  useEffect(() => {
    if (notifications?.getNotifications?.firstRank) {
      setModal("1");
    } else if (notifications?.getNotifications?.secondRank) {
      setModal("2");
    } else if (notifications?.getNotifications?.thirdRank) {
      setModal("3");
    }
  }, [notifications]);

  return (
    <>
      <FilterRankModal
        themes={themes}
        filters={filters}
        visible={filterModal}
        setFilters={setFilters}
        action={() => getRanks()}
        onClose={() => setFilterModal(false)}
        isBottomButton={true}
      />

      <RankingModal
        rank={modal}
        visible={!_.isEmpty(modal)}
        onClose={() => setModal(null)}
      />

      <Layout
        noSafe={true}
        showMenu
        headerConfig={{
          position: "relative",
          style: { paddingBottom: 20 },
          wrapperStyle: {
            borderBottomLeftRadius: 30,
            borderBottomRightRadius: 30,
          },
          filters: { active, setActive, menu },
          children: (
            <RankingHeader
              loading={ranksLoading}
              current={currentRanking}
              label={getButtonLabel()}
              progressions={progressions}
              onFilter={() => setFilterModal(true)}
            />
          ),
        }}
      >
        <Content>
          <Touchable
            onClick={() =>
              navigate("/result_ranking", {
                state: {
                  target: "month",
                },
              })
            }
          >
            <Text textAlign={"center"}>
              Voir le classement du mois précédent
            </Text>
          </Touchable>

          <FlatList>
            {ranking.length > 0 ? (
              ranking.map((item, index) => (
                <MemberCard
                  index={index}
                  key={item.id}
                  member={item}
                  animated={false}
                  onClick={() => onProfile({ id: item.id })}
                  currentTeam={isTeam || item?.teamName !== user?.team?.name}
                />
              ))
            ) : ranksLoading ? (
              <ActivityIndicator size="large" color={theme.colors.blue} />
            ) : (
              <Text
                textAlign="left"
                style={{ width: width - 40, marginLeft: 20 }}
              >
                {t("empty_ranking")}
              </Text>
            )}
          </FlatList>
        </Content>
      </Layout>
    </>
  );
};

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;
`;

const FlatList = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 20px 50px;
`;

const Touchable = styled.div`
  margin-top: 20px;
  width: 100%;
  padding-bottom: 20px;
  cursor: pointer;
  text-align: center;
`;

const ActivityIndicator = styled.div`
  margin: auto;
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-top: 4px solid ${({ color }) => color};
  width: 30px;
  height: 30px;
  animation: spin 2s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export default withTheme(Ranking);
