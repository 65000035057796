import { useStoreState } from "easy-peasy";
import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import styled from "styled-components";
import { Layout, Text } from "../components";
import { theme } from "../styles";
import { useNavigate } from "react-router-dom";

/*
|--------------------------------------------------------------------------
| VARIABLES
|--------------------------------------------------------------------------
*/
const menu = ["general", "quiz", "mission", "ranking"];
const rules = [
  {
    id: 1,
    category: "general",
    title: "Quel est le but de Save Dany ?",
    description: {
      dany: "Save Dany est une application dont le but est d’assurer une sensibilisation aux différents risques professionnels. Pour être sensibilisé, rien de plus simple : il faut jouer et protéger Dany contre Ynad !",
      normal:
        "Save Dany est une application dont le but est d’assurer une sensibilisation aux différents risques professionnels.",
    },
  },
  {
    id: 15,
    category: "general",
    title: "Qui sont Dany et Ynad ?",
    description:
      "Dany est une personne qui souhaite devenir un Héros. Mais son double maléfique Ynad est bien décidé à lui barrer la route en se servant des risques professionnels !",
  },
  {
    id: 2,
    category: "quiz",
    title: "A quoi sert le compte à rebours ?",
    description: {
      dany: "Chaque personne dispose de 2 minutes chaque jour pour jouer au Mode QCM. Vous pouvez entrer et utiliser l’application autant que vous voulez pour aller dans le Profil, les Insignes ou le Classement, cependant le Mode QCM ne peut durer que 2 minutes. Faites-en bon usage !",
      normal:
        "Chaque personne dispose de 2 minutes chaque jour pour jouer au Mode QCM. Vous pouvez entrer et utiliser l’application autant que vous voulez pour aller dans le Profil, les Insignes ou le Classement, cependant le Mode QCM ne peut durer que 2 minutes. Faites-en bon usage !",
    },
  },
  {
    id: 3,
    category: "quiz",
    title:
      "Puis-je quitter le Mode QCM avant que les 2 minutes soient écoulées ?",
    description: {
      dany: "Évidemment !",
      normal: "Évidemment !",
    },
  },
  {
    id: 4,
    category: "quiz",
    title:
      "Que se passera-t-il si je quitte le Mode QCM avant que mon compte à rebours soit écoulé ?",
    description: {
      dany: "Vous conservez le temps restant jusqu’à 20h.\nPar exemple si vous avez quitté le Mode QCM à 11h30 alors qu’il vous restait 45 secondes, vous pourrez les réutiliser à 17h dans le Mode QCM. Le compte à rebours reprendra lorsque vous reviendrez dans le Mode QCM.",
      normal:
        "Vous conservez le temps restant jusqu’à 20h.\nPar exemple si vous avez quitté le Mode QCM à 11h30 alors qu’il vous restait 45 secondes, vous pourrez les réutiliser à 17h dans le Mode QCM. Le compte à rebours reprendra lorsque vous reviendrez dans le Mode QCM.",
    },
  },
  {
    id: 5,
    category: "quiz",
    title: "Puis-je cumuler le temps de mon compte à rebours ?",
    description: {
      dany: "Il n’est pas possible de cumuler le temps du compte à rebours. Il se réinitialise à 2 minutes chaque jour.",
      normal:
        "Il n’est pas possible de cumuler le temps du compte à rebours. Il se réinitialise à 2 minutes chaque jour.",
    },
  },
  {
    id: 6,
    category: "quiz",
    title: "Durant combien de temps puis-je utiliser mes 2 minutes ?",
    description: {
      dany: "Entre 6h et 20h, du lundi au vendredi.",
      normal: "Entre 6h et 20h, du lundi au vendredi.",
    },
  },
  {
    id: 7,
    category: "quiz",
    title: "Combien de réponses sont possibles dans le Mode QCM ?",
    description: {
      dany: "Les questions sont toujours à choix multiples, qu’il y ait 2 ou 4 propositions.\nUne question à 2 propositions peut avoir une bonne réponse ou deux bonnes réponses.\nUne question à 4 propositions peut avoir une, deux, trois ou quatre bonnes réponses.",
      normal:
        "Les questions sont toujours à choix multiples, qu’il y ait 2 ou 4 propositions.\nUne question à 2 propositions peut avoir une bonne réponse ou deux bonnes réponses.\nUne question à 4 propositions peut avoir une, deux, trois ou quatre bonnes réponses.",
    },
  },
  {
    id: 8,
    category: "mission",
    title: "Que sont les Insignes ?",
    description: {
      dany: "Ce sont des récompenses qui vous seront octroyées lorsque vous réussirez des missions pendant votre aventure avec Dany !\nLes Insignes sont divisés en 4 classes : Bronze, Argent, Or, Ultime.",
      normal:
        "Ce sont des récompenses qui vous seront octroyées tout au long de votre apprentissage des risques professionnels !\nLes Insignes sont divisés en 4 classes : Bronze, Argent, Or, Ultime.",
    },
  },
  {
    id: 9,
    category: "general",
    title: "Comment sont formées les Équipes ?",
    description: {
      dany: "Les Équipes sont constituées de 2 à 5 membres en réunissant les salariés en fonction de l'organigramme de l'entreprise.",
      normal:
        "Les Équipes sont constituées de 2 à 5 membres en réunissant les salariés en fonction de l'organigramme de l'entreprise.",
    },
  },

  {
    id: 10,
    category: "ranking",
    title: "Le Classement se fait individuellement ou par équipe ?",
    description: {
      dany: "Il y a 3 classements :\n-  Le classement Individuel.\n-  Le classement Équipe.\n-  Un classement individuel entre les membres d’une même équipe : le Classement « Mon Équipe ».\n\nEn parallèle, chaque mois il y a un Classement Mensuel, et un Classement Annuel tout au long de l’année.",
      normal:
        "Il y a 3 classements :\n-  Le classement Individuel.\n-  Le classement Équipe.\n-  Un classement individuel entre les membres d’une même équipe : le Classement « Mon Équipe ».\n\nEn parallèle, chaque mois il y a un Classement Mensuel, et un Classement Annuel tout au long de l’année.",
    },
  },
  {
    id: 11,
    category: "ranking",
    title: "Comment sont établis ces Classements ?",
    description: {
      dany: "Ces 3 classement indique les points en fonction du temps. Par défaut, ils sont présentés en fonction du mois en cours.\n\nLe Classement Individuel indique les salariés en fonction des points gagnés.\nLe Classement Équipe classe les équipes en fonction des points gagnés.\nLe Classement « Mon Équipe » classe les membres de l’équipe en fonction des points gagnés.",
      normal:
        "Ces 3 classement indique les points en fonction du temps. Par défaut, ils sont présentés en fonction du mois en cours.\n\nLe Classement Individuel indique les salariés en fonction des points gagnés.\nLe Classement Équipe classe les équipes en fonction des points gagnés.\nLe Classement « Mon Équipe » classe les membres de l’équipe en fonction des points gagnés.",
    },
  },
  {
    id: 12,
    category: "ranking",
    title: "Peut-on analyser les Classements sur une autre durée ?",
    description: {
      dany: "Oui ! Dans « Filtres » en haut de la page « Classements », vous pourrez choisir de voir les classements en fonction du mois ou de l'année !",
      normal:
        "Oui ! Dans « Filtres » en haut de la page « Classements », vous pourrez choisir de voir les classements en fonction du mois ou de l'année !",
    },
  },
  {
    id: 13,
    category: "ranking",
    title: "Les points des Classement sont effacés définitivement ?",
    description: {
      dany: "Les classements se réinitialisent à chaque début de mois mais les résultats des mois précédents sont conservés et accessibles dans la partie « Filtres ».",
      normal:
        "Les classements se réinitialisent à chaque début de mois mais les résultats des mois précédents sont conservés et accessibles dans la partie « Filtres ».",
    },
  },
  {
    id: 14,
    category: "general",
    title: "Les équipes composées de moins de 5 membres sont désavantagées ?",
    description: {
      dany: "Pas du tout ! Votre nombre de points sera recalculé pour être équivalent à celui d’une équipe de 5 personnes.",
      normal:
        "Pas du tout ! Votre nombre de points sera recalculé pour être équivalent à celui d’une équipe de 5 personnes.",
    },
  },
];

const Rules = ({ t }) => {
  const navigate = useNavigate();

  /*
  |--------------------------------------------------------------------------
  | STORE
  |--------------------------------------------------------------------------
  */
  const isDany = true;

  /*
  |--------------------------------------------------------------------------
  | STATES
  |--------------------------------------------------------------------------
  */
  const [active, setActive] = useState(menu[0]);

  /*
  |--------------------------------------------------------------------------
  | FUNCTIONS
  |--------------------------------------------------------------------------
  */
  const onBack = () => window.history.back();

  /*
  |--------------------------------------------------------------------------
  | RENDER
  |--------------------------------------------------------------------------
  */
  return (
    <Layout
      headerConfig={{
        title: t("rules_title"),
        filters: { active, setActive, menu },
        leftIconConfig: { name: "chevron-left", action: onBack },
      }}
    >
      <S.Wrapper>
        {rules
          .filter((o) => o.category === active)
          .map(
            (item) =>
              (item.id !== 15 || (item.id === 15 && isDany)) && (
                <S.Section key={item.id}>
                  <Text
                    fontFamily={theme.fonts.bold}
                    fontWeight={700}
                    style={{ marginBottom: 10 }}
                    fontSize={20}
                  >
                    {item.title}
                  </Text>
                  <Text>
                    {item?.description?.dany
                      ? isDany
                        ? item?.description?.dany
                        : item?.description?.normal
                      : item?.description}
                  </Text>
                </S.Section>
              )
          )}
      </S.Wrapper>
    </Layout>
  );
};

/*
|--------------------------------------------------------------------------
| S
|--------------------------------------------------------------------------
*/
const S = {
  Wrapper: styled.div`
    padding: 20px;
    margin-top: 100px;
  `,
  Section: styled.div`
    margin-bottom: 40px;
  `,
};

export default withTranslation()(Rules);
