import React from "react";
import styled from "styled-components";
import { Text } from "../../atoms";
import { Countdown } from "../common";

const QuizChrono = ({ time, isCorrect = null }) => (
  <Styled.Top>
    <Countdown time={time} />

    {isCorrect !== null && (
      <>
        <Styled.Divider />
        <Text fontSize={16}>
          {isCorrect ? "10 points gagnés" : "0 point gagné"}
        </Text>
      </>
    )}
  </Styled.Top>
);

const Styled = {
  Top: styled.div`
    flex-direction: row;
    align-items: center;
    display: flex;
    justify-content: center;
    gap: 15px;
    margin-top: 10px;
  `,
  Divider: styled.div`
    width: 1px;
    height: 20px;
    margin: 15px;
    background-color: ${(props) => props.theme.colors.grey};
  `,
};

export default QuizChrono;
