import React from "react";
import FeatherIcon from "feather-icons-react";
import styled from "styled-components";
import { Text } from "../../atoms";
import { theme } from "../../../styles";

const FilterButton = ({ onClick, label, value, style }) => (
  <StyledFilterButton onClick={onClick} style={style}>
    <div
      style={{
        flex: 1,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        ...style,
      }}
    >
      <Text
        fontFamily={theme.fonts.semiBold}
        fontWeight={600}
        color={theme.colors.white}
      >
        {label}
      </Text>
      <Text
        color={theme.colors.white}
        style={{
          paddingLeft: "5px",
        }}
      >
        {" "}
        {value}
      </Text>
    </div>
    <FeatherIcon icon="chevron-right" color={theme.colors.white} size={20} />
  </StyledFilterButton>
);

const StyledFilterButton = styled.button`
  background-color: rgba(255, 255, 255, 0.2);
  border: none;
  border-radius: 15px;
  padding: 15px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export default FilterButton;
