import React from "react";
import { Text } from "../../components/atoms";
import { theme } from "../../styles";

const Title = ({
  color,
  style,
  children,
  fontSize,
  textAlign,
  fontFamily,
  textTransform,
  fontWeight,
  fontStyle,
  ...props
}) => (
  <Text
    style={style}
    textAlign={textAlign}
    fontSize={fontSize || 25}
    color={color || theme.colors.white}
    textTransform={textTransform || "uppercase"}
    fontFamily={fontFamily || theme.fonts.blackItalic}
    fontWeight={fontWeight || 900}
    fontStyle={fontStyle || "normal"}
    {...props}
  >
    {children}
  </Text>
);

export default Title;
