import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import FR from '../translations/translations.json';

i18n.use(initReactI18next).init({
  lng: 'fr',
  fallbackLng: 'fr',
  interpolation: { escapeValue: false },
  resources: { fr: { translation: FR } },
});

export default i18n;
