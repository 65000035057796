import { useStoreState } from "easy-peasy";
import React from "react";
import { withTranslation } from "react-i18next";
import FeatherIcon from "feather-icons-react";
import styled, { withTheme } from "styled-components";
import { Layout, Text, Title } from "../components"; // Adjust the import path
import { LogoSVG } from "../components/svg"; // Adjust the import path

const TransitionScreen = ({ route, navigation, theme, t }) => {
  const isDany = useStoreState((state) => state.isDany);

  return (
    <Layout
      backgroundColor={{ main: theme.colors.blue }}
      statusBarConfig={{ barStyle: "light-content" }}
      footerConfig={route?.params?.footerConfig}
    >
      <S.Wrapper>
        <S.Close
          onClick={() =>
            navigation.navigate(route?.params?.route, { refresh: true })
          }
        >
          <FeatherIcon icon="x" size={40} color={theme.colors.white} />
        </S.Close>

        <LogoSVG />

        <S.Content>
          <S.Illustration
            isDany={isDany}
            src={
              isDany
                ? require("../assets/images/ok_dany.png") // Adjust the import path
                : require("../assets/images/ok.png") // Adjust the import path
            }
            alt="Illustration"
          />

          {route?.params?.title && (
            <Title style={{ marginBottom: 10, textAlign: "center" }}>
              {t(route?.params?.title)}
            </Title>
          )}

          {route?.params?.description && (
            <Text
              fontSize={16}
              textAlign="center"
              color={theme.colors.white}
              style={{ paddingHorizontal: 20 }}
            >
              {t(route?.params?.description)}
            </Text>
          )}
        </S.Content>
      </S.Wrapper>
    </Layout>
  );
};

const S = {
  Wrapper: styled.div`
    display: flex;
    flex: 1;
    padding: 20px;
    align-items: center;
    justify-content: center;
  `,
  Close: styled.button`
    position: absolute;
    z-index: 10000;
    right: 10px;
    top: 10px;
    background: none;
    border: none;
    cursor: pointer;
  `,
  Illustration: styled.img`
    height: ${({ isDany }) => (isDany ? "300px" : "200px")};
    object-fit: contain;
    margin-bottom: 50px;
    width: 100%;
  `,
  Content: styled.div`
    display: flex;
    flex: 1;
    width: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  `,
};

export default withTheme(withTranslation()(TransitionScreen));
