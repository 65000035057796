import React, { useEffect } from "react";

const CustomStatusBar = ({
  statusBarConfig,
  loading,
  backgroundColor,
  headerConfig,
  barStyle,
}) => {
  useEffect(() => {
    if (statusBarConfig?.hidden) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    if (backgroundColor) {
      document
        .querySelector('meta[name="theme-color"]')
        .setAttribute("content", backgroundColor);
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [statusBarConfig, backgroundColor]);

  return (
    <React.Fragment>
      <meta name="theme-color" content={backgroundColor || "#ffffff"} />
      <style>
        {`
          body::-webkit-scrollbar {
            display: ${loading ? "none" : "block"};
          }
          .header {
            color: ${barStyle === "light-content" ? "#fff" : "#000"};
          }
        `}
      </style>
    </React.Fragment>
  );
};

export default CustomStatusBar;
