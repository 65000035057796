/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import { withTranslation } from "react-i18next";
import styled from "styled-components";
import { Layout, Text, Title } from "../components";
import { theme } from "../styles";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";

/*
|--------------------------------------------------------------------------
| VARIABLES
|--------------------------------------------------------------------------
*/
const secToMin = (value) => Math.floor(value / 60);

const textConfig = {
  color: theme.colors.white,
  textAlign: "center",
  fontSize: 16,
};

const medals_xxl = {
  0: theme.icons.medals.xxl.bronze,
  1: theme.icons.medals.xxl.bronze,
  2: theme.icons.medals.xxl.silver,
  3: theme.icons.medals.xxl.gold,
  4: theme.icons.medals.xxl.diamond,
};

const medals = {
  0: theme.icons.medals.bronze,
  1: theme.icons.medals.bronze,
  2: theme.icons.medals.silver,
  3: theme.icons.medals.gold,
  4: theme.icons.medals.diamond,
};

const labels = {
  0: "de bronze",
  1: "de bronze",
  2: "d'argent",
  3: "d'or",
  4: "de diamant",
};

const nextInsigne = {
  0: "bronze",
  1: "d'argent",
  2: "d'or",
  3: "ultime",
  4: "ultime",
};

const SelectedMission = ({ navigation, route, t }) => {
  const navigate = useNavigate();

  /*
  |--------------------------------------------------------------------------
  | VARIABLES
  |--------------------------------------------------------------------------
  */
  const location = useLocation();
  const { mission } = location.state || {};
  const { badge, stepsArray, type } = mission;

  const isFinish = badge === 4;
  const noMedal = badge === 0;

  /*
  |--------------------------------------------------------------------------
  | FUNCTIONS
  |--------------------------------------------------------------------------
  */
  const getDescription = ({ isNext }) => {
    const isPlayingTime = type === "playingTime";
    const isRank = type === "monthRank" || type === "yearRank";
    const r = isRank ? "_1" : "";
    const th = mission?.theme?.name ? "_theme" : "";
    const description = `${type}_description${r}${th}`;
    const count = isFinish
      ? isPlayingTime
        ? secToMin(stepsArray[3])
        : stepsArray[3]
      : isPlayingTime
      ? secToMin(stepsArray[isNext ? badge : badge - 1])
      : stepsArray[isNext ? badge : badge - 1];
    return t(description, { count, theme: mission?.theme?.name });
  };

  /*
  |--------------------------------------------------------------------------
  | MAIN RENDER
  |--------------------------------------------------------------------------
  */
  return (
    <Layout
      // goBack={{ action: () => navigation.goBack() }}
      statusBarConfig={{ barStyle: "light-content" }}
      backgroundColor={{ main: theme.colors.blue, top: theme.colors.blue }}
      headerConfig={{
        rightIconConfig: {
          name: "x",
          action: () =>
            navigate("/missions", {
              state: {
                refresh: false,
              },
            }),
        },
      }}
    >
      <S.Wrapper>
        <S.Medal.Winned
          src={medals_xxl[badge]}
          style={{ opacity: noMedal ? 0.2 : 1 }}
        />

        <Title
          fontSize={28}
          fontWeight={800}
          textAlign="center"
          style={{ marginBottom: 10 }}
        >
          {noMedal
            ? `vous n'avez pas encore remporté l'insigne ${labels[badge]}`
            : `vous avez remporté l’insigne ${labels[badge]} !`}
        </Title>

        {getDescription({ isNext: !!noMedal }) && (
          <Text {...textConfig}>{getDescription({ isNext: !!noMedal })}</Text>
        )}

        {isFinish ? null : (
          <>
            <S.Divider />
            <Text {...textConfig}>
              Prochain insigne à obtenir pour cette mission :
            </Text>
            <S.Medal.Next src={medals[badge + 1]} />
            <Title {...textConfig}>INSIGNE {nextInsigne[badge]}</Title>
            {getDescription({ isNext: true }) && (
              <Text {...textConfig}>{getDescription({ isNext: true })}</Text>
            )}
          </>
        )}
      </S.Wrapper>
    </Layout>
  );
};

/*
|--------------------------------------------------------------------------
| STYLES
|--------------------------------------------------------------------------
*/
const S = {
  Wrapper: styled.div`
    flex: 1;
    padding: 20px;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
  `,

  Divider: styled.div`
    background-color: ${theme.colors.white};
    margin: 32px 0;
    opacity: 0.5;
    width: 100%;
    height: 1px;
  `,

  Medal: {
    Next: styled.img`
      object-fit: cover;
      margin-top: 20px;
      height: 100px;
      width: 100px;
    `,
    Winned: styled.img`
      object-fit: cover;
      height: 250px;
      width: 250px;
    `,
    Slide: styled.img`
      object-fit: cover;
      height: 200px;
      width: 200px;
    `,
  },
};

export default withTranslation()(SelectedMission);
