import React from "react";
import { withTranslation } from "react-i18next";
import styled from "styled-components";
import FeatherIcon from "feather-icons-react";
import { theme } from "../../../styles";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const Section = ({
  category,
  navigation,
  isNotification,
  notifIsActive,
  setNotifIsActive,
}) => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  return (
    <StyledCategory
      key={category.key}
      isNotification={isNotification}
      onClick={() => (isNotification ? null : navigate("../" + category.path))}
    >
      <StyledTop>
        <StyledText
          fontSize={18}
          fontFamily={theme.fonts.semiBold}
          fontWeight={600}
        >
          {t(`settings_${category.key}`)}
        </StyledText>

        {isNotification && (
          <Switch>
            <input
              type="checkbox"
              checked={notifIsActive}
              onChange={setNotifIsActive}
            />
            <Slider />
          </Switch>
        )}
      </StyledTop>

      {!isNotification && (
        <FeatherIcon
          icon="chevron-right"
          size={30}
          style={{ paddingLeft: 40 }}
        />
      )}

      {isNotification && (
        <StyledText
          fontSize={14}
          style={{ marginRight: "auto", paddingRight: 60 }}
        >
          {t(
            notifIsActive
              ? `settings_notification_description`
              : `settings_notification_description_inactive`
          )}
        </StyledText>
      )}
    </StyledCategory>
  );
};

const StyledCategory = styled.div`
  display: flex;
  align-items: center;
  padding: 30px 0;
  border-top: 1.5px solid ${theme.colors.lightgrey};
  justify-content: ${(props) =>
    props.isNotification ? "center" : "space-between"};
  flex-direction: ${(props) => (props.isNotification ? "column" : "row")};
  cursor: pointer;
`;

const StyledTop = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const StyledText = styled.p`
  font-size: ${(props) => props.fontSize}px;
  font-family: ${(props) => props.fontFamily};
  margin: 0;
`;

const Switch = styled.label`
  position: relative;
  display: inline-block;
  width: 34px;
  height: 20px;

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  input:checked + div {
    background-color: ${theme.colors.green};
  }

  input:checked + div:before {
    transform: translateX(14px);
  }
`;

const Slider = styled.div`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${theme.colors.red};
  transition: 0.4s;
  border-radius: 34px;

  &:before {
    position: absolute;
    content: "";
    height: 14px;
    width: 14px;
    left: 3px;
    bottom: 3px;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
  }
`;

export default withTranslation()(Section);
