/* eslint-disable react/jsx-props-no-spreading */
import { gql, useLazyQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import styled, { withTheme } from "styled-components";
import _ from "lodash";
import { BadgesModal, Layout, MissionCard, Text, Spinner } from "../components";
import { useTranslation } from "react-i18next";
import { theme } from "../styles";
import { CiTextAlignCenter } from "react-icons/ci";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useStoreActions } from "easy-peasy";

import { storage } from "../services";

/*
|--------------------------------------------------------------------------
| QUERIES
|--------------------------------------------------------------------------
*/
const GET_NOTIFICATION = gql`
  query getPendingNotifications($notifications: [GetNotificationsEnum!]!) {
    getNotifications(notifications: $notifications) {
      newBadges {
        step
        challenge {
          type
          stepsArray
          theme {
            name
          }
        }
      }
    }
  }
`;

const GET_MISSIONS = gql`
  query {
    getChallenges {
      type
      stepsArray
      badge
      count
      theme {
        id
        name
      }
    }
  }
`;

const GET_MISSIONS_THEME = gql`
  query {
    getThemeChallenges {
      type
      stepsArray
      badge
      count
      theme {
        id
        name
      }
    }
  }
`;

const GET_BADGES = gql`
  query getPendingMenuBadges {
    getPendingMenuBadges
  }
`;

/*
|--------------------------------------------------------------------------
| COMPONENT
|--------------------------------------------------------------------------
*/
const menu = ["missions_global", "missions_theme"];

const Missions = ({ theme }) => {
  const navigate = useNavigate();
  const location = useLocation();

  /*
  |--------------------------------------------------------------------------
  | STATES
  |--------------------------------------------------------------------------
  */
  const [openModal, setOpenModal] = useState(false);
  const [active, setActive] = useState(menu[0]);
  const [refresh, setRefresh] = useState(false);
  const [missions, setMissions] = useState(null);
  const [themes, setThemes] = useState(null);
  const [notificationData, setNotificationData] = useState(null);
  const [badgesData, setBadgesData] = useState(null);
  const [loading, setLoading] = useState(false);
  /*
  |--------------------------------------------------------------------------
  | FETCHING
  |--------------------------------------------------------------------------
  */
  const [getMissionsQuery, { loading: missionsLoading }] = useLazyQuery(
    GET_MISSIONS,
    {
      fetchPolicy: "cache-first",
      onCompleted(res) {
        setMissions(res.getChallenges);
        console.debug("GRAPHQL response is ready");
        console.debug(res.getChallenges);
        console.debug("GRAPHQL log is done");
      },
    }
  );

  const [getThemesQuery, { loading: themesLoading }] = useLazyQuery(
    GET_MISSIONS_THEME,
    {
      fetchPolicy: "cache-first",
      onCompleted(res) {
        setThemes(res.getThemeChallenges);
        console.debug("theme mission is ready");
        console.debug(res.getThemeChallenges);
      },
    }
  );

  const [getNotifQuery] = useLazyQuery(GET_NOTIFICATION, {
    fetchPolicy: "cache-first",
    variables: { notifications: ["newBadges"] },
    onCompleted(res) {
      setNotificationData(res.getNotifications);
    },
  });

  const [getBadgesQuery] = useLazyQuery(GET_BADGES, {
    fetchPolicy: "cache-first",
    onCompleted(res) {
      setBadgesData(res.getPendingMenuBadges);
    },
  });

  /*
  |--------------------------------------------------------------------------
  | STORES
  |--------------------------------------------------------------------------
  */
  const { t } = useTranslation();

  const setBadges = useStoreActions((actions) => actions.setBadges);

  /*
  |--------------------------------------------------------------------------
  | FUNCTIONS
  |--------------------------------------------------------------------------
  */
  const onRefresh = async () => {
    setRefresh(true);
    if (active === "missions_theme") {
      await getThemesQuery();
    } else {
      await getMissionsQuery();
    }
    setRefresh(false);
  };

  /*
  |--------------------------------------------------------------------------
  | USEEFFECT - LIFECYCLE HOOKS
  |--------------------------------------------------------------------------
  */
  useEffect(() => {
    const fetchInitialData = async () => {
      setLoading(true);
      await getBadgesQuery();
      await getNotifQuery();
      setLoading(false);
    };

    fetchInitialData();
  }, []);

  useEffect(() => {
    if (active === "missions_theme") {
      if (!themes?.length) getThemesQuery();
    } else {
      if (!missions?.length) getMissionsQuery();
    }
  }, [active]);

  useEffect(() => {
    if (badgesData && !_.isEmpty(badgesData)) {
      setBadges(badgesData);
      storage.set({
        key: "@badges",
        isObject: true,
        value: badgesData,
      });
    }
  }, [badgesData]);

  useEffect(() => {
    if (notificationData?.newBadges && !_.isEmpty(notificationData.newBadges)) {
      setOpenModal(true);
    }
  }, [notificationData]);

  /*
  |--------------------------------------------------------------------------
  | MAIN RENDER
  |--------------------------------------------------------------------------
  */
  return (
    <>
      {!_.isEmpty(notificationData?.newBadges) && (
        <BadgesModal
          visible={openModal}
          onClose={() => setOpenModal(false)}
          badges={notificationData?.newBadges}
        />
      )}

      <Layout
        showMenu
        headerConfig={{
          wrapperStyle: {
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
          },
          style: { paddingBottom: 10 },
          filters: { active, setActive, menu },
          title: t("missions_title"),
        }}
        noScroll={true}
      >
        {" "}
        <S.Blue />
        <S.Wrapper>
          {(loading || themesLoading || missionsLoading) && !refresh ? (
            <Spinner color={"white"} />
          ) : (
            <MissionList>
              {active === "missions_theme" ? (
                themes?.length ? (
                  themes
                    ?.filter((challenge) => challenge.theme)
                    ?.map((mission, index) => (
                      <MissionCard
                        key={index}
                        {...mission}
                        onClick={() =>
                          navigate("/selected_mission", { state: { mission } })
                        }
                      />
                    ))
                ) : (
                  <Text color={"white"} textAlign={"center"}>
                    Aucune mission disponible pour cette section
                  </Text>
                )
              ) : missions?.length ? (
                missions?.map((mission, index) => (
                  <MissionCard
                    key={index}
                    {...mission}
                    onClick={() =>
                      navigate("/selected_mission", { state: { mission } })
                    }
                  />
                ))
              ) : (
                <Text color={"white"} textAlign={"center"}>
                  Aucune mission disponible pour cette section
                </Text>
              )}
            </MissionList>
          )}
        </S.Wrapper>
      </Layout>
    </>
  );
};

const S = {
  Wrapper: styled.div`
    width: 100%;
    position: relative;
    z-index: 999;
    padding-top: 125px;
    overflow-y: scroll;
  `,
  Blue: styled.div`
    top: 140px;
    left: 0;
    width: 100%;
    height: 100px;
    position: fixed;
    border-bottom-left-radius: 50px;
    border-bottom-right-radius: 50px;
    background-color: ${theme.colors.blue};
  `,
};

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
`;

const MissionList = styled.div`
  padding: 70px 20px 50px;
`;

export default withTheme(Missions);
