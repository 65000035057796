import React from "react";
import styled from "styled-components";
import { Text } from "../atoms";
import { theme } from "../../styles";

const Link = ({
  color,
  style,
  onClick,
  fontSize,
  children,
  textAlign,
  fontFamily,
  textTransform,
}) => (
  <Styled.Link
    style={style}
    onClick={onClick}
    color={color || theme.colors.white}
  >
    <Text
      fontSize={fontSize}
      textAlign={textAlign}
      fontFamily={fontFamily}
      textTransform={textTransform}
      color={color || theme.colors.white}
    >
      {children}
    </Text>
  </Styled.Link>
);

const Styled = {
  Link: styled.a`
    align-self: center;
    text-decoration: none;
    color: ${({ color }) => color || theme.colors.white};
    border-bottom: 1px solid ${({ color }) => color || theme.colors.white};
    cursor: ${({ onClick }) => (onClick ? "pointer" : "default")};
  `,
};

export default Link;
