import { useStoreState } from "easy-peasy";
import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import styled from "styled-components";
import { Text } from "../../atoms";
import { Title, Progress } from "../../molecules";
import { theme } from "../../../styles";

const QuizHeader = ({
  t,
  level,
  isRules,
  loading,
  colorConfig,
  currentTheme,
  questionsCount,
  isSmallDevice,
  currentQuestion,
}) => {
  const isDany = useStoreState((state) => state.isDany);
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const progressBar =
    currentQuestion && questionsCount
      ? Math.round((currentQuestion / questionsCount) * 100)
      : 0;

  return (
    <Styled.Header
      width={width}
      height={height}
      isRules={isRules}
      backgroundColor={colorConfig.backgroundColor}
    >
      {(currentTheme?.coverFile || currentTheme?.cover?.file) && (
        <Styled.Illustration
          src={
            currentTheme?.cover?.file
              ? currentTheme.cover.file
              : currentTheme.coverFile
          }
          alt="Theme Cover"
        />
      )}

      {currentTheme?.name && (
        <Title
          fontSize={16}
          color={colorConfig.color}
          textAlign="center"
          style={{
            marginTop: isRules ? 40 : 0,
            width: width / 1.5,
            alignSelf: "center",
          }}
        >
          {currentTheme.name}
        </Title>
      )}

      {!isRules && !loading && (
        <Styled.Tooltip>
          <Text
            fontSize={12}
            textTransform="uppercase"
            fontFamily={theme.fonts.blackItalic}
            fontWeight={900}
            fontStyle={"italic"}
          >
            {`${currentQuestion} / ${questionsCount}`}
          </Text>
        </Styled.Tooltip>
      )}

      {(currentTheme?.progression?.level || level) && (
        <Text
          fontSize={16}
          textAlign="center"
          color={colorConfig.color}
          fontFamily={theme.fonts.medium}
          style={{ marginBottom: 20, marginTop: 10 }}
          fontWeight={500}
        >
          {t("level", { level: level || currentTheme?.progression?.level })}
        </Text>
      )}

      {isRules && isDany && (
        <Styled.Dany
          isSmallDevice={isSmallDevice}
          src={require("../../../assets/images/dany_quiz.png")}
          alt="Dany"
        />
      )}

      {!isRules && (
        <Progress
          height={12}
          progression={progressBar}
          color={theme.colors.yellow}
          backgroundColor="rgba(255, 255, 255, 0.3)"
          style={{ width, position: "absolute", bottom: 0 }}
        />
      )}
    </Styled.Header>
  );
};

const Styled = {
  Header: styled.div`
    width: ${({ width }) => width + "px"};
    min-height: 120px;
    position: relative;
    align-items: center;
    background-color: ${({ backgroundColor }) => backgroundColor};
    max-height: ${({ isRules }) => (isRules ? "10000px" : "300px")};
    justify-content: ${({ isRules }) => (isRules ? "flex-start" : "center")};
    display: flex;
    flex-direction: column;
  `,

  Illustration: styled.img`
    width: 100%;
    opacity: 0.6;
    height: 100%;
    position: absolute;
    object-fit: cover;
  `,

  Dany: styled.img`
    width: 100%;
    height: ${({ isSmallDevice }) => (isSmallDevice ? "230px" : "358px")};
    margin-top: auto;
    object-fit: contain;
  `,

  Tooltip: styled.div`
    background-color: white;
    position: absolute;
    padding: 5px 10px;
    box-shadow: 0px 5px 6.68px rgba(0, 0, 0, 0.16);
    border-radius: 10px;
    z-index: 100;
    left: 10px;
    top: 10px;
  `,
};

export default withTranslation()(QuizHeader);
