import React from "react";
import { useStoreState } from "easy-peasy";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { LoadingScreen } from "../components";
import {
  GeneralConditions,
  Helps,
  Home,
  Login,
  Missions,
  Onboarding,
  Password,
  Profile,
  QuizQuestions,
  QuizRecap,
  QuizResult,
  QuizRules,
  Ranking,
  Recap,
  Rules,
  SelectedMission,
  SelectedProfile,
  Settings,
  ResultRanking,
  TransitionScreen,
  QuizDayRecap,
  Logout,
} from "../screens";
import { storage } from "../services";

const RootNavigator = () => {
  const isAuthenticated = useStoreState((state) => state.isAuthenticated);

  if (!isAuthenticated) {
    storage.set({ key: "@token", value: "" });
    storage.set({ key: "@companyId", value: "" });
    storage.set({ key: "@user", value: "" });
  }

  const showOnboarding = useStoreState((state) => state.showOnboarding);
  const hydrated = useStoreState((state) => state.hydrated);

  return (
    <Router>
      <Routes>
        {!isAuthenticated ? (
          <>
            <Route path="/login" element={<Login />} />
            <Route path="/transition" element={<TransitionScreen />} />
            <Route path="/auth_conditions" element={<GeneralConditions />} />
            <Route path="*" element={<Navigate to="/login" />} />
          </>
        ) : (
          <>
            {/* <Route path="/onboarding" element={<Onboarding />} /> */}
            {showOnboarding && (
              <Route path="*" element={<Navigate to="/home" />} />
            )}
            <Route path="/home" element={<Home />} />
            <Route path="/recap" element={<Recap />} />
            <Route path="/helps" element={<Helps />} />
            <Route path="/rules" element={<Rules />} />
            <Route path="/ranking" element={<Ranking />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/missions" element={<Missions />} />
            <Route path="/settings" element={<Settings />} />
            <Route path="/quiz_rules" element={<QuizRules />} />
            <Route path="/quiz_recap" element={<QuizRecap />} />
            <Route path="/quiz_result" element={<QuizResult />} />
            <Route path="/conditions" element={<GeneralConditions />} />
            <Route path="/quiz_questions" element={<QuizQuestions />} />
            <Route path="/selected_profile" element={<SelectedProfile />} />
            <Route path="/selected_mission" element={<SelectedMission />} />
            <Route path="/result_ranking" element={<ResultRanking />} />
            <Route path="/quiz_day_recap" element={<QuizDayRecap />} />

            {isAuthenticated && <Route path="/logout" element={<Logout />} />}
            <Route path="*" element={<Navigate to="/home" />} />
          </>
        )}
      </Routes>
    </Router>
  );
};

export default RootNavigator;
