/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import styled from "styled-components";
import { Text } from "../atoms";
import { Title } from "../molecules";
import { Modal } from "../organisms/common";
import { theme } from "../../styles";
import _ from "lodash";

const secToMin = (value) => Math.floor(value / 60);

const textConfig = {
  color: theme.colors.white,
  textAlign: "center",
  fontSize: 16,
};

const medals_xxl = {
  0: theme.icons.medals.xxl.bronze,
  1: theme.icons.medals.xxl.bronze,
  2: theme.icons.medals.xxl.silver,
  3: theme.icons.medals.xxl.gold,
  4: theme.icons.medals.xxl.diamond,
};

const medals = {
  0: theme.icons.medals.bronze,
  1: theme.icons.medals.bronze,
  2: theme.icons.medals.silver,
  3: theme.icons.medals.gold,
  4: theme.icons.medals.diamond,
};

const labels = {
  0: "de bronze",
  1: "de bronze",
  2: "d'argent",
  3: "d'or",
  4: "de diamant",
};

const nextInsigne = {
  0: "bronze",
  1: "d'argent",
  2: "d'or",
  3: "ultime",
  4: "ultime",
};

const BadgesModal = ({ t, visible, onClose, badges }) => {
  const [position, setPosition] = useState(1);
  const width = window.innerWidth;
  const isMultiple = badges && !_.isEmpty(badges) && _.size(badges) > 1;

  const indicators =
    badges &&
    !_.isEmpty(badges) &&
    Array.from(Array(badges.length), (__, i) => i + 1);

  function onScroll(e) {
    const pageNumber = Math.round(e.target.scrollLeft / width + 1);
    if (pageNumber !== position) {
      setPosition(pageNumber);
    }
  }

  const render = () =>
    isMultiple && badges && !_.isEmpty(badges) ? badges : badges[0];

  const getDescription = ({ item, isNext }) => {
    const { step } = item;
    const isFinish = step === 4;

    const { type, stepsArray } = item.challenge;
    const currentTheme =
      item?.challenge?.mission?.theme?.name || item?.challenge?.theme?.name;

    const isPlayingTime = type === "playingTime";
    const isRank = type === "monthRank" || type === "yearRank";
    const r = isRank ? "_1" : "";
    const th = currentTheme ? "_theme" : "";
    const description = `${type}_description${r}${th}`;

    const count = isFinish
      ? isPlayingTime
        ? secToMin(stepsArray[3])
        : stepsArray[3]
      : isPlayingTime
      ? secToMin(stepsArray[isNext ? step : step - 1])
      : stepsArray[isNext ? step : step - 1];

    return t(description, { count, theme: currentTheme });
  };

  return (
    <Modal visible={visible} onClose={onClose} noPadding={isMultiple}>
      {!isMultiple ? (
        <Styled.Wrapper>
          <Styled.Medal.Winned src={medals_xxl[render().step]} />
          <Title fontSize={28} textAlign="center" style={{ marginBottom: 10 }}>
            {render().step === 0
              ? `vous n'avez pas encore remporté l'insigne ${
                  labels[render().step]
                }`
              : `vous avez remporté l’insigne ${labels[render().step]} !`}
          </Title>
          {getDescription({
            item: render(),
            isNext: !!render().step === 0,
          }) && (
            <Text {...textConfig}>
              {getDescription({
                item: render(),
                isNext: !!render().step === 0,
              })}
            </Text>
          )}

          {render().step === 4 ? null : (
            <>
              <Styled.Divider />
              <Text {...textConfig}>
                Prochain insigne à obtenir pour cette mission :
              </Text>
              <Styled.Medal.Next src={medals[render().step + 1]} />
              <Title {...textConfig}>
                INSIGNE {nextInsigne[render().step]}
              </Title>
              {getDescription({ item: render(), isNext: true }) && (
                <Text {...textConfig}>
                  {getDescription({ item: render(), isNext: true })}
                </Text>
              )}
            </>
          )}
        </Styled.Wrapper>
      ) : (
        <Styled.Wrapper>
          <Title fontSize={28} textAlign="center" style={{ marginBottom: 10 }}>
            Bien joué !
          </Title>
          <Text style={{ textAlignLast: "center" }} {...textConfig}>
            Vous avez remporté <Bold>{render().length}</Bold> insignes !
          </Text>

          {render() && !_.isEmpty(render()) && (
            <Styled.Carousel>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  overflowX: "auto",
                  scrollSnapType: "x mandatory",
                }}
                onScroll={onScroll}
              >
                {render().map((item, index) => (
                  <Styled.Slide key={index} width={width}>
                    <Styled.Medal.Slide src={medals[item.step]} />

                    {item?.challenge?.type && item?.step && (
                      <Title
                        fontSize={18}
                        textAlign="center"
                        style={{ marginBottom: 10 }}
                      >
                        {`Vous avez remporté l'insigne ${
                          labels[item.step]
                        } pour la mission “${t(item.challenge.type)}“`}
                      </Title>
                    )}

                    {getDescription({ item, isNext: false }) && (
                      <Text {...textConfig}>
                        {getDescription({ item, isNext: false })}
                      </Text>
                    )}

                    {item.step === 4 ? null : (
                      <>
                        <Styled.Divider />
                        <Text {...textConfig}>
                          Prochain insigne à obtenir pour cette mission :
                        </Text>
                        <Styled.Medal.Next src={medals[item.step + 1]} />
                        <Title {...textConfig}>
                          INSIGNE {nextInsigne[item.step]}
                        </Title>
                        {getDescription({ item, isNext: true }) && (
                          <Text {...textConfig}>
                            {getDescription({ item, isNext: true })}
                          </Text>
                        )}
                      </>
                    )}
                  </Styled.Slide>
                ))}
              </div>

              {indicators &&
                !_.isEmpty(indicators) &&
                indicators.length <= 15 && (
                  <Styled.Indicator.Wrapper>
                    {indicators.map((indicator) => (
                      <Styled.Indicator.Item
                        isLast={indicator === 3}
                        isActive={indicator === position}
                        key={indicator}
                      />
                    ))}
                  </Styled.Indicator.Wrapper>
                )}
            </Styled.Carousel>
          )}
        </Styled.Wrapper>
      )}
    </Modal>
  );
};

const Bold = ({ children }) => (
  <Text
    fontSize={16}
    color={theme.colors.white}
    fontFamily={theme.fonts.bold}
    fontWeight={700}
  >
    {children}
  </Text>
);

const Styled = {
  Wrapper: styled.div`
    width: 100%;
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
  `,
  Divider: styled.div`
    background-color: ${theme.colors.white};
    margin-vertical: 32px;
    opacity: 0.5;
    width: 100%;
    height: 1px;
  `,
  Slide: styled.div`
    align-items: center;
    justify-content: center;
    scroll-snap-align: center;
    display: flex;
    flex-direction: column;
    min-width: ${({ width }) => `${width}px`};
    gap: 10px;
  `,
  Carousel: styled.div`
    margin-top: 20px;
    min-height: 400px;
    position: relative;
    flex: 1;
    overflow: hidden;
  `,
  Medal: {
    Next: styled.img`
      object-fit: cover;
      margin-top: 20px;
      height: 100px;
      width: 100px;
    `,
    Winned: styled.img`
      object-fit: cover;
      height: 250px;
      width: 250px;
    `,
    Slide: styled.img`
      object-fit: cover;
      height: 130px;
      width: 130px;
    `,
  },
  Indicator: {
    Wrapper: styled.div`
      margin-top: 20px;
      align-self: center;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      position: absolute;
      bottom: 0px;
      right: 50%;
      display: flex;
    `,
    Item: styled.div`
      width: 10px;
      height: 10px;
      margin-right: 10px;
      border-radius: 20px;
      background-color: ${({ isActive }) =>
        isActive ? theme.colors.white : "rgba(255, 255, 255, 0.3)"};
    `,
  },
};

export default withTranslation()(BadgesModal);
