/* eslint-disable no-unused-vars */
import {
  gql,
  NetworkStatus,
  useLazyQuery,
  useMutation,
  useQuery,
} from "@apollo/client";
import { useEffect, useState, useCallback } from "react";
import { useStoreActions, useStoreState } from "easy-peasy";
import _ from "lodash";
import React from "react";
import {
  CategoryCard,
  CountdownCard,
  HomeFirstConnectMessage,
  HomeHeader,
  Layout,
  Text,
} from "../components";
import { theme } from "../styles";
import { TailSpin } from "react-loader-spinner"; // Importing the loader
import { storage } from "../services"; // Adjust the import path
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

/*
|--------------------------------------------------------------------------
| QUERIES
|--------------------------------------------------------------------------
*/
const GET_BADGES = gql`
  query getPendingMenuBadges {
    getPendingMenuBadges
  }
`;

const GET_THEMES = gql`
  query getThemes($companyId: String!) {
    getThemes(companyId: $companyId) {
      id
      name
      progressionQuestionsCount
      progressionCurrentQuestion
      progressionPercentage
      progressionLevel
      progressionThemeLevelId
      levels {
        id
        level
      }
      color
      backgroundColor
      coverFile
    }
  }
`;

const GET_TIME = gql`
  query getRemainingTime {
    getRemainingTime
  }
`;

const GET_ME = gql`
  query getMe {
    getMe {
      id
      remainingTime
      blockedWeekEnd
      blockedUntil
      isUserBlocked
    }
  }
`;

const GET_BLOCK = gql`
  query getUser($userId: ID!) {
    User(where: { id: $userId }) {
      id
      isBlocked
      blockedWeekEnd
      blockedUntil
    }
  }
`;

const TIMEZONE = gql`
  mutation setTimezone($timezone: String!) {
    setTimezone(timezone: $timezone)
  }
`;

/*
|--------------------------------------------------------------------------
| VARIABLES
|--------------------------------------------------------------------------
*/

const date = new Date();
const hour = date.getHours();
const dayOfWeek = date.getDay();
const isWeekend = dayOfWeek === 6 || dayOfWeek === 0;

const isCloseToBottom = ({ layoutMeasurement, contentOffset, contentSize }) => {
  const paddingToBottom = 20;
  return (
    layoutMeasurement.height + contentOffset.y >=
    contentSize.height - paddingToBottom
  );
};

const Home = ({ navigation, route }) => {
  const navigate = useNavigate();

  /*
    |--------------------------------------------------------------------------
    | STATES
    |--------------------------------------------------------------------------
    */
  const [isVisible, setIsVisible] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const [companyId, setCompanyId] = useState(null);
  const [themeIsLoading, setThemeIsLoading] = useState(false);
  const [screenSize, setScreenSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  /*
    |--------------------------------------------------------------------------
    | STORE
    |--------------------------------------------------------------------------
    */
  const user = useStoreState((state) => state.user);
  const isFirstLog = useStoreState((state) => state.isFirstLog);
  const logOut = useStoreActions((actions) => actions.logOut);
  const setUser = useStoreActions((actions) => actions.setUser);
  const setBadges = useStoreActions((actions) => actions.setBadges);
  const setThemes = useStoreActions((actions) => actions.setThemes);
  const setIsFirstLog = useStoreActions((actions) => actions.setIsFirstLog);
  const setShowOnboarding = useStoreActions(
    (actions) => actions.setShowOnboarding
  );

  /*
    |--------------------------------------------------------------------------
    | FETCHING
    |--------------------------------------------------------------------------
    */
  const [
    fetchThemes,
    {
      data: themes,
      error: themeError,
      refetch: getThemes,
      loading: loadingTheme,
      NetworkStatus: themeStatus,
    },
  ] = useLazyQuery(GET_THEMES, {
    fetchPolicy: "network-only",
    onCompleted(res) {
      setThemeIsLoading(false);
      console.debug("themes result ready");
      console.debug("get themes:", res?.getThemes);
    },
  });

  const [getBadges, { data: badges }] = useLazyQuery(GET_BADGES, {
    fetchPolicy: "cache-and-network",
  });

  const [getMe, { data: meData, loading: meLoading, error: meError }] =
    useLazyQuery(GET_ME, {
      fetchPolicy: "cache-and-network",
      onCompleted(res) {
        console.debug(res?.getMe);
      },
    });

  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const [setTimezone, { data: tz, error: tzError }] = useMutation(TIMEZONE, {
    variables: { timezone: timeZone },
  });

  /*
    |--------------------------------------------------------------------------
    | VARIABLES
    |--------------------------------------------------------------------------
    */
  const remainingTime = meData?.getMe?.remainingTime;
  const themeIsDisable = hour >= 20 || hour < 6;

  /*
    |--------------------------------------------------------------------------
    | FUNCTIONS
    |--------------------------------------------------------------------------
    */
  const onRules = ({ item, isBlocked }) =>
    navigate("/quiz_rules", {
      state: {
        currentTheme: {
          ...item,
          progression: {
            questionsCount: item.progressionQuestionsCount,
            currentQuestion: item.progressionCurrentQuestion,
            percentage: item.progressionPercentage,
            level: item.progressionLevel,
            themeLevelId: item.progressionThemeLevelId,
          },
        },
        remainingTime,
        isBlocked,
      },
    });

  const onRefresh = async () => {
    setRefresh(true);
    await Promise.all([
      () => fetchThemes({ variables: { companyId } }),
      () => getMe(),
    ]);
    setRefresh(false);
  };

  /*
    |--------------------------------------------------------------------------
    | CYCLE LIFE
    |--------------------------------------------------------------------------
    */

  useEffect(() => {
    const handleResize = () => {
      setScreenSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (
      themeError?.message === "You do not have access to this resource" ||
      meError?.message === "You do not have access to this resource"
    ) {
      logOut();
    }
  }, [themeError, meError]);

  useEffect(() => {
    if (companyId) {
      fetchThemes({ variables: { companyId } });
    }
  }, [companyId]);

  useEffect(() => {
    if (
      badges?.data?.getPendingMenuBadges &&
      !_.isEmpty(badges?.data?.getPendingMenuBadges)
    ) {
      setBadges(badges.data?.getPendingMenuBadges);
      storage.set({
        key: "@badges",
        isObject: true,
        value: badges.data?.getPendingMenuBadges,
      });
    }
  }, [badges]);

  useEffect(() => {
    getBadges();
    setTimezone();
    getMe();
    storage.get({
      key: "@user",
      isObject: true,
      callback: (m) => setUser({ ...m }),
    });

    storage.get({
      key: "@companyId",
      callback: (m) => setCompanyId(m),
    });
    if (companyId) {
      setThemeIsLoading(true);
      fetchThemes({ variables: { companyId } });
    }
  }, [companyId]);

  useEffect(() => {
    setIsVisible(true);
    setIsFirstLog(false);
    setShowOnboarding(false);
    storage.set({ key: "@firstLog", value: "true" });
  }, []);

  useEffect(() => {
    if (meError) {
      logOut();
    }
    if (meData?.getMe) {
      setUser({
        ...user,
        isBlocked: meData?.getMe?.isUserBlocked,
        blockedUntil: meData?.getMe?.blockedUntil,
        blockedWeekEnd: meData?.getMe?.blockedWeekEnd,
      });
    }
  }, [meData]);

  useEffect(() => {
    if (themes?.getThemes) {
      setThemes(themes?.getThemes);
    }
  }, [themes]);

  useEffect(() => {
    if (route?.params?.refresh && route.params.refresh === true) {
      setTimezone();
      getMe();
    }
  }, [route?.params?.refresh]);

  useEffect(() => {
    if (themeStatus === NetworkStatus.refetch && companyId)
      fetchThemes({ variables: { companyId } });
  }, [themeStatus]);

  /*
    |--------------------------------------------------------------------------
    | MAIN RENDER
    |--------------------------------------------------------------------------
    */
  return (
    <Layout
      showMenu
      noScroll
      header={<HomeHeader width={screenSize?.width} />}
      footerVisibily={isVisible}
      backgroundColor={{ main: "#e5e5e5", top: theme.colors.white }}
      fixedFooter={{
        render: (
          <CountdownCard
            time={remainingTime}
            isDisabled={themeIsDisable}
            loading={meLoading}
            onClose={() => setIsVisible("is close")}
            onRecap={() => navigate("/recap")}
            isBlocked={
              (isWeekend && meData?.getMe?.blockedWeekEnd) ||
              meData?.getMe?.isUserBlocked
            }
          />
        ),
        isVisible: isVisible === true,
      }}
      noSafe={true}
    >
      <div
        style={{
          overflowY: "scroll",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          width: screenSize?.width,
          alignItems: "center",
        }}
        onScroll={(e) => {
          const { scrollHeight, scrollTop, clientHeight } = e.currentTarget;
          if (scrollHeight - scrollTop === clientHeight) {
            setIsVisible(false);
          } else {
            setIsVisible(true);
          }
        }}
      >
        <HomeFirstConnectMessage show={isFirstLog} />
        <div style={{ padding: "20px 20px 150px 20px" }}>
          {loadingTheme && !refresh ? (
            <ActivityIndicator size="large" color={theme.colors.blue} />
          ) : themes?.getThemes.length > 0 ? (
            themes.getThemes.map((item, index) => (
              <CategoryCard
                key={item.id}
                index={index}
                isDisabled={
                  themeIsLoading ||
                  themeIsDisable ||
                  meData?.getMe?.isUserBlocked ||
                  (meData?.getMe?.blockedWeekEnd && isWeekend)
                }
                category={{
                  ...item,
                  progression: {
                    questionsCount: item.progressionQuestionsCount,
                    currentQuestion: item.progressionCurrentQuestion,
                    percentage: item.progressionPercentage,
                    level: item.progressionLevel,
                    themeLevelId: item.progressionThemeLevelId,
                  },
                }}
                isLast={index === themes.getThemes.length - 1}
                onClick={() =>
                  onRules({
                    item,
                    isBlocked:
                      themeIsDisable ||
                      meData?.getMe?.isUserBlocked ||
                      (meData?.getMe?.blockedWeekEnd && isWeekend),
                  })
                }
              />
            ))
          ) : (
            <Text style={{ alignSelf: "center" }}>
              Aucun thème disponible pour le moment
            </Text>
          )}
        </div>
      </div>
    </Layout>
  );
};

const ActivityIndicator = styled.div`
  width: 30px;
  height: 30px;
  border: 4px solid ${theme.colors.blue};
  border-top: 4px solid transparent;
  border-radius: 50%;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export default Home;
