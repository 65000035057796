import React, { useState } from "react";
import styled from "styled-components";

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const AlertBox = styled.div`
  background: white;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
`;

const Message = styled.p`
  margin-bottom: 20px;
`;

const CloseButton = styled.button`
  padding: 10px 20px;
  border: none;
  background-color: #007bff;
  color: white;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }
`;

const Alert = ({ message, onClose }) => {
  return (
    <Overlay>
      <AlertBox>
        <Message>{message}</Message>
        <CloseButton onClick={onClose}>Close</CloseButton>
      </AlertBox>
    </Overlay>
  );
};

const App = () => {
  const [showAlert, setShowAlert] = useState(false);

  const handleAlert = () => {
    setShowAlert(true);
  };

  const handleCloseAlert = () => {
    setShowAlert(false);
  };

  return (
    <div>
      <button onClick={handleAlert}>Show Alert</button>
      {showAlert && (
        <Alert
          message="This is a custom alert message!"
          onClose={handleCloseAlert}
        />
      )}
    </div>
  );
};

export default App;
