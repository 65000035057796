// import { useHistory } from "react-router-dom";
import _ from "lodash";
import React from "react";
import { FiX } from "react-icons/fi";
import styled, { withTheme, keyframes } from "styled-components";
import { Text } from "../../atoms";
import { Link, Title } from "../../molecules";

const Notif = ({ theme, title, description, onDelete, route, themeId }) => {
  const width = window.innerWidth;
  // const history = useHistory();
  const history = "/";

  const targetRoute = route || null;

  const onGo = () => {
    history.push(targetRoute, { themeId: themeId || null });
    onDelete();
  };

  return (
    <S.Wrapper
      style={{
        background: `linear-gradient(${theme.colors.blue}, transparent)`,
      }}
      isAndroid={navigator.userAgent.includes("Android")}
      width={width}
    >
      <S.Delete onClick={onDelete}>
        <FiX color={theme.colors.white} size={30} />
      </S.Delete>
      {!!title && (
        <Title textAlign="center" fontSize={20}>
          {title}
        </Title>
      )}
      {!!description && (
        <Text color={theme.colors.white} style={{ marginBottom: 5 }}>
          {description}
        </Text>
      )}
      {!_.isEmpty(targetRoute) && <Link onClick={onGo}>Découvrir</Link>}
    </S.Wrapper>
  );
};

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const S = {
  Wrapper: styled.div`
    top: 0;
    left: 0;
    z-index: 100;
    height: 300px;
    padding-top: ${({ isAndroid }) => (isAndroid ? "40px" : "20px")};
    padding: 0 20px;
    position: absolute;
    align-items: center;
    justify-content: flex-start;
    width: ${({ width }) => `${width}px`};
    display: flex;
    flex-direction: column;
    animation: ${fadeIn} 1s ease-in-out;
  `,
  Delete: styled.button`
    align-self: flex-end;
    background: none;
    border: none;
    cursor: pointer;
  `,
};

export default withTheme(Notif);
