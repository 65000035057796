import { gql, useMutation } from "@apollo/client";
import _ from "lodash";
import React, { useCallback, useState, useEffect } from "react";
import { withTranslation } from "react-i18next";
import styled from "styled-components";
import { Input, Layout, Select, Text } from "../components";
import { theme } from "../styles";

/*
|--------------------------------------------------------------------------
| VARIABLES
|--------------------------------------------------------------------------
*/
const inputs = ["object", "details"];
const objects = [
  { label: "Bug dans l'application", value: 1 },
  { label: "QCM", value: 2 },
  { label: "Autres", value: 3 },
];

/*
|--------------------------------------------------------------------------
| QUERIES
|--------------------------------------------------------------------------
*/
const SEND_TICKET = gql`
  mutation createTicket($object: String!, $description: String!) {
    createTicket(data: { object: $object, description: $description }) {
      id
    }
  }
`;

const Helps = ({ t }) => {
  /*
  |--------------------------------------------------------------------------
  | STATES
  |--------------------------------------------------------------------------
  */
  const [form, setForm] = useState({ object: null, details: null });
  const [error, setError] = useState(null);
  const [isDisabled, setIsDisabled] = useState(
    _.isEmpty(form.details) || form.object === null
  );

  /*
  |--------------------------------------------------------------------------
  | FETCHING
  |--------------------------------------------------------------------------
  */
  const [send, { data, loading, error: sendError }] = useMutation(SEND_TICKET);

  /*
  |--------------------------------------------------------------------------
  | FUNCTIONS
  |--------------------------------------------------------------------------
  */
  const onSend = () => {
    send({
      variables: {
        object: objects[form.object - 1]?.label,
        description: form?.details?.target.value,
      },
    });
  };

  /*
  |--------------------------------------------------------------------------
  | CYCLE LIFE
  |--------------------------------------------------------------------------
  */
  const handleFocusEffect = useCallback(() => {
    if (data?.createTicket?.id) {
      window.location.href = "/transition";
    }
    if (sendError) {
      setError("L'envoi a échoué. Merci d'essayer à nouveau ultérieurement");
    }
  }, [data, sendError]);

  useEffect(() => {
    handleFocusEffect();
  }, [handleFocusEffect]);

  useEffect(() => {
    setIsDisabled(_.isEmpty(form.details) || form.object === null);
  }, [form]);

  /*
  |--------------------------------------------------------------------------
  | MAIN RENDER
  |--------------------------------------------------------------------------
  */
  return (
    <Layout
      noScroll
      loading={{ isLoading: loading }}
      headerConfig={{
        title: t("helps_title"),
        description: t("helps_description"),
        leftIconConfig: {
          name: "chevron-left",
          action: () => {
            window.history.back();
          },
        },
      }}
      footerConfig={{
        isDisabled,
        action: onSend,
        title: t("helps_button"),
      }}
    >
      <Styled.Wrapper>
        {inputs.map((input) =>
          input === "object" ? (
            <Select
              key={input}
              options={objects}
              value={form?.object?.value}
              style={{ marginBottom: 30 }}
              label={t(`form_label_${input}`)}
              placeholder={t(`form_placeholder_${input}`)}
              onChange={(item) => setForm({ ...form, object: item?.value })}
            />
          ) : (
            <Input
              isDark
              key={input}
              value={form?.details?.value}
              isError={!_.isEmpty(error)}
              isSelect={input !== "details"}
              isTextarea={input === "details"}
              label={t(`form_label_${input}`)}
              placeholder={t(`form_placeholder_${input}`)}
              onChangeText={(value) => setForm({ ...form, details: value })}
            />
          )
        )}
        {error && (
          <Text
            color={theme.colors.red}
            textAlign="center"
            style={{ marginTop: 10 }}
          >
            {error}
          </Text>
        )}
      </Styled.Wrapper>
    </Layout>
  );
};

const Styled = {
  Wrapper: styled.div`
    padding: 70px 20px;
    margin-top: 100px;
    width: -webkit-fill-available;
    display: flex;
    flex-direction: column;
    gap: 12px;
  `,
};

export default withTranslation()(Helps);
