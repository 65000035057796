import React from "react";
import styled from "styled-components";
import { theme } from "../../styles"; // Adjust the import path to your styles
import { Text } from "../atoms"; // Adjust the import path to your Text component

const BottomButton = ({
  style,
  onClick,
  fontSize,
  children,
  loading,
  fontFamily,
  isDisabled,
  textTransform,
  backgroundColor,
  fontWeight,
}) => {
  const width = window.innerWidth;

  return (
    <StyledBottomButton
      width={width}
      style={style}
      onClick={!isDisabled && onClick}
      disabled={isDisabled}
      backgroundColor={backgroundColor || theme.colors.yellow}
    >
      <Text
        fontSize={fontSize || 18}
        textTransform={textTransform || "none"}
        fontFamily={fontFamily || theme.fonts.blackItalic}
        fontWeight={fontWeight || 900}
        fontStyle={"italic"}
        style={{ opacity: isDisabled ? 0.3 : 1, marginRight: loading ? 10 : 0 }}
      >
        {children}
      </Text>
      {loading && <LoadingSpinner size="small" />}
    </StyledBottomButton>
  );
};

const StyledBottomButton = styled.button`
  background-color: ${({ backgroundColor }) => backgroundColor};
  width: ${({ width }) => `${width}px`};
  justify-content: center;
  align-items: center;
  flex-direction: row;
  position: fixed;
  padding: 20px;
  bottom: 0;
  left: 0;
  display: flex;
  border: none;
  cursor: pointer;
  opacity: ${({ disabled }) => (disabled ? 0.6 : 1)};
`;

const LoadingSpinner = styled.div`
  border: 2px solid ${theme.colors.black};
  border-top: 2px solid transparent;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export default BottomButton;
