import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { useStoreActions, useStoreState } from "easy-peasy";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import styled from "styled-components";
import {
  FilterRankModal,
  Layout,
  Medals,
  MemberCard,
  ProfileHeader,
  StatisticsCard,
  Text,
} from "../components";
import { theme } from "../styles";
import { common } from "../utils";
import { useNavigate } from "react-router-dom";
import { storage } from "../services";

/*
|--------------------------------------------------------------------------
| QUERIES
|--------------------------------------------------------------------------
*/
const LOG_OUT = gql`
  mutation signOut {
    unauthenticateUser {
      success
    }
  }
`;

const GET_BADGES = gql`
  query getPendingMenuBadges {
    getPendingMenuBadges
  }
`;

const REMOVE_TOKEN = gql`
  mutation removePushNotificationRegistrationId($registrationId: String!) {
    removePushNotificationRegistrationId(registrationId: $registrationId) {
      success
    }
  }
`;

const GET_MEDALS = gql`
  query getUser {
    authenticatedUser {
      badgesCount
    }
  }
`;

const GET_STATS = gql`
  query getDaySummary($date: String!, $length: String!) {
    getDaySummary(date: $date, length: $length) {
      totalPoints
      todayPoints
      monthRank
      themesCount
      questionsCount
      goodAnswersCount
      wrongAnswersCount
      goodAnswersPercentage
      wrongAnswersPercentage
    }
  }
`;

const GET_TEAM_STATS = gql`
  query getRanks(
    $themeId: ID
    $date: String!
    $type: GetRankTypeEnum!
    $scope: GetRankScopeEnum
    $length: GetRankLengthEnum!
  ) {
    getRanks(
      date: $date
      type: $type
      scope: $scope
      length: $length
      themeId: $themeId
    ) {
      ranks {
        id
        name
        points
        teamName
        picture {
          thumbnail
        }
      }
    }
  }
`;

/*
|--------------------------------------------------------------------------
| VARIABLES
|--------------------------------------------------------------------------
*/
const date = new Date();
const currentMonth = date.getMonth();
const currentYear = date.getFullYear();
const menu = ["my_profile", "my_team"];

const formattedMonth =
  _.size(_.toString(currentMonth + 1)) > 1
    ? currentMonth + 1
    : `0${currentMonth + 1}`;

const Profile = ({ navigation, t }) => {
  const navigate = useNavigate();

  /*
  |--------------------------------------------------------------------------
  | STORE
  |--------------------------------------------------------------------------
  */
  const user = useStoreState((state) => state.user);
  const logOut = useStoreActions((actions) => actions.logOut);
  const setBadges = useStoreActions((actions) => actions.setBadges);
  const notificationToken = useStoreState((state) => state.notificationToken);
  const setNotificationToken = useStoreActions(
    (actions) => actions.setNotificationToken
  );

  /*
  |--------------------------------------------------------------------------
  | STATES
  |--------------------------------------------------------------------------
  */

  const [active, setActive] = useState(menu[0]);
  const [confirm, setConfirm] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const [filters, setFilters] = useState({
    date: `${currentYear}-${formattedMonth}-01`,
    month: currentMonth,
    year: currentYear,
    length: "month",
    day: "01",
  });

  /*
  |--------------------------------------------------------------------------
  | FETCHING
  |--------------------------------------------------------------------------
  */
  const [signOut, { data: signOutData, loading: signOutLoading }] =
    useMutation(LOG_OUT);

  const [removeToken, { data: removeTokenData }] = useMutation(REMOVE_TOKEN);

  const { data: medals, refetch: getMedals } = useQuery(GET_MEDALS);

  const {
    data,
    refetch: getStats,
    loading: statsLoading,
  } = useQuery(GET_STATS, {
    fetchPolicy: "cache-and-network",
    variables: { type: "user", length: filters.length, date: filters.date },
  });

  const { data: team, refetch: getTeam } = useQuery(GET_TEAM_STATS, {
    variables: {
      type: "user",
      scope: "team",
      date: filters.date,
      length: filters.length,
    },
  });

  const [getBadges, { data: badges }] = useLazyQuery(GET_BADGES, {
    fetchPolicy: "cache-and-network",
  });

  /*
  |--------------------------------------------------------------------------
  | VARIABLES
  |--------------------------------------------------------------------------
  */
  const isTeam = active === menu[1];
  const stats = data?.getDaySummary;
  const medalsCount = medals?.authenticatedUser?.badgesCount;

  const count =
    medalsCount && !_.isEmpty(medalsCount)
      ? medalsCount.reduce((a, b) => a + b, 0)
      : 0;

  const statistics = [
    {
      isDark: true,
      color: "yellow",
      value: stats?.totalPoints || 0,
      label: "recap_win_points",
    },
    {
      color: "red",
      isRanking: true,
      value: stats?.monthRank || 0,
      label: "recap_month_ranking",
    },
    {
      isDark: true,
      color: "lightblue",
      value: stats?.questionsCount || 0,
      label: "recap_question_treated",
    },
    {
      color: "violet",
      isPercentage: true,
      value: stats?.goodAnswersPercentage || 0,
      label: "recap_right_answers_percent",
    },
  ];

  /*
  |--------------------------------------------------------------------------
  | CYCLE LIFE
  |--------------------------------------------------------------------------
  */
  useEffect(() => {
    if (signOutData?.unauthenticateUser?.success) {
      logOut();
      storage.remove({ key: "@token" });
      storage.remove({ key: "@user" });
      removeToken({ variables: { registrationId: notificationToken } });
    }
  }, [signOutData]);

  useEffect(() => {
    getBadges();
    getMedals();
    getStats();
  }, []);

  useEffect(() => {
    if (
      badges?.data?.getPendingMenuBadges &&
      !_.isEmpty(badges?.data?.getPendingMenuBadges)
    ) {
      setBadges(badges.data?.getPendingMenuBadges);
      storage.set({
        key: "@badges",
        isObject: true,
        value: badges.data?.getPendingMenuBadges,
      });
    }
  }, [badges]);

  useEffect(() => {
    if (isTeam) {
      getTeam();
    } else {
      getStats();
    }
  }, [active]);

  useEffect(() => {
    if (confirm === true && isOpen === true && stats) {
      setIsOpen(false);
      setConfirm(false);
    }
  }, [data, isOpen, confirm]);

  useEffect(() => {
    if (removeTokenData?.success) {
      setNotificationToken(null);
    }
  }, [removeTokenData]);

  /*
  |--------------------------------------------------------------------------
  | FUNCTION
  |--------------------------------------------------------------------------
  */
  function getButtonLabel() {
    const isCurrent =
      filters.year === currentYear && filters.month === currentMonth;

    if (filters.length === "month") {
      if (isCurrent) return t("profile_month");
      return `${common.months[filters.month]} ${filters.year}`;
    }

    if (filters.length === "year") {
      return filters.year;
    }

    const month =
      _.size(_.toString(filters.month + 1)) === 2
        ? filters.month + 1
        : `0${filters.month + 1}`;

    return `Semaine du ${filters.day}/${month}/${filters.year}`;
  }

  const onLogout = () => signOut();
  const onSettings = () => navigate("/settings");
  const onProfile = ({ id }) => navigate("/selected_profile", { id });

  /*
  |--------------------------------------------------------------------------
  | MAIN RENDER
  |--------------------------------------------------------------------------
  */
  return (
    <>
      {isOpen && (
        <FilterRankModal
          visible={isOpen}
          filters={filters}
          setFilters={setFilters}
          action={() => getStats()}
          onClose={() => setIsOpen(false)}
        />
      )}

      <Layout
        showMenu
        noSafe={true}
        isLoading={signOutLoading}
        headerConfig={{
          position: "relative",
          style: { paddingBottom: 0 },
          filters: { active, setActive, menu },
          leftIconConfig: { name: "settings", action: onSettings },
          children: (
            <ProfileHeader
              onFilter={() => setIsOpen(true)}
              buttonLabel={getButtonLabel()}
              connectedUser={user}
              isTeam={isTeam}
            />
          ),
        }}
      >
        <S.Wrapper active={active}>
          <S.StatisticsGrid>
            {statistics.map((statistic, index) => (
              <StatisticsCard
                loading={statsLoading}
                statistic={statistic}
                index={index}
                key={index}
              />
            ))}
          </S.StatisticsGrid>
          <Text
            fontSize={20}
            textAlign="center"
            fontFamily={theme.fonts.bold}
            fontWeight={700}
            style={{ marginBottom: isTeam ? 20 : 0 }}
          >
            {isTeam ? t("team_members") : t("badges_earned", { count })}
          </Text>
          {!isTeam ? (
            <S.Medals>
              {common.medals.map((medal, index) => (
                <Medals
                  key={medal}
                  medal={medal}
                  count={medalsCount?.[index]}
                />
              ))}
            </S.Medals>
          ) : team?.getRanks?.ranks &&
            !_.isEmpty(team.getRanks.ranks.filter((i) => i.id !== user?.id)) ? (
            team.getRanks.ranks
              .filter((i) => i.id !== user?.id)
              .map((member, index) => (
                <MemberCard
                  isProfile={false}
                  index={index}
                  key={member.id}
                  member={member}
                  currentTeam={false}
                  // onClick={() => onProfile({ id: member.id })}
                />
              ))
          ) : (
            <Text textAlign="center" style={{ paddingHorizontal: 40 }}>
              Vous êtes pour le moment seul dans votre team
            </Text>
          )}
        </S.Wrapper>
      </Layout>
    </>
  );
};

/*
|--------------------------------------------------------------------------
| STYLES
|--------------------------------------------------------------------------
*/
const S = {
  Medals: styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  `,
  Wrapper: styled.div`
    flex: 1;
    width: 100%;
    position: relative;
    overflow-y: scroll;
    margin-bottom: 60px;
    display: flex;
    flex-direction: column;
    padding-top: 20px;
  `,
  StatisticsGrid: styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    padding: 0 25px 10px;
  `,
};

export default withTranslation()(Profile);
