export const theme = {
  colors: {
    lightorange: '#FBC402',
    lightblue: '#00FFFF',
    lightgrey: '#f5f5f5',
    turquoise: '#03D9B2',
    orange: '#FF9900',
    yellow: '#FFE500',
    violet: '#8F00FF',
    fushia: '#FF007A',
    green: '#00FFA3',
    white: '#FFFFFF',
    black: '#000000',
    pink: '#FF9AE3',
    blue: '#0057FF',
    grey: '#393939',
    red: '#FF004C',
  },

  fonts: {
    bold: 'Montserrat',
    light: 'Montserrat',
    medium: 'Montserrat',
    regular: 'Montserrat',
    semiBold: 'Montserrat',
    extraBold: 'Montserrat',
    blackItalic: 'Montserrat',
  },

  icons: {
    medals: {
      gold: require('../assets/images/medals/gold.png'),
      silver: require('../assets/images/medals/silver.png'),
      bronze: require('../assets/images/medals/bronze.png'),
      diamond: require('../assets/images/medals/diamond.png'),
      xxl: {
        gold: require('../assets/images/medals/gold_xxl.png'),
        silver: require('../assets/images/medals/silver_xxl.png'),
        bronze: require('../assets/images/medals/bronze_xxl.png'),
        diamond: require('../assets/images/medals/diamond_xxl.png'),
      },
    },
  },
};
