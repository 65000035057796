import React from "react";
import { withTranslation } from "react-i18next";
import styled from "styled-components";
import Avatar from "../../atoms/Avatar";
import Text from "../../atoms/Text";
import Title from "../../molecules/Title";
import { theme } from "../../../styles";

const MemberCard = ({
  t,
  rank,
  index,
  style,
  member,
  isLast,
  onClick,
  isCurrent,
  isProfile,
  animated = true,
  currentTeam = true,
}) => {
  const width = window.innerWidth;

  return (
    <AnimatedCard
      style={style}
      width={width - 40}
      isLast={isLast}
      isCurrent={isCurrent}
      duration={animated ? `${1000 + index * 200}ms` : "0ms"}
      animation={"none"}
    >
      {(rank || member?.rank) && (
        <div style={{ marginRight: 23 }}>
          <Title
            fontSize={18}
            color={isCurrent ? theme.colors.yellow : "#6D6D6D"}
            fontWeight={900}
            fontStyle={"italic"}
          >
            {`# ${rank || member?.rank || "-"}`}
          </Title>
        </div>
      )}

      <StyledMember
        style={{ flex: 1 }}
        isProfile={isProfile}
        isCurrent={isCurrent}
        currentTeam={currentTeam}
        onClick={!currentTeam ? onClick : null}
        activeOpacity={onClick ? (!currentTeam ? 0.5 : 1) : 1}
      >
        <Avatar
          size={isCurrent ? 50 : 60}
          isLocal={!member?.picture?.thumbnail}
          onEdit={!currentTeam ? onClick : null}
          src={
            member?.picture?.thumbnail ||
            require("../../../assets/images/default_avatar.png")
          }
        />
        <div style={{ marginLeft: 20, flex: 1 }}>
          {member?.name && (
            <Text
              color={theme.colors.grey}
              fontFamily={theme.fonts.bold}
              fontSize={isCurrent ? 14 : 17}
              fontWeight={700}
            >
              {member.name}
            </Text>
          )}

          {(member?.team?.name || member?.teamName) && (
            <Text style={{ marginVertical: 2 }} fontSize={isCurrent ? 12 : 14}>
              {t("team_name", { name: member?.team?.name || member?.teamName })}
            </Text>
          )}

          <Title
            color={theme.colors.blue}
            fontSize={isCurrent ? 10 : 12}
            fontWeight={900}
            fontStyle={"italic"}
          >
            {t("points_count", {
              count: member?.totalPoints || member?.points || 0,
            })}
            {(member?.totalPoints || member?.points || 0) > 1 ? "S" : ""}
          </Title>
        </div>
      </StyledMember>
    </AnimatedCard>
  );
};

// Animations using keyframes from styled-components
// const fadeInRightBig = keyframes`
//   from {
//     opacity: 0;
//     transform: translate3d(100%, 0, 0);
//   }

//   to {
//     opacity: 1;
//     transform: none;
//   }
// `;

const StyledMember = styled.div`
  padding: ${({ isCurrent }) => (isCurrent ? "15px" : "20px")};
  background-color: ${theme.colors.white};
  border-color: ${theme.colors.yellow};
  box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.15);
  align-items: center;
  border-radius: 15px;
  display: flex;
  flex-direction: row;

  border-width: ${({ currentTeam, isProfile }) =>
    currentTeam || isProfile ? "0px" : "1.5px"};
  border-style: ${({ currentTeam, isProfile }) =>
    currentTeam || isProfile ? "unset" : "solid"};
`;

const Wrapper = styled.div`
  align-self: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: ${({ width }) => `${width}px`};
  margin-bottom: ${({ isLast, isCurrent }) =>
    isLast || isCurrent ? "0px" : "16px"};
  animation: ${({ animation }) => animation};
  animation-duration: ${({ duration }) => duration};
`;

const AnimatedCard = styled(Wrapper)``;

export default withTranslation()(MemberCard);
