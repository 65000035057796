import React from "react";
import styled from "styled-components";
import { theme } from "../../styles"; // Adjust the import path to your styles
import FeatherIcon from "feather-icons-react";

const Avatar = ({ size, src, isLocal, style, onEdit, loading, isEdited }) => {
  const clickable = !!onEdit;
  return (
    <Wrapper onClick={onEdit} clickable={clickable || undefined} style={style}>
      <StyledItem size={size || 90} src={isLocal ? src : src} />
      {loading && (
        <LoadingOverlay size={size || 90}>
          <LoadingSpinner size={size || 90} />
        </LoadingOverlay>
      )}
      {onEdit && (
        <EditIcon size={20} isEdited={isEdited}>
          <FeatherIcon icon={isEdited ? "x" : "edit"} />
        </EditIcon>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  display: inline-block;
  cursor: ${(props) => (props.clickable ? "pointer" : "default")};
`;

const StyledItem = styled.img`
  border-radius: 50%;
  height: ${({ size }) => `${size}px`};
  width: ${({ size }) => `${size}px`};
  object-fit: cover;
`;

const LoadingOverlay = styled.div`
  position: absolute;
  height: ${({ size }) => `${size}px`};
  width: ${({ size }) => `${size}px`};
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
`;

const LoadingSpinner = styled.div`
  border: 4px solid ${theme.colors.white};
  border-top: 4px solid transparent;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const EditIcon = styled.div`
  position: absolute;
  top: -5px;
  right: -15px;
  z-index: 10;
  color: ${theme.colors.white};
  font-size: 20px;
`;

export default Avatar;
