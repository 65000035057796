import { gql, useLazyQuery } from "@apollo/client";
import { useStoreState } from "easy-peasy";
import _ from "lodash";
import React, { useCallback, useEffect } from "react";
import { withTranslation } from "react-i18next";
import styled from "styled-components";
import { Layout, Link, QuizHeader, Text, Title } from "../components";
import { theme } from "../styles";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";

/*
|--------------------------------------------------------------------------
| QUERIES
|--------------------------------------------------------------------------
*/
const GET_THEME = gql`
  query Theme($id: ID!) {
    Theme(where: { id: $id }) {
      id
      name
      progression {
        questionsCount
        currentQuestion
        percentage
        level
        themeLevelId
      }
      levels {
        id
        level
      }
      color
      backgroundColor
      cover {
        file
      }
    }
  }
`;

const QuizRules = ({ t }) => {
  /*
  |--------------------------------------------------------------------------
  | VARIABLES
  |--------------------------------------------------------------------------
  */
  const navigate = useNavigate();

  const location = useLocation();

  const { currentTheme, remainingTime, isBlocked } = location.state || {};

  const themeId = currentTheme?.id;
  const isFinish = remainingTime === 0;

  /*
  |--------------------------------------------------------------------------
  | STORE
  |--------------------------------------------------------------------------
  */
  const isDany = useStoreState((state) => state.isDany);

  /*
  |--------------------------------------------------------------------------
  | FETCHING
  |--------------------------------------------------------------------------
  */
  const [getTheme, { data, loading }] = useLazyQuery(GET_THEME, {
    variables: { id: themeId },
    fetchPolicy: "network-only",
  });

  /*
  |--------------------------------------------------------------------------
  | VARIABLES
  |--------------------------------------------------------------------------
  */
  const height = window.innerHeight;
  const isSmallDevice = height <= 680;

  const item = data?.Theme || currentTheme;

  const isEmpty =
    item?.progression?.questionsCount === 0 || _.isEmpty(item?.levels);

  const colorConfig = {
    backgroundColor: item?.backgroundColor || theme.colors.violet,
    color: item?.color || theme.colors.white,
  };

  useEffect(() => {
    console.debug("isEmpty:", isEmpty);
  }, [isEmpty]);

  useEffect(() => {
    console.debug("loading:", loading);
  }, [loading]);

  /*
  |--------------------------------------------------------------------------
  | FETCH THEME ON FOCUS
  |--------------------------------------------------------------------------
  */
  useEffect(() => {
    if (themeId && !currentTheme) {
      console.debug("fetching theme");
      getTheme();
    }
  }, [themeId, currentTheme]);

  /*
  |--------------------------------------------------------------------------
  | FUNCTIONS
  |--------------------------------------------------------------------------
  */
  const onQuiz = () => {
    const target =
      item?.progression?.percentage === 100
        ? "/quiz_result"
        : "/quiz_questions";
    navigate(target, {
      state: {
        currentTheme: item,
        progression: item?.progression,
      },
    });
  };

  /*
  |--------------------------------------------------------------------------
  | MAIN RENDER
  |--------------------------------------------------------------------------
  */
  return (
    <Layout
      noSafe={true}
      backgroundColor={{ top: colorConfig?.backgroundColor }}
      statusBarConfig={{ backgroundColor: colorConfig?.backgroundColor }}
      onClose={{
        action: () => navigate("/home", { refresh: true }),
        color: colorConfig?.color,
      }}
      footerConfig={{
        loading,
        action: onQuiz,
        title: t("quiz_rules_button"),
        isDisabled: loading || isFinish || isEmpty || isBlocked,
      }}
    >
      <QuizHeader
        isRules
        currentTheme={item}
        colorConfig={colorConfig}
        isSmallDevice={isSmallDevice}
      />

      <Styled.Content>
        <Title fontSize={14} color={theme.colors.grey}>
          {t("quiz_rules_title")}
        </Title>

        {isDany ? (
          <Text
            textAlign="center"
            color={theme.colors.grey}
            style={{
              marginTop: 20,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            Vous pouvez jouer jusqu&apos;à <Bold>2 minutes</Bold> chaque jour
            pour sauver Dany ! Répondez à un maximum de questions et{" "}
            <Bold>montez dans le classement.</Bold>
          </Text>
        ) : (
          <Text
            textAlign="center"
            color={theme.colors.grey}
            style={{ marginTop: 20 }}
          >
            Vous pouvez jouer jusqu&apos;à <Bold>2 minutes</Bold> chaque jour !
            Répondez à un maximum de questions et{" "}
            <Bold>montez dans le classement.</Bold>
          </Text>
        )}

        <Link
          color={theme.colors.grey}
          fontFamily={theme.fonts.bold}
          fontWeight={700}
          onClick={() =>
            navigate("/quiz_recap", { state: { currentTheme: item } })
          }
        >
          {t("quiz_rules_link")}
        </Link>

        {(isEmpty || isFinish || isBlocked) && !loading && (
          <Text
            style={{ marginTop: 20 }}
            color={theme.colors.red}
            textAlign="center"
          >
            {t(isEmpty ? "empty_theme" : "timeout_theme")}
          </Text>
        )}
      </Styled.Content>
    </Layout>
  );
};

const Bold = ({ children }) => (
  <Text
    color={theme.colors.grey}
    fontFamily={theme.fonts.bold}
    fontWeight={700}
  >
    {children}
  </Text>
);

const Styled = {
  Content: styled.div`
    flex: 1;
    padding: 20px;
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-bottom: 60px;
  `,
};

export default withTranslation()(QuizRules);
