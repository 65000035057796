import React, { useState, useEffect, useCallback } from "react";
import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { useDropzone } from "react-dropzone";
import _ from "lodash";
import {
  Alert,
  Avatar,
  Input,
  KeyboardWrapper,
  Layout,
  Link,
  Section,
  Text,
  Title,
} from "../components";
import { theme } from "../styles";
import { generateRNFile } from "../utils";
import { useNavigate } from "react-router-dom";
import { useStoreActions, useStoreState } from "easy-peasy";
import { storage } from "../services";

/*
|--------------------------------------------------------------------------
| QUERIES
|--------------------------------------------------------------------------
*/
const UPDATE_PASSWORD = gql`
  mutation updatePassword($oldPassword: String!, $newPassword: String!) {
    updatePassword(oldPassword: $oldPassword, newPassword: $newPassword) {
      success
    }
  }
`;

const UPDATE_USER = gql`
  mutation updateUser(
    $notifications: Boolean
    $pictureConnection: MediaFileRelateToOneInput
    $email: String
    $userId: ID!
  ) {
    updateUser(
      id: $userId
      data: {
        notifications: $notifications
        picture: $pictureConnection
        email: $email
      }
    ) {
      id
      name
      username
      blockedWeekEnd
      blockedUntil
      email
      notifications
      picture {
        id
        thumbnail
      }
      team {
        id
        name
        users {
          id
          name
          picture {
            thumbnail
          }
        }
      }
    }
  }
`;

const CREATE_MEDIAFILE = gql`
  mutation createMediaFile($file: Upload, $name: String) {
    createMediaFile(data: { upload: $file, name: $name }) {
      id
      file
    }
  }
`;

const CLEAR_NOTIF = gql`
  mutation clearPushNotificationRegistrationIds {
    clearPushNotificationRegistrationIds {
      success
    }
  }
`;

const ADD_NOTIF = gql`
  mutation addPushNotificationRegistrationId($registrationId: String!) {
    addPushNotificationRegistrationId(registrationId: $registrationId) {
      success
    }
  }
`;

/*
|--------------------------------------------------------------------------
| VARIABLES
|--------------------------------------------------------------------------
*/
const inputs = ["email", "password", "new_password", "confirm_new_password"];

const categories = [
  { key: "rules", path: "rules" },
  { key: "helps", path: "helps" },
  { key: "cgu", path: "conditions" },
];

const Settings = ({}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  /*
  |--------------------------------------------------------------------------
  | STORE
  |--------------------------------------------------------------------------
  */
  const registrationId = useStoreState((state) => state.notificationToken);
  const setUser = useStoreActions((actions) => actions.setUser);
  const user = useStoreState((state) => state.user);

  /*
  |--------------------------------------------------------------------------
  | STATES
  |--------------------------------------------------------------------------
  */
  const [file, setFile] = useState();
  const [active, setActive] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isEdited, setIsEdited] = useState(false);

  const [form, setForm] = useState({
    password: null,
    new_password: null,
    confirm_new_password: null,
    email: user?.email || null,
    image: user?.picture?.thumbnail || null,
  });

  /*
  |--------------------------------------------------------------------------
  | FETCHING
  |--------------------------------------------------------------------------
  */
  const [addNotif, addNotifResult] = useMutation(ADD_NOTIF);
  const [clearNotif, clearNotifResult] = useMutation(CLEAR_NOTIF);
  const [updateUser, resultUpdateUser] = useMutation(UPDATE_USER);
  const [updatePassword, updateResult] = useMutation(UPDATE_PASSWORD);
  const [createFile, createFileResult] = useMutation(CREATE_MEDIAFILE);

  /*
  |--------------------------------------------------------------------------
  | VARIABLES
  |--------------------------------------------------------------------------
  */
  const isLoading = resultUpdateUser?.loading || updateResult?.loading;

  /*
  |--------------------------------------------------------------------------
  | NOTIFICATIONS
  |--------------------------------------------------------------------------
  */
  const onChangeNotification = () => setActive(!active);

  useEffect(() => {
    if (active !== null) {
      if (active === true && user?.notifications === false) {
        addNotif({ variables: { registrationId } });
      }
      if (active === false && user?.notifications === true) {
        clearNotif();
      }
      if (active !== user?.notifications) {
        updateUser({ variables: { notifications: active, userId: user?.id } });
      }
    }
  }, [active]);

  useEffect(() => {
    if (user?.notifications !== null) {
      setActive(user.notifications);
    }
  }, []);

  /*
  |--------------------------------------------------------------------------
  | CYCLE LIFE
  |--------------------------------------------------------------------------
  */
  useEffect(() => {
    if (
      updateResult?.data?.updatePassword?.success &&
      updateResult?.data.updatePassword?.success === true
    ) {
      navigate("/transition", {
        title: "C'est tout bon !",
        route: "profile",
        description: "Votre mot de passe a bien été modifié",
      });
    }
  }, [updateResult?.data]);

  useEffect(() => {
    const { data } = createFileResult;
    if (data?.createMediaFile?.file) {
      setLoading(false);
      setIsEdited(true);
      setForm({ ...form, image: data?.createMediaFile?.file });
    }
  }, [createFileResult?.data]);

  useEffect(() => {
    if (file) {
      createFile({
        variables: { file, name: `profile-picture-${Date.now()}` },
        context: { useMultipart: true },
      });
    }
  }, [file]);

  useEffect(() => {
    if (resultUpdateUser?.data?.updateUser) {
      const newUser = resultUpdateUser?.data?.updateUser;
      setUser({ ...newUser });
      storage.set({ key: "@user", value: newUser, isObject: true });
      navigate("/transition", {
        title: "C'est tout bon !",
        route: "profile",
        description: "Votre profil a bien été mis à jour",
      });
    }
  }, [resultUpdateUser]);

  /*
  |--------------------------------------------------------------------------
  | FUNCTIONS
  |--------------------------------------------------------------------------
  */

  const pickImage = async () => {
    // if (!isEdited) {
    //   const result = await ImagePicker.launchImageLibraryAsync({
    //     mediaTypes: ImagePicker.MediaTypeOptions.All,
    //     allowsEditing: true,
    //     aspect: [1, 1],
    //     quality: 0.4,
    //     base64: true,
    //   });
    //   if (!result?.cancelled) {
    //     setLoading(true);
    //     const resizedPhoto = await ImageManipulator.manipulateAsync(
    //       result.uri,
    //       [{ resize: { width: 300 } }],
    //       { compress: 0.7, format: "jpeg" }
    //     );
    //     const newFile = generateRNFile(
    //       resizedPhoto.uri,
    //       `profile-picture-${Date.now()}`
    //     );
    //     setFile(newFile);
    //   }
    // } else {
    //   setIsEdited(false);
    //   setForm({ ...form, image: user?.picture?.thumbnail });
    // }
  };

  const updateProfile = () => {
    if (form?.password && form?.new_password && form?.confirm_new_password) {
      const { password, new_password, confirm_new_password } = form;
      const isDisabled =
        new_password.length < 8 || confirm_new_password.length < 8;

      if (isDisabled) {
        setIsError(true);
      } else {
        setIsError(false);
        updatePassword({
          variables: {
            oldPassword: password.trim(),
            newPassword: new_password.trim(),
          },
        });
      }
    }
    setIsEdited(false);

    updateUser({
      variables: {
        userId: user?.id,
        email: form?.email || user?.email || "",
        pictureConnection: createFileResult?.data?.createMediaFile?.id
          ? { connect: { id: createFileResult?.data?.createMediaFile?.id } }
          : null,
      },
    });
  };

  const isEditedAlert = () =>
    Alert.alert(
      "Modifications non sauvegardées",
      "Vous venez d'éditer votre photo de profil mais vous n'avez pas encore enregistrées vos modifications, si vous quittez la page, les modifications seront annulées",
      [
        {
          text: "Enregistrer",
          onClick: updateProfile,
          style: "cancel",
        },
        { text: "Continuer", onClick: () => window.history.back() },
      ],
      { cancelable: false }
    );

  /*
  |--------------------------------------------------------------------------
  | MAIN RENDER
  |--------------------------------------------------------------------------
  */
  return (
    <Layout
      loading={{ isLoading }}
      headerConfig={{
        position: "absolute",
        title: t("settings_title"),
        leftIconConfig: {
          name: "chevron-left",
          action: () => (isEdited ? isEditedAlert() : window.history.back()),
        },
        children: (
          <div
            style={{
              marginTop: 20,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Avatar
              isLocal
              size={91}
              loading={loading}
              isEdited={isEdited}
              onEdit={pickImage}
              src={
                form?.image
                  ? { uri: form.image }
                  : require("../assets/images/default_avatar.png")
              }
            />

            {user?.User?.name && (
              <Title style={{ marginTop: 15 }} fontSize={20}>
                {user?.User.name}
              </Title>
            )}

            {user?.User?.team?.name && (
              <Text
                style={{ marginTop: 5 }}
                color={theme.colors.white}
                fontSize={12}
              >
                {t("team_name", { name: user?.User.team.name })}
              </Text>
            )}
          </div>
        ),
      }}
    >
      <div style={{ marginTop: "240px", flexDirection: "column" }}>
        <KeyboardWrapper style={{ padding: 20 }}>
          <Text>V.2.1.1</Text>

          {form?.image && (
            <Link
              style={{ marginBottom: 20 }}
              color={theme.colors.black}
              fontSize={12}
              onClick={() => {
                setForm({ ...form, image: null });
                updateUser({
                  variables: {
                    userId: user?.User.id,
                    pictureConnection: { disconnectAll: true },
                  },
                });
              }}
            >
              Supprimer ma photo de profil
            </Link>
          )}
        </KeyboardWrapper>

        <div style={{ padding: 20 }}>
          {categories.map((category) => (
            <Section
              navigation={() => window.history.back()}
              notifIsActive={active}
              category={category}
              key={category.key}
            />
          ))}
        </div>

        {/* <div
          {...getRootProps()}
          style={{ padding: 20, border: "1px dashed black", cursor: "pointer" }}
        >
          <input {...getInputProps()} />
          <p>Drag 'n' drop some files here, or click to select files</p>
        </div> */}
      </div>
    </Layout>
  );
};

export default Settings;
