import dayjs from "dayjs";
import _ from "lodash";
import React, { useCallback, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Line } from "react-chartjs-2";
import FeatherIcon from "feather-icons-react";

import { Text } from "./../../atoms";
import { theme } from "./../../../styles";
import { FilterButton, MemberCard } from "../common";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

// Register the required components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const RankingHeader = ({ label, current, loading, onFilter, progressions }) => {
  /*
  |--------------------------------------------------------------------------
  | VARIABLES
  |--------------------------------------------------------------------------
  */
  const { t } = useTranslation();
  const width = window.innerWidth;

  /*
  |--------------------------------------------------------------------------
  | STATES
  |--------------------------------------------------------------------------
  */
  const [data, setData] = useState([]);
  const [labels, setLabels] = useState([]);
  const [showRank, setShowRank] = useState(false);
  const [showCurrent, setShowCurrent] = useState(true);
  const [range, setRange] = useState({ start: 0, end: 5 });

  /*
  |--------------------------------------------------------------------------
  | FUNCTIONS
  |--------------------------------------------------------------------------
  */
  const onScroll = ({ isAdd }) => {
    const nextEnd = range.end + 5;
    const prevStart = range.start - 5;

    if (isAdd) {
      setRange({
        start: nextEnd >= _.size(data) ? _.size(data) - 5 : range.start + 5,
        end: nextEnd >= _.size(data) ? _.size(data) : range.end + 5,
      });
    }
    if (!isAdd) {
      setRange({
        start: prevStart < 0 ? 0 : range.start - 5,
        end: prevStart < 0 ? 5 : range.end - 5,
      });
    }
  };

  /*
  |--------------------------------------------------------------------------
  | CYCLE LIFE
  |--------------------------------------------------------------------------
  */
  useEffect(() => {
    if (progressions && !_.isEmpty(progressions)) {
      const d = [];
      const l = [];

      progressions.forEach((item) => {
        d.push(dayjs(item.date).format("DD/MM"));
        l.push(item?.points || 0);
      });

      setData(d);
      setLabels(l);
      setRange({
        start: _.size(progressions) - 5,
        end: _.size(progressions),
      });
    }
  }, [progressions]);

  /*
  |--------------------------------------------------------------------------
  | MAIN RENDER
  |--------------------------------------------------------------------------
  */
  return (
    <>
      <FilterButton
        value={label}
        onClick={onFilter}
        label={t("filters")}
        style={{ marginBottom: 15, width: width - 40 }}
      />

      <Dropdown
        isOpen={showCurrent}
        label={t("my_ranking")}
        style={{ padding: "0 20px" }}
        onClick={() => setShowCurrent(!showCurrent)}
      />

      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ActivityIndicator size="large" color={theme.colors.white} />
        </div>
      ) : (
        current &&
        showCurrent && (
          <MemberCard isLast isCurrent animated={false} member={current} />
        )
      )}

      <Dropdown
        isOpen={showRank}
        style={{ marginTop: 20, padding: "0 20px" }}
        label={t("my_progression")}
        onClick={() => setShowRank(!showRank)}
      />

      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ActivityIndicator size="large" color={theme.colors.white} />
        </div>
      ) : (
        showRank &&
        !_.isEmpty(labels) &&
        !_.isEmpty(data) && (
          <div style={{ width: width - 40 }}>
            <Line
              data={{
                labels: data.slice(range.start, range.end),
                datasets: [
                  {
                    label: "Progression",
                    data: labels.slice(range.start, range.end),
                    backgroundColor: "rgba(255, 255, 255, 0.2)",
                    borderColor: "rgba(255, 255, 255, 1)",
                    borderWidth: 1,
                    pointBackgroundColor: "#FFFFFF",
                    pointBorderColor: "#FFFFFF",
                  },
                ],
              }}
              options={{
                responsive: true,
                scales: {
                  x: {
                    ticks: { color: "#FFFFFF" },
                  },
                  y: {
                    ticks: { color: "#FFFFFF" },
                  },
                },
                plugins: {
                  legend: { display: false },
                },
              }}
            />
            <Control>
              <ChartButton
                isLeft
                active={range.start > 0}
                onClick={() => onScroll({ isAdd: false })}
              />
              <ChartButton
                isLeft={false}
                active={range.end < _.size(data)}
                onClick={() => onScroll({ isAdd: true })}
              />
            </Control>
          </div>
        )
      )}
    </>
  );
};

const ChartButton = ({ isLeft = false, onClick, active }) => (
  <button
    onClick={onClick}
    style={{
      background: "none",
      border: "none",
      cursor: active ? "pointer" : "default",
      opacity: active ? 1 : 0.5,
    }}
  >
    <FeatherIcon
      size={30}
      icon={isLeft ? "arrow-left" : "arrow-right"}
      color={active ? theme.colors.white : "rgba(255, 255, 255, 0.3)"}
    />
  </button>
);

const Dropdown = ({ isOpen, label, onClick, style, children }) => (
  <Row onClick={onClick} isOpen={isOpen} style={style}>
    <Text
      fontSize={18}
      color={theme.colors.white}
      fontFamily={theme.fonts.bold}
      fontWeight={700}
      style={{ marginRight: "auto" }}
    >
      {label}
    </Text>
    {children}
    <FeatherIcon
      icon={isOpen ? "chevron-up" : "chevron-down"}
      size={30}
      color={theme.colors.white}
    />
  </Row>
);

const Row = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${({ isOpen }) => (isOpen ? "10px" : "0px")};
`;

const Control = styled.div`
  width: 100%;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ActivityIndicator = styled.div`
  width: 30px;
  height: 30px;
  border: 4px solid ${theme.colors.white};
  border-top: 4px solid transparent;
  border-radius: 50%;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export default RankingHeader;
