import { useStoreState } from "easy-peasy";
import React from "react";
import { withTranslation } from "react-i18next";
import styled from "styled-components";
import { theme } from "../../../styles";

const HomeFirstConnectMessage = ({ show }) => {
  const isDany = useStoreState((state) => state.isDany);

  return (
    show && (
      <Message>
        <GreyText>Vous pouvez jouer jusqu’à </GreyText>
        <BoldGreyText>{`2 minutes `}</BoldGreyText>
        {isDany ? (
          <GreyText>
            chaque jour pour sauver Dany en améliorant votre score ! Choisissez
            un thème, répondez à un maximum de questions pour
          </GreyText>
        ) : (
          <GreyText>
            chaque jour pour améliorer votre score ! Choisissez un thème,
            répondez à un maximum de questions pour
          </GreyText>
        )}
        <BoldGreyText>{` monter dans le classement.`}</BoldGreyText>
      </Message>
    )
  );
};

const Message = styled.div`
  font-size: 14px;
  margin-bottom: 25px;
  padding: 10px 25px 0;
`;

const GreyText = styled.span`
  color: ${theme.colors.grey};
`;

const BoldGreyText = styled(GreyText)`
  font-family: ${theme.fonts.bold};
  font-weight: 700;
`;

export default withTranslation()(HomeFirstConnectMessage);
