const Storage = {
  get: ({ key, callback, isObject }) => {
    try {
      const value = localStorage.getItem(key);
      const result = isObject ? JSON.parse(value) : value;
      callback(result);
    } catch (e) {
      // handle error
    }
  },
  set: ({ key, value, isObject }) => {
    try {
      const result = isObject ? JSON.stringify(value) : value;
      localStorage.setItem(key, result);
    } catch (e) {
      // handle saving error
    }
  },
  remove: ({ key }) => {
    localStorage.removeItem(key);
  },
  clear: () => localStorage.clear(),
};

export default Storage;
