import React from "react";
import { LogoSVG } from "../../svg";
import { theme } from "../../../styles";

const AuthTemplate = ({ children, withLight, goBack, onClose, loading }) => {
  const width = window.innerWidth;
  const height = window.innerHeight;
  return (
    <div
      style={{
        backgroundColor: theme.colors.blue,
        barStyle: "light-content",
        overflow: "hidden",
      }}
    >
      <div
        style={{
          position: "relative",
          flex: 1,
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
        }}
      >
        <img
          style={{
            height: height / 2,
            top: height / 4,
            width: width,
            resizeMode: "cover",
            position: "absolute",
            zIndex: 1,
          }}
          src={require("../../../assets/pictures/auth_background.png")}
        />

        {withLight && (
          <img
            style={{
              resizeMode: "cover",
              position: "absolute",
              opacity: "0.3",
              height: "100%",
              width: "100%",
              zIndex: 0,
              left: 0,
              top: 0,
            }}
            src={require("../../../assets/pictures/sun.png")}
          />
        )}

        <div
          style={{
            flex: 1,
            zIndex: 4,
            alignItems: "center",
            flexDirection: "column",
            justifyContent: "space-around",
            display: "flex",
            height: height,
            padding: "20px 0",
          }}
        >
          <div style={{ position: "absolute", top: "20px", zIndex: "9" }}>
            <LogoSVG />
          </div>
          <div
            style={{
              flex: 1,
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
              flexDirection: "column",
            }}
          >
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthTemplate;
