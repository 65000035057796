import { useStoreState } from "easy-peasy";
import React from "react";
import { withTranslation } from "react-i18next";
import styled from "styled-components";
import { Text } from "./../atoms";
import { Title } from "./../molecules";
import { Modal } from "./../organisms";
import { theme } from "../../styles";

const titles = {
  1: "incroyable !",
  2: "excellent !",
  3: "bravo !",
};

const positions = {
  1: "premier",
  2: "deuxième",
  3: "troisième",
};

const images = {
  1: require("../../assets/images/congrats_dany.png"),
  2: require("../../assets/images/ok_dany.png"),
  3: require("../../assets/images/bravo_dany.png"),
};

const modelHeight = "100px";

const RankingModal = ({ visible, onClose, rank }) => {
  const isDany = true;
  return (
    <Modal visible={visible} onClose={onClose}>
      <Styled.Illustration
        isDany={isDany}
        src={isDany ? images[rank] : require("../../assets/images/ok.png")}
      />

      <Title
        fontSize={28}
        color={theme.colors.white}
        style={{ marginBottom: 16 }}
      >
        {titles[rank]}
      </Title>

      <Text
        textAlign="center"
        color={theme.colors.white}
        fontFamily={theme.fonts.medium}
        fontWeight={500}
        style={{ paddingHorizontal: 40 }}
      >
        {`Vous êtes passé `}
        <Text
          color={theme.colors.white}
          fontFamily={theme.fonts.bold}
          fontWeight={700}
        >
          {positions[rank]}
        </Text>
        {` du classement mensuel !`}
      </Text>
    </Modal>
  );
};

const Styled = {
  Illustration: styled.img`
    height: ${({ isDany }) => (isDany ? "300px" : "200px")};
    resize-mode: contain;
    margin-bottom: 50px;
    width: 100%;
  `,
};

export default withTranslation()(RankingModal);
