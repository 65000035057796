import { gql, useMutation } from "@apollo/client";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import styled, { withTheme } from "styled-components";
import authBackground from "../assets/pictures/auth_background.png"; // Adjust the import path
import { Button, Input, Layout, Title } from "../components"; // Adjust the import path
import { LogoSVG } from "../components/svg"; // Adjust the import path

/*
|--------------------------------------------------------------------------
| QUERIES
|--------------------------------------------------------------------------
*/
const RESET_PASSWORD = gql`
  mutation resetPassword($email: String, $username: String) {
    resetPassword(email: $email, username: $username) {
      success
      _password
      sentTo
    }
  }
`;

const Password = ({ navigation, theme, t }) => {
  /*
  |--------------------------------------------------------------------------
  | STATES
  |--------------------------------------------------------------------------
  */
  const [err, setErr] = useState(null);
  const [username, setUsername] = useState(null);

  /*
  |--------------------------------------------------------------------------
  | VARIABLES
  |--------------------------------------------------------------------------
  */
  const isDisabled = _.isEmpty(username);

  const isEmail = username && username.includes("@");
  const variables = isEmail ? { email: username } : { username };

  /*
  |--------------------------------------------------------------------------
  | FETCHING
  |--------------------------------------------------------------------------
  */
  const [reset, { data, loading, error }] = useMutation(RESET_PASSWORD, {
    variables,
  });

  /*
  |--------------------------------------------------------------------------
  | FUNCTIONS
  |--------------------------------------------------------------------------
  */
  const onReset = () => reset();

  const onClose = () => {
    navigation.goBack();

    setUsername("");
    setErr(null);
  };

  /*
  |--------------------------------------------------------------------------
  | CYCLE LIFE
  |--------------------------------------------------------------------------
  */
  useEffect(() => {
    if (!_.isEmpty(error) || data?.resetPassword?.success === false) {
      setErr("missing_password_error");
    }
  }, [error]);

  useEffect(() => {
    if (data?.resetPassword?.success && data?.resetPassword?.success === true) {
      setErr(null);
      navigation.navigate("transition", {
        route: "login",
        title: t("missing_password_success_title"),
        description: t(
          `missing_password_success_description_${data?.resetPassword?.sentTo}`
        ),
      });
    }
  }, [data]);

  /*
  |--------------------------------------------------------------------------
  | MAIN RENDER
  |--------------------------------------------------------------------------
  */
  return (
    <Layout
      goBack={{ action: onClose }}
      backgroundColor={{ main: theme.colors.blue }}
    >
      <S.Wrapper>
        <S.Background src={authBackground} alt="Auth Background" />

        <div>
          <LogoSVG />

          <S.Content>
            <Title textAlign="center" style={{ paddingHorizontal: 20 }}>
              {t("missing_password_title")}
            </Title>

            <Input
              error={err}
              value={username}
              width="calc(100% - 40px)"
              placeholder="form_placeholder_username"
              style={{ marginTop: 40, marginBottom: 20 }}
              onChange={(e) => setUsername(e.target.value)}
            />

            <Button onClick={onReset} loading={loading} disabled={isDisabled}>
              {t("missing_password_button")}
            </Button>
          </S.Content>
        </div>
      </S.Wrapper>
    </Layout>
  );
};

/*
|--------------------------------------------------------------------------
| STYLES
|--------------------------------------------------------------------------
*/
const S = {
  Background: styled.img`
    position: absolute;
    height: 500px;
    width: 500px;
  `,
  Content: styled.div`
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
  `,
  Wrapper: styled.div`
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
  `,
};

export default withTheme(withTranslation()(Password));
