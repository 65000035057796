/* eslint-disable react/jsx-props-no-spreading */
import { gql, useQuery } from "@apollo/client";
import dayjs from "dayjs";
import _ from "lodash";
import React from "react";
import { withTranslation } from "react-i18next";
import { CircularProgress } from "@mui/material";
import styled from "styled-components";
import { Layout, QuestionCard, Text } from "../components";
import { theme } from "../styles";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";

/*
|--------------------------------------------------------------------------
| QUERIES
|--------------------------------------------------------------------------
*/
const GET_QUESTIONS_OF_DAY = gql`
  query ($date: String, $themeLevelId: ID!) {
    getDayAnsweredQuestions(date: $date, themeLevelId: $themeLevelId) {
      startProgression {
        questionsCount
        currentQuestion
        percentage
        level
      }
      userAnswers {
        selectedAnswers
        isCorrect
        question {
          question
          correction
          picture {
            thumbnail
          }
          answers {
            id
            isCorrect
            answer
          }
        }
      }
    }
  }
`;

/*
|--------------------------------------------------------------------------
| VARIABLES
|--------------------------------------------------------------------------
*/
const date = dayjs(new Date()).format();

const QuizDayRecap = ({ t, history }) => {
  const location = useLocation();
  const navigate = useNavigate();

  /*
  |--------------------------------------------------------------------------
  | VARIABLES
  |--------------------------------------------------------------------------
  */
  const currentTheme = location?.state?.currentTheme?.theme;
  const themeLevelId = location?.state?.currentTheme?.themeLevel?.id;

  /*
  |--------------------------------------------------------------------------
  | FETCHING
  |--------------------------------------------------------------------------
  */
  const {
    data: result,
    loading,
    called,
  } = useQuery(GET_QUESTIONS_OF_DAY, {
    fetchPolicy: "cache-and-network",
    variables: { date, themeLevelId },
  });

  /*
  |--------------------------------------------------------------------------
  | VARIABLES
  |--------------------------------------------------------------------------
  */
  const level = location?.state?.currentTheme?.themeLevel?.level;
  const description = `${currentTheme?.name} - ${t("level", { level })}`;
  const isCalled = called;

  const colorConfig = {
    backgroundColor: currentTheme?.backgroundColor || theme.colors.violet,
    color: currentTheme?.color || theme.colors.white,
  };

  const data = result?.getDayAnsweredQuestions;
  const items = data?.userAnswers;

  /*
  |--------------------------------------------------------------------------
  | FUNCTIONS
  |--------------------------------------------------------------------------
  */
  function getPosition(index) {
    const total =
      data?.startProgression?.questionsCount ||
      data?.progression?.questionsCount;
    const currentQuestion = data?.startProgression?.currentQuestion;
    return `${currentQuestion + index + 1} / ${total}`;
  }

  function getMyAnswer(index) {
    const arr = [];
    let target;

    if (items && index !== null) {
      target = items[index];
    }

    if (target) {
      const { selectedAnswers, question } = target;
      if (question?.answers && !_.isEmpty(question?.answers)) {
        question?.answers.forEach((item) => {
          if (
            selectedAnswers &&
            !_.isEmpty(selectedAnswers) &&
            selectedAnswers.includes(item.id)
          ) {
            arr.push(item);
          }
        });
      }
    }
    return arr;
  }

  function isCorrect(index) {
    let target;
    if (items && index !== null) {
      target = items[index];
    }
    return target?.isCorrect;
  }

  /*
  |--------------------------------------------------------------------------
  | MAIN RENDER
  |--------------------------------------------------------------------------
  */
  return (
    <Layout
      noSafe={true}
      isLoading={loading}
      backgroundColor={{ top: colorConfig?.backgroundColor }}
      headerConfig={{
        position: "relative",
        description,
        ...colorConfig,
        title: t(`quiz_recap_title_true`),
        backgroundImage: currentTheme?.cover?.file,
        style: { paddingBottom: !_.isEmpty(items) ? 120 : 30 },
        leftIconConfig: {
          name: "chevron-left",
          action: () => navigate(-1),
        },
        rightIconConfig: {
          name: "x",
          action: () => navigate("/home", { refresh: true }),
        },
      }}
    >
      {isCalled !== false && (
        <StyledWrapper hasData={!loading && !_.isEmpty(items)}>
          {loading ? (
            <CircularProgress
              size={60}
              style={{ color: colorConfig.backgroundColor }}
            />
          ) : items && !_.isEmpty(items) ? (
            items.map((item, index) => (
              <QuestionCard
                {...item}
                key={index}
                isCorrect={isCorrect(index)}
                answers={getMyAnswer(index)}
                position={getPosition(index)}
              />
            ))
          ) : (
            <Text textAlign="center">{t(`empty_questions_treated_true`)}</Text>
          )}
        </StyledWrapper>
      )}
    </Layout>
  );
};

const StyledWrapper = styled.div`
  padding: 20px;
  align-items: center;
  justify-content: center;
`;

export default withTranslation()(QuizDayRecap);
