import _ from "lodash";
import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import styled from "styled-components";
import { Text } from "./../atoms";
import { Tags, Title } from "./../molecules";
import { Modal, Calendar } from "./../organisms";
import { theme } from "../../styles";

const FilterRankModal = ({
  t,
  themes,
  action,
  visible,
  onClose,
  filters,
  setFilters,
  isBottomButton,
}) => {
  const [settings, setSettings] = useState({
    themeId: null,
    day: filters.day,
    year: filters.year,
    date: filters.date,
    month: filters.month,
    length: filters.length,
  });

  const confirmFilter = () => {
    setFilters({
      ...filters,
      day: settings?.day,
      date: settings?.date,
      year: settings?.year,
      month: settings?.month,
      length: settings?.length,
      themeId: settings?.themeId,
    });

    action();
    onClose();
  };

  const isRanking = themes && !_.isEmpty(themes);

  return (
    <Modal
      isBottomButton={isBottomButton}
      visible={visible}
      onClose={onClose}
      footerConfig={{ title: t("filters_button"), action: confirmFilter }}
    >
      <S.Wrapper>
        <Title fontSize={16} style={{ marginBottom: 50 }}>
          {t("ranking_filter_title")}
        </Title>

        {isRanking && (
          <S.Section style={{ marginBottom: 45 }}>
            <Text
              fontSize={18}
              color={theme.colors.white}
              style={{ marginBottom: 15 }}
              fontFamily={theme.fonts.bold}
              fontWeight={700}
            >
              {t("by_styles")}
            </Text>

            {themes && !_.isEmpty(themes) && (
              <S.Tags>
                <Tags
                  onClick={() => setSettings({ ...settings, themeId: "all" })}
                  style={{ marginBottom: 10 }}
                  value="Tous les thèmes"
                  isActive={
                    settings?.themeId
                      ? settings.themeId === "all"
                      : filters.themeId === "all"
                  }
                />
                {themes.map(({ id: themeId, name }) => (
                  <Tags
                    onClick={() => setSettings({ ...settings, themeId })}
                    style={{ marginBottom: 10 }}
                    key={themeId}
                    value={name}
                    isActive={
                      settings?.themeId
                        ? themeId === settings.themeId
                        : themeId === filters.themeId
                    }
                  />
                ))}
              </S.Tags>
            )}
          </S.Section>
        )}

        <S.Section>
          <Text
            fontSize={18}
            color={theme.colors.white}
            style={{ marginBottom: 15 }}
            fontFamily={theme.fonts.bold}
            fontWeight={700}
          >
            {t("by_period")}
          </Text>

          <Calendar
            filters={filters}
            settings={settings}
            isRanking={isRanking}
            setFilters={setFilters}
            setSettings={setSettings}
          />
        </S.Section>
      </S.Wrapper>
    </Modal>
  );
};

const S = {
  Wrapper: styled.div`
    flex: 1;
    align-items: center;
  `,
  Section: styled.div`
    align-items: flex-start;
    width: 100%;
  `,
  Tags: styled.div`
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
  `,
};

export default withTranslation()(FilterRankModal);
