import React from "react";
import styled from "styled-components";
import { theme } from "../../styles";
import {
  GoBack,
  Header,
  Menu,
  Notif,
  OnClose,
  CustomStatusBar,
} from "./components";
import { BottomButton } from "../../components";

const Layout = ({
  showMenu = false,
  statusBarConfig,
  backgroundColor,
  headerConfig,
  footerConfig,
  fixedFooter,
  noScroll,
  children,
  loading,
  onClose,
  noSafe,
  goBack,
  header,
  noHeight,
}) => {
  const width = window.innerWidth;

  const colorConfig = {
    top: backgroundColor?.top
      ? backgroundColor.top
      : headerConfig
      ? headerConfig?.backgroundColor || theme.colors.blue
      : backgroundColor?.main || theme.colors.white,
    main: backgroundColor?.main || theme.colors.white,
    bottom:
      backgroundColor?.bottom || footerConfig
        ? theme.colors.yellow
        : showMenu
        ? theme.colors.lightgrey
        : theme.colors.white,
  };

  const content = (
    <>
      {onClose && <OnClose onClose={onClose} />}
      {goBack && <GoBack goBack={goBack} />}

      {loading?.isLoading && <S.Loading>Loading...</S.Loading>}

      {header || (headerConfig && <Header headerConfig={headerConfig} />)}

      <S.Content noscroll={noScroll ? true : false}>{children}</S.Content>

      {showMenu && <Menu />}

      {footerConfig?.title && (
        <BottomButton
          isDisabled={footerConfig?.isDisabled}
          onClick={footerConfig?.action}
          loading={footerConfig.loading}
        >
          {footerConfig?.title}
        </BottomButton>
      )}

      {fixedFooter?.render && (
        <S.FixedFooter isvisible={fixedFooter?.isVisible ? true : false}>
          {fixedFooter?.render}
        </S.FixedFooter>
      )}
    </>
  );

  return (
    <>
      {!noSafe && <S.Bar backgroundcolor={colorConfig.top} />}
      <CustomStatusBar
        backgroundcolor={colorConfig.top}
        statusBarConfig={statusBarConfig}
        loading={loading?.isLoading}
        headerConfig={headerConfig}
      />
      <S.Main
        nosafe={noSafe ? true : false}
        noheight={noHeight}
        backgroundcolor={colorConfig.main}
        width={width}
      >
        {content}
      </S.Main>
      {(backgroundColor?.bottom || showMenu || footerConfig) && (
        <S.Bar backgroundcolor={colorConfig.bottom} />
      )}
    </>
  );
};

const S = {
  Bar: styled.div`
    background-color: ${({ backgroundcolor }) => backgroundcolor};
    width: 100%;
  `,
  Main: styled.div`
    flex: 1;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    background-color: ${({ backgroundcolor }) => backgroundcolor};
    padding-top: ${({ nosafe }) => (nosafe ? "0" : "20px")};
    width: 100%;
    height: ${({ noheight }) =>
      noheight ? "unset" : window.innerHeight + "px"};
  `,
  Content: styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    flex-grow: 1;
    overflow-y: ${({ noscroll }) => (noscroll ? "hidden" : "scroll")};
    flex-direction: column;
  `,
  Loading: styled.div`
    position: absolute;
    z-index: 1000;
    right: 20px;
    top: 20px;
  `,
  FixedFooter: styled.div`
    position: absolute;
    align-self: center;
    bottom: 20px;
    transition: all 0.5s;
    opacity: ${({ isvisible }) => (isvisible ? 1 : 0)};
    transform: ${({ isvisible }) =>
      isvisible ? "translateX(0)" : "translateX(100%)"};
  `,
};

export default Layout;
